import React, { useState} from 'react';
import { useDispatch, useSelector } from "react-redux";
import {getSection} from "../../actions/index";
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader
import { Carousel } from 'react-responsive-carousel';
import { useNavigate } from "react-router-dom";

import AnchorLink from "react-anchor-link-smooth-scroll";



export default function Slider () {
    const navigate = useNavigate();
    const dispatch = useDispatch(); 
    function goSectionC () {
  
        dispatch(getSection("sectionC", "Nuevos" )).then(() => {
            navigate("/section/Nuevos");
        }
        );
    }

    function goToOffer () {
        navigate("/service");
    }


    
     

    return (
        <div className='slider'>
            <section>
               
            <div className="banner-part banner-1">
                <div className="container">
                    <div className="row align-items-center">
                        <div className="col-md-6 col-lg-6">
                            <div className="banner-content">
                                <h1>Envío gratis</h1>
                                <p>Aprovecha nuestros envíos gratis en pedidos de piezas por un valor de $10,000 pesos o más.</p>
                                <div className="banner-btn">
                                    <button className="btn btn-inline" onClick={() => goSectionC()}> 
                                        <i className="fas fa-shopping-basket"></i>
                                        <span>comprar ahora</span>
                                        </button>
                                    <AnchorLink className="btn btn-outline" href='#news-part'>
                                        <i className="icofont-sale-discount"></i>
                                        <span>obtener cupón</span>
                                    </AnchorLink>
                                </div>
                                <br/>
                                <h1>Repara tu Celular con nostros ! </h1>
                                <p>Aprovecha en este mes de Diciembre con el costo de reparacion a solo $80 pesos</p>
                                <div className="banner-btn">
                                    <button className="btn btn-inline" onClick={() => goToOffer()}> 
                                        <i className="fas fa-shopping-basket"></i>
                                        <span>Ir a la promocion !</span>
                                        </button>
                                 
                                </div>
                            </div>
                        </div>
                        
                        

                        <div className="col-md-6 col-lg-6">
                        <div className="banner-img"> 
                       
                <Carousel
                 statusFormatter={(current, total) => `Current slide: ${current} / Total: ${total}`}
                 renderArrowPrev={(onClickHandler, hasPrev, label) =>
                     hasPrev && (
                         <button type="button" onClick={onClickHandler} title={label} style={{ position: 'absolute', zIndex: 2,top: 'calc(50% - 15px)', width: 50,
                         height: 50,
                         cursor: 'pointer', background: '#fff', left: 15, 'borderRadius': 10, opacity: 0.7, }}>
                            &#x3c;&#x3c;&#x3c;
                         </button>
                     )
                 }
                 renderArrowNext={(onClickHandler, hasNext, label) =>
                     hasNext && (
                         <button type="button" onClick={onClickHandler} title={label} style={{position: 'absolute', zIndex: 2,top: 'calc(50% - 15px)', width: 50,
                         height: 50, background: '#fff',
                         cursor: 'pointer', right: 15, 'borderRadius': 10, opacity: 0.7  }}>
                           &#x3e;&#x3e;&#x3e;
                         </button>
                     )
                 }
                 autoPlay
                 infiniteLoop
                 
                 swipeable={false}
                 showThumbs={false}
                 showStatus={false}
                 showIndicators={true}
                 showArrows={true}
                 dynamicHeight={false}
                 >
                   
                        <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/1.jpeg"}  alt="slider1" />
                        
                       </div>
                       <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/2.jpeg"}  alt="slider1" />
                        
                       </div>
                       <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/3.jpeg"}  alt="slider1" />
                        
                       </div>
                       <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/4.jpeg"}  alt="slider1" />
                        
                       </div>
                       <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/5.jpeg"}  alt="slider1" />
                        
                       </div>
                       <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/6.jpeg"}  alt="slider1" />
                        
                       </div>
                       <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/7.jpeg"}  alt="slider1" />
                        
                       </div>
                       <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/8.jpeg"}  alt="slider1" />
                        
                       </div>
                       <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/9.jpeg"}  alt="slider1" />
                        
                       </div>
                       <div >
                        <img src={process.env.PUBLIC_URL + "/images/slider/10.jpeg"}  alt="slider1" />
                        
                       </div>
                       

                    </Carousel>
                    </div>
                    </div>
                
                    </div>
</div>
</div>
                </section>
                </div>
                
    )
}
