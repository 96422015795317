import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import '../../css/user-auth.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate, NavLink } from "react-router-dom";
import { AuthContext } from "../../utils/AuthContext";
import { login, facebooklogin } from "../../actions/auth";
//  import FacebookLogin from '@greatsumini/react-facebook-login';

function Login() {
 
  const [loading, setLoading] = useState(false);
    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const API = process.env.REACT_APP_URL;

    const [authState, setAuthState] = useState({
        username:"",
        id: 0,
        status: false,
        
    });
 
    const initialValues = {
        email: "",
        password: "",
      };
      
const  setAuth = useContext(AuthContext);
     
    const loginState = (data) => {
        setLoading(true);
       
      dispatch(login(data.email, data.password))
        .then(res => {   
            setLoading(false);
          navigate("/");
           window.location.reload();
        }).catch(err => {
            setLoading(false);
          
        }
        );
    }
    
      const responseFacebook = () => {

        window.open(API + "/auth/facebook", "_self");    
    }
const responseGoogle = () => {
    window.open(API + "/auth/google", "_self");
}

const responseTwitter = () => {
    window.open(API + "/auth/twitter", "_self");
}

const responseInstagram = () => {
    window.open(API + "/auth/facebook", "_self");
}

   
 
    return (
<div>

    <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
            loginState(values)}}
        
        validationSchema={Yup.object().shape({
            email: Yup.string().email("Debe ser un correro valido").required("Campo requerido"),
            password: Yup.string().required("Campo requerido"),
        })}>

<section className="user-form-part">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-12 col-lg-12 col-xl-10">
                        <div className="user-form-logo">
                            <a href="/"><img src="images/logotipo2.svg" alt="logo"/></a>
                        </div>
                        <div className="user-form-card">
                            <div className="user-form-title">
                                <h2>¡Bienvenido!</h2>
                                <p>Usas tus credenciales para ingresar</p>
                            </div>
                           
                            <div className="user-form-group">
                                <ul className="user-form-social">
                                
     
                                    
                                    <li>
                                 
                                      <a  onClick={ ()=>responseFacebook() } className="facebook"><i className="fab fa-facebook-f">
                                    <FontAwesomeIcon  icon={['fab', 'fa-facebook-f']}/></i>ingresar con facebook</a></li> 
                                    <li><a onClick={ ()=>responseTwitter() } className="twitter"><i className="fab fa-twitter"><FontAwesomeIcon  icon={['fab', 'fa-twitter']}/></i>ingresar con twitter</a></li>
                                    <li><a onClick={ ()=>responseGoogle() } className="google"><i className="fab fa-google"><FontAwesomeIcon  icon={['fab', 'fa-google']}/></i>ingresar con google</a></li>
                                    <li><a onClick={ ()=>responseInstagram() } className="instagram"><i className="fab fa-instagram"><FontAwesomeIcon  icon={['fab', 'fa-instagram']}/></i>ingresar con instagram</a></li>
                                </ul>
                                <div className="user-form-divider">
                                    <p>or</p>
                                </div>
                                 <Form className="user-form">
                               
                                    <div className="form-group">
                                    <Field name="email" type="text" className="form-control" placeholder="Ingresa tu correo"/>
                                       <ErrorMessage name="email" component="div" />
                                    </div>
                                    <div className="form-group">
                                        
                                        <Field name="password" type="password" className="form-control"  placeholder="Ingresa tu contraseña"/>
                                       <ErrorMessage name="password" component="div" />
                                    </div>
                                    <div className="form-check mb-3">
                                    {message ?  <div className="form-group">
              <div className="alert alert-danger" role="alert"> {message?.message}</div> 
         </div> : null }
            
                                        <input className="form-check-input" type="checkbox" value="" id="check"/>
                                        <label className="form-check-label" htmlFor="check">Recordarme</label>
                                    </div>
                                    <div className="form-button">
                                        <button type="submit">Ingresar</button>
                                        {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
                                        <p>¿olvidaste tu contraseña?<NavLink to="/forgot">recuperar aquí</NavLink></p>
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="user-form-remind">
                            <p>¿Aún no tienes una cuenta?<NavLink to="/Reg">Regístrate aquí</NavLink></p>
                        </div>
                        </div>
                        </div>
                    </div>
        
        </section>


    
       
    </Formik>
</div>
    );
}

 
                                      
 export default Login;