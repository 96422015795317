import React, {useState, useLayoutEffect} from "react";
import {NavLink, useParams} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  celular  from "../../../src/img/iconos/celulares.svg";
import  computadoras  from "../../../src/img/iconos/portatil.svg";
import  herramientas  from "../../../src/img/iconos/herramientas1.svg";


export function Categories () {
    const [category, setCategory] = useState([]);
    const id = useParams();
    const API = process.env.REACT_APP_URL;

    useLayoutEffect (() => {
        fetch(API + '/categories/')
        .then(res => res.json())
        .then(data => {
            setCategory(data);
        }).catch();
     
    } , []);

    return (
        <section className="inner-section">
            <div className="container">
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center">
                {category.map((categories) => (
                    <div className="col">
                        <div className="category-wrap">
                            <div className="category-media">
                                { categories.id === 1 ? <img src={celular} alt="celulares"/> : categories.id === 2 ? <img src={computadoras} alt="computadoras"/> : <img src={herramientas} alt="herramientas"/>}
                                <NavLink to={`/categories/sub/${categories.id}`} className="category-overlay">
                                <div className="category-overlay">
                                    <i className="fas fa-link"><FontAwesomeIcon icon={['fas', 'fa-link']}/> </i>
                                </div></NavLink>
                            </div>
                            <div className="category-meta">
                                <h4>{categories.name}</h4>
                                <p>({categories.description})</p>
                            </div>
                        </div>
                    </div>
                ))}
                    </div>
                </div>
                    </section>
    );
} 

export default Categories;