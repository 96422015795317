import axios from "axios";
const API_URL = process.env.REACT_APP_URL;

const instance = axios.create({
  baseURL: API_URL,
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": process.env.REACT_APP_URL,

},
withCredentials: true,

});

export default instance;

