import React, { useState, useLayoutEffect, useEffect} from "react";
import { useNavigate, Link, NavLink, Routes, Route, useParams } from "react-router-dom";
import { addMovieFavorite, getSearch, getRecommendProducts, addToCart, removeFromCart, Plus, Minus, getSection, getCategory} from "../../actions/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useSelector, useDispatch } from "react-redux";
import classNames from "classnames";
import useSticky from "../../hooks/useSticky";
import NavBar from "../../components/NavBar/NavBar";
import { logout } from "../../actions/auth";
import logo from  "../../img/logotipo.svg";
import isotipo from  "../../img/isotipo.svg";
import ingresa from  "../../img/ingresa.svg";
import userService from '../../services/user.service';
import {formatCurrency} from "../../utils/formatCurrency";
import SweetPagination from "sweetpagination";
import LoadingSpinner from "../../utils/LoadingSpinner";
import Carousel from "./Carousel";




export  function Buscador ({socket, user}) {
  const   id  = useParams();
  const dispatch = useDispatch();  
  const movies = useSelector((state) => state.publicActions.moviesLoaded);
  const reco = useSelector ((state) => state.publicActions.Reco);
  const [busqueda, setBusqueda] = useState("");
  const [currentPageData, setCurrentPageData] = useState(movies?.slice(0, 15));
  const favs = useSelector((state) => state.publicActions.moviesFavourites);
  const [hideStore, setHideStore] = useState(true);
  const { sticky, stickyRef } = useSticky();
  const navigate = useNavigate();
  const [hideCategory, setCategoryhide] = useState(true);
  const [hideSearch, setSearch] = useState(true);
  const cartItem = useSelector((state) => state.publicActions.items);
  const [category, setCategory] = useState([]);
  const [subcategory, setSubcategory] = useState([]);
  const [Loading, setLoading] = useState(false);
  const [notifications, setNotifications] = useState([]);
  const [openNotifications, setOpenNotifications] = useState(false);
  const [currentUser, setCurrentUser] = useState(null);
  const API = process.env.REACT_APP_URL;
 

  
  useLayoutEffect (() => {
    let componentMounted = true;
      fetch(API + '/categories/')
      .then(res => res.json())
      .then(data => {
          setCategory(data);
      }).catch();
      fetch(API + '/categories/sub/')
      .then(res => res.json())
      .then(data => {
          setSubcategory(data);
      }
      ).catch();
      return () => {
        componentMounted = false;
      }
  } , []);

useEffect(() => {
  let componentMounted = true;
  setCurrentUser(user); 
  return () => {
    componentMounted = false;
  }
}, [user]); 


   
  useEffect(() => {
    let componentMounted = true;
    if (currentUser) {
    
    
    socket?.emit('getNotifications', currentUser?.user.id, (error) => {
      if(error) {
        alert(error);
      }
    }
      )
    }
   
      return () => {
        componentMounted = false;
      }
  }, [socket, currentUser]);

  useEffect(() => {

    let componentMounted = true;
    socket?.on('notification', (notification) => {

      setNotifications(notification);
   
      
      // setNotifications(prev => {
      //   if (Array.isArray(notification) === false && prev.length <= 0 ) {
         
      //     return [notification];
      //   }
      //   if (prev.length <= 0 && Array.isArray(notification) === true) {
          
      //     return notification;
        
      //   }
       
      //   else {
      //     return [...prev, notification];
      //   }
      // }
      //);
        
    });
    return () => {
      componentMounted = false;
    }
  }, [socket]);
 

  const handleRead = (id) => {
    socket?.emit('readNotifications', id, (error) => {
      if(error) {
        alert(error);
      }
    }
      )
    setNotifications([ ...notifications?.filter((notification) => notification.id !== id)]);
  
  };

  const displayNotification = () => {
    return notifications?.map((notification, index) => {
      return (
        <div key={index} className="notification" onClick={ ()=> setOpenNotifications(!openNotifications)}> 
           <div className="notification__body">
          {notification.type ==="Answer" ? <Link to="/preguntas">
              <p>{notification.createdAt?.slice(0, 10)}</p>
           
            <p>{notification.message}</p>
            </Link> : 
            notification.type ==="order" ? <Link to="/user/history">
            <div>
              <p>{notification.createdAt?.slice(0, 10)}</p>
              <p>{notification?.message}</p> 
            </div>
            </Link> :
            notification.type ==="coupon" ? <Link to="/offers">
            <div>
              <p>{notification.createdAt?.slice(0, 10)}</p>
              <p>{notification?.message}</p>
            </div>
            </Link> :
            <div>
              <p>{notification.createdAt?.slice(0, 10)}</p>
              <p>{notification?.message}</p>
            </div>
          }
          </div>
          {notifications?.length > 0 && <button className="nButton btn btn-inline" onClick={()=>  handleRead(notification.id)}>Marcar como leído <FontAwesomeIcon  icon={['fas', 'fa-check']}/> </button> }
        </div>
      )
    })
  }
  
 
  let compare = {'*': 'buscar/'+ busqueda}
 
  let ProductosTotales =[];
  let Total =[];
ProductosTotales +=cartItem.reduce((a, b) => a + b.quantity, 0); 

Total += cartItem.reduce((acc, curr) => { 
    if (curr.inOffer === false) {
      return acc + curr.price * curr.quantity
      }
      else {
        return acc + curr.offerPrice * curr.quantity
        }
        }, 0);

   


  useLayoutEffect (() => {
    let componentMounted = true;
if (currentUser && currentUser.info) {
    
        userService.getWishListUser(currentUser?.user?.id)
        .then((response) => {
            dispatch(addMovieFavorite(response.data));
           
        }
        
        );
                 
    }
    return () => {
      componentMounted = false;
    }
    } , [currentUser]);

  


    useLayoutEffect (() => {
      let componentMounted = true;
        if (currentUser && currentUser.info)  {  
    userService.getCartListUser(currentUser?.user?.id)
    .then(cartItem => {
        cartItem.data.map(item => {
            dispatch(addToCart(item));
        })
     
}    )   }


return () => {
  componentMounted = false;
} 

    }, [currentUser]);

function values () {setBusqueda (document.getElementById("search").value);}

function categoryFind  (data) {
    data.preventDefault();
    dispatch(getCategory(data));

}


async  function  buscar () {
    setLoading(true);

    if (busqueda === "") {

        setLoading(false);
        return;
    }

   
    else {

    await dispatch(getSearch(busqueda));
    await dispatch(getRecommendProducts(busqueda));
    navigate("buscar/"+ busqueda);
    // setBusqueda ("");
    setLoading(false);
   
    
  }
}
    




function addFavorite(movie) {
    if (currentUser) {
      userService.addWishListUser({product_id:movie.id}).then((movie) => {
      dispatch(addMovieFavorite(movie));
      }) }
    else {
      dispatch(addMovieFavorite(movie));
    }  
    }

    function addCart(movie) {
        if (currentUser) {
          userService.addCartListUser({product_id:movie.id}).then(() => {
          dispatch(addToCart(movie));
          }) }
        else {
          dispatch(addToCart(movie));
        }  
        
        }
function Min (cartItem) {
    if (currentUser && cartItem.quantity > 1) {
        userService.deleteCartListUser({product_id:cartItem.id})
        .then(() => {
            dispatch(Minus(cartItem));
        })
        
        }   
    if (!currentUser && cartItem.quantity > 1) {
  dispatch(Minus(cartItem));
}
}

function PlusPlus (cartItem) {
    if (currentUser && cartItem.stock > cartItem.quantity) {
        userService.addCartListUser({product_id:cartItem.id}).then(() => {
        dispatch(Plus(cartItem));
        }) }
      if (!currentUser && cartItem.stock > cartItem.quantity) {
    dispatch(Plus(cartItem));
  }}
  
function removeItemCart(cartItem) {
    if (currentUser) { 
        userService.deleteAllCartListUser({product_id:cartItem.id}).then(() => {

    dispatch(removeFromCart(cartItem));
    }) }
    else {
    dispatch(removeFromCart(cartItem));
}
    }
    const logOut = () => {
        dispatch(logout());
        setCurrentUser(null);
      
        navigate("/");
        window.location.reload();
      };
 
      function goSectionA () {
  
        dispatch(getSection("sectionA", "En Oferta" )).then(() => {
          navigate("/section/En Oferta");
        
          
          
        }
        );
      
      } 
      function goSectionB () {
  
        dispatch(getSection("sectionB", "Más Vendidos" )).then(() => {
            navigate("/section/Más Vendidos" );
            
        }
        );
      
      } 
      function goSectionC () {
  
        dispatch(getSection("sectionC", "Nuevos" )).then(() => {
            navigate("/section/Nuevos");
        }
        );
      
      } 
      function goSectionD () {
  
        dispatch(getSection("sectionD", "Recomendados" )).then(() => {
            navigate("/section/Recomendados");
          }
        );
      
      } 


      const renderProduct = (product, index) => (
        <div key={`${product.SKU+index}-product`} className="col">
          <div className="product-card">
            <div className="product-media">
              <div className="product-label">
                {product.sectionA && <label className="label-text sale">{product.sectionA}</label>}
                {product.sectionB && <label className="label-text off">{product.sectionB}</label>}
                {product.sectionC && <label className="label-text new">{product.sectionC}</label>}
                {product.sectionD && <label className="label-text feat">{product.sectionD}</label>}
              </div>
              <Link to={`/producto/${product.SKU}`} className="product-image">
                <img src={`${process.env.PUBLIC_URL}/${product.image}`} alt={product.title} />
              </Link>
              <div className="product-widget">
                <a onClick={() => addFavorite(product)} className="venobox fas fa-play" data-autoplay="true" data-vbtype="video">
                  <i className="fas fa-heart"><FontAwesomeIcon  icon={['fas', 'fa-heart']}/></i>
                </a>
              </div>
            </div>
            <div className="product-content">
              <div className="product-rating">
                <Link to={`/categories/sub/product/${product.subcategory}/${product.brand}`}>
                  ({product.brand})
                </Link>
              </div>
              <h6 className="product-name">
                <Link to={`/producto/${product.SKU}`}>{product.products}</Link>
              </h6>
              {product.inOffer ? (
                <h6 className="product-price">
                  <del>{formatCurrency(product.price)}</del>
                  <span>{formatCurrency(product.offerPrice)}<small>/pieza</small></span>
                </h6>
              ) : (
                <h6 className="product-price">
                  <span>{formatCurrency(product.price)}<small>/pieza</small></span>
                </h6>
              )}
              <button className="product-add" title="Add to Cart" onClick={() => addCart(product)}>
                <i className="fas fa-shopping-basket"><FontAwesomeIcon icon={['fas', 'fa-shopping-basket']}/></i>
                <span>Agregar</span>
              </button>
            </div>
          </div>
        </div>
      );
      
      <Route path="/buscar/:id" element={currentPageData && currentPageData.map(renderProduct)} />
      

    return (
      
      <div>
       
      <header ref={stickyRef} className={classNames("header-part", { sticky })}>
    
        <div className="container">
        
                <div className="header-content ">
                 
                    <div className="header-media-group">
                    <div  className="mobile-menu">
                    <Link to="/" title="Inicio"> <img src={isotipo} alt="logo"/>
              
                <span>Tienda</span>
                </Link>
           
            <button className="cart-btn" title="Cartlist" onClick={() => setHideStore(false)}>
                <i className="fas fa-shopping-basket"><FontAwesomeIcon  icon={['fas', 'fa-shopping-basket']}/></i>
                <span>Carrito</span>
                <sup>{ProductosTotales}</sup>
            </button>
           <Link to='/favs' title="Deseos">
                <i className="fas fa-heart"><FontAwesomeIcon  icon={['fas', 'fa-heart']}/></i>
                <span>Deseos</span>
                <sup>{favs.length}</sup>
            </Link>
           
        
                    {/* <Link to="/login" title="ingresar"><button className="header-user"><img src={ingresa} alt="user"/></button> </Link> */}
                        
                        <button className="cart-btn" onClick={() => setSearch(!hideSearch)}><i className="fas fa-search"><FontAwesomeIcon icon={['fas', 'fa-search']}/></i><span>Buscar</span></button>
                     
                        </div>
                        </div>
                        
                        <div className="header-logo">
                        <Link to="/" title="Inicio"> 
                        <img src={logo} alt="logo" /> </Link>
                        </div>
              
        <form  className={`header-form  ${hideSearch ? "header-form" : "active"}`} onSubmit={(e) => {(buscar(e.preventDefault())); setSearch(!hideSearch)}}>
         
        
            <input
            className="input-container"
              type="text"
              id="search"
              onChange={() => (values())}
              value={busqueda}
              placeholder="Buscar..."
              
            />
           <button className="button-home" type="submit"><i> <FontAwesomeIcon icon={['fas', 'fa-search']}/></i></button>
            </form>
            <div className="header-widget-group">
            {!currentUser && (
                    <NavLink to="/login" className="header-widget" title="My Account">
                        <img src={ingresa} alt="user"/>
                        <span>Ingresa</span>
                    </NavLink>
 )}
        
                    {currentUser && (
                        <>
                    <Link to={`/Perfil/${currentUser.user?.id}`} className="header-widget" title="My Account">
                        <img src={`${currentUser.user?.picture ? currentUser.user?.picture : ingresa  }`} alt="user"/>
                        <span>{currentUser.user?.user}</span>
                    </Link>
                    <button className="header-widget"  onClick={ ()=> notifications ==! null || notifications?.length >= 1  ? setOpenNotifications(!openNotifications): null}>  
                    <i className="fas fa-bell"><FontAwesomeIcon icon={['fas', 'fa-bell']}/></i>       
                            { notifications?.length > 0 && 
                            <sup>{notifications?.length}</sup>
                                 }
                                
                          </button>  
                    
                    </>

    )}
                      {/*  <Link to="/" className="header-widget" title="Comparar">
                            <i className="fas fa-random"><FontAwesomeIcon icon={['fas', 'fa-random']}/></i>
                            <sup>0</sup>
                    </a> */}
                        <Link to='/favs' className="header-widget">  
                         <i className="fas fa-heart"><FontAwesomeIcon icon={['fas', 'fa-heart']}/></i>
                            <sup>{favs.length}</sup>
                             </Link>
                        <button className="header-widget header-cart"  onClick={() => setHideStore(false)} title="Carrito">
                            <i className="fas fa-shopping-basket"><FontAwesomeIcon icon={['fas', 'fa-shopping-basket']}/></i>
                            <sup> {ProductosTotales}</sup>
                            <span>total<small>${Total}</small></span>
                        </button>
                        {currentUser  && (


                         <button className="header-widget btn btn-inline" onClick={logOut}>Salir</button>
                        )}
                        
                    </div>
                    { openNotifications && <div className="notifications">
            {displayNotification()}
            <div className="notification__container--read">
            
              
            </div>
          </div>}
                      </div>
            </div>
            </header>
            <Routes>
            <Route path="/" element= {<NavBar/>}/>
</Routes>            
       
  <aside className= {`cart-sidebar  ${hideStore ? "cart-sidebar" : "active"}`}>
  <div className="cart-header">
      <div className="cart-total">
          <i className="fas fa-shopping-basket"><FontAwesomeIcon icon={['fas', 'fa-shopping-basket']}/></i>
          <span>productos totales ({ProductosTotales})</span>
      </div>
     
      <button className="cart-close"><i className="icofont-close" onClick={() => setHideStore(true)}><FontAwesomeIcon icon={['fas', 'fa-xmark']}/></i></button>
  </div>
  <ul className="cart-list">
    
  {cartItem?.map(item => (

      <li className="cart-item" key={item.SKU}>
        
          <div className="cart-media">
              <Link to={`/producto/${item.SKU}`}><img src={process.env.PUBLIC_URL +  "/" +  item.image} alt={item.Title}/></Link>
              <button className="cart-delete" onClick={() => (removeItemCart(item))} ><i className="far fa-trash-alt"><FontAwesomeIcon icon={['fas', 'fa-trash']}/></i></button>
          </div>
          <div className="cart-info-group">
              <div className="cart-info">
                  <h6><Link to={`/producto/${item.SKU}`} alt={item.products}>{item.products}</Link></h6>
                  {item.inOffer === true ? <p>C/U - ${item.offerPrice} </p>: <p> C/U - ${item.price} </p>  } 
              </div>
              <div className="cart-action-group">
                  <div className="product-action">
                      <button className="action-minus" title="Quantity Minus"onClick={() => (Min(item))} ><i className="icofont-minus"><FontAwesomeIcon icon={['fas', 'fa-minus']}/></i></button>
                      <label className="action-input" title="Quantity Number" type="text" name="quantity" defaultValue={item.quantity} > {item.quantity} </label>
                      <button className="action-plus" title="Quantity Plus"onClick={() => (PlusPlus(item))} ><i className="icofont-plus"><FontAwesomeIcon icon={['fas', 'fa-plus']}/></i></button>
                  </div>
                   {item.inOffer === true ? <p className="cart-price"> ${item.offerPrice * item.quantity} </p>: <p className="cart-price"> ${item.price * item.quantity} </p>  }
                  
              </div>
          </div>
      </li>))} 
      </ul> 
      {ProductosTotales > 0 &&
      <div className="cart-footer"  onClick={() => setHideStore(true)}>
     
       <div>
               
                <div className="cart-checkout-btn"  onClick={() => (setHideStore(true), navigate("/checkout"))}>
      
               
                <button 
          type="button"
          >
            <span className="checkout-label"> Pagar &nbsp; </span> <span>&nbsp; &nbsp;</span></button>
                    <span className="checkout-price"> ${Total}</span>
                    </div> 
            </div> </div> }
            {ProductosTotales <=0  &&
          <div className="cart-footer">
            <span className="cart-prewive-label">Tu carrito está vacío </span>
             <p>   Echalé un ojo a nuestras secciones: </p> 
            <Link to="/section/En oferta"> <label className="label-text  sale" onClick={()=> (goSectionA (), setHideStore(true) )}>ofertas!</label>&nbsp;  </Link>
              <label className="label-text feat" onClick={()=> (goSectionB(), setHideStore(true))}>Recomendados!</label> &nbsp; 
             <br/>
              <label className="label-text off" onClick={()=> (goSectionD(), setHideStore(true))}> Más vendidos!</label>  &nbsp; 
              <label className="label-text new" onClick={()=> (goSectionC(), setHideStore(true))}>Nuevos! </label> &nbsp; 
            
         </div>
         } 
            
       </aside>
            <div className="container">
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5">
        
         
            <Routes>
        

<Route path="/buscar/:id" element={currentPageData && currentPageData.map(renderProduct)} />
 </Routes> 
           
             </div>
             {movies <= 0 && JSON.stringify(id) === JSON.stringify(compare)  &&   <div className="col-12"> 
             <br/> 
                    <br/>
                <div className="alert alert-danger" role="alert">
                   
                <h4 className="alert-heading">No se encontraron resultados!</h4>
                <p>Lo sentimos, no se encontraron resultados para la búsqueda: <strong>{busqueda}</strong></p>
            
                <hr/>
                <p className="mb-0">Por favor, intente con otra palabra clave.</p>
                </div>
                <br/> 
                    <br/>
                </div> }
            
             { movies && JSON.stringify(id) === JSON.stringify(compare)  ?    <>
               <SweetPagination
        currentPageData={setCurrentPageData}
        dataPerPage={15}
        getData={movies}
        navigation={true}/> 
        
        <div className="row">
          <div className="col-12">
            <div className="section-title">
              <h2>Productos Relacionados</h2>
            </div>
          </div>
          </div>
          {/* Carousel componet send props*/}
          
          <Carousel items={reco}/>
           
          
        
          
        </>
         : null}
                
            {Loading === true  && <LoadingSpinner/>}
             
       
          </div>  
          <aside className="menu-button"> 
            <button  onClick={() => setCategoryhide(false)}> <FontAwesomeIcon  icon={['fas', 'fa-bars']}> <span> menu</span></FontAwesomeIcon></button>
          </aside>
          <aside className={`category-sidebar  ${hideCategory ? "category-sidebar" : "active"}`} onClick={() => setCategoryhide(true)}> 
            <div className="category-header">
           
                <h4 className="category-title">
                    <i className="fas fa-align-left"></i>
                    <span>Menu</span>
                </h4>
                <button className="category-close" onClick={() => setCategoryhide(true)}><i className="icofont-close"><FontAwesomeIcon icon={['fas', 'fa-xmark']}/></i></button>
            </div>
            <ul className="category-list" >
            <li className="category-item ">
        
            <i className="flaticon-vegetable"></i>
            <span>Perfil</span>
            <ul className="dropdown-list" >
                <li className="dropdown-item">
                {!currentUser && (
                    <>
                    <NavLink to="/login" title="My Account">
                        <img src={ingresa} alt="user"/>
                        <span>Ingresa</span>
                    </NavLink>
                    </>
 )}
 
        
                    {currentUser && (
                        <>
                    <Link to={`/Perfil/${currentUser.user?.id}`}  title="My Account">
                        <img src={` ${currentUser.user?.image ? currentUser.user?.image : ingresa  }`} alt="user"/>
                        <span>{currentUser.user?.username}</span>
                    </Link>
                    
                    
                    </>

    )}
    </li> 
    <li className="dropdown-item"><NavLink to="/Reg"><FontAwesomeIcon icon={['fas',"fa-address-card"]}></FontAwesomeIcon> Registrarse</NavLink></li>
                                        <li><NavLink to="/forgot"><FontAwesomeIcon icon={['fas',"fa-key"]}></FontAwesomeIcon> Recuperar Contraseña</NavLink></li>
                                        {currentUser  && (  <li onClick={logOut}>  
                                            <FontAwesomeIcon icon={['fas',"fa-right-from-bracket"]}></FontAwesomeIcon> Salir</li>
                        )}
 
            </ul>

    </li>
    
            {category.map((categories) => (
                                                            
                                                            <li className="category-item " key={categories.name}>
                                                            <Link to={`/categories/sub/product/${categories.name}`}> 
                                                            <i className="flaticon-vegetable"></i>
                                                            <span>{categories.name}</span>
                                                            </Link>
                                                            <ul className="dropdown-list" key={categories.name+ "dropdown-list"}>
                                                            {
                                                                subcategory.map( (subcategories) => {
                                                                    if (subcategories.category_id === categories.id) {
                                                                        return (
                                                                            <li key={subcategories.name}>
                                                                                <Link to={`/categories/sub/product/${subcategories.name}`} onClick= {()=> categoryFind(subcategories.name)}>
                                                                                    {subcategories.name === "PC de Escritorio" ? <FontAwesomeIcon icon={['fas',"fa-desktop"]}></FontAwesomeIcon> : 
                                                                                    subcategories.name === "PC portátil" ? <FontAwesomeIcon icon={['fas',"fa-laptop"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Accesorios" ? <FontAwesomeIcon icon={['fas',"fa-headphones"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Componentes" ? <FontAwesomeIcon icon={['fas',"fa-microchip"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Herramientas para reparación" ? <FontAwesomeIcon icon={['fas',"fa-toolbox"]}></FontAwesomeIcon> : 
                                                                                    subcategories.name === "Otros" ? <FontAwesomeIcon icon={['fas',"fa-gifts"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Celulares" ? <FontAwesomeIcon icon={['fas',"fa-mobile-screen-button"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Refacciones" ? <FontAwesomeIcon icon={['fas',"fa-mobile"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Telefonía fija" ? <FontAwesomeIcon icon={['fas',"fa-phone"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Telefonía inalámbrica" ? <FontAwesomeIcon icon={['fas',"fa-signal"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Desarmadores" ? <FontAwesomeIcon icon={['fas',"fa-screwdriver-wrench"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Maquinas de soldar " ? <FontAwesomeIcon icon={['fas',"fa-toolbox"]}></FontAwesomeIcon> :
                                                                                    
                                                                                    
                                                                                    
                                                            
                                                                                    null}
                                                                                    
                                                                                        
                                                                                    &nbsp; {subcategories.name}
                                                                                        </Link>
                                                                            </li>
                                                                        )
                                                                    }
                                                                } ) 
                                                            }
                                                            
                                                        </ul>
                                                        </li>
                  ) ) } 
 <li className="category-item ">
        
        <i className="flaticon-vegetable"></i>
        <span>Paginas</span>
        <ul className="dropdown-list" >
            <li className="dropdown-item"></li> 
            <li><NavLink to="/about"><FontAwesomeIcon icon={['fas',"fa-question"]}></FontAwesomeIcon> ¿Quiénes Somos?</NavLink></li>
            <li><NavLink to="/contact"><FontAwesomeIcon icon={['fas',"fa-message"]}></FontAwesomeIcon> Contactanos</NavLink></li>
            <li><NavLink to="/privacy"> <FontAwesomeIcon icon={['fas',"fa-landmark"]}></FontAwesomeIcon> Politicas</NavLink></li>
            </ul>
            </li>

  </ul>
  
    

 
            <div className="category-footer">
           
                <p><Link to="/">MACERONU</Link></p>
            </div>
        </aside>
        
   
   
    </div>


  
    );
  }


export default Buscador;

