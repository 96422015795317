 
import React, { useState, useEffect, useLayoutEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import CheckoutForm from "./CheckoutForm";
import { useSelector, useDispatch } from "react-redux";


 
const STRIPE_PUBLISHABLE_KEY = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY);
const API_URL = process.env.REACT_APP_URL;

 
export default function App() {
  
  const [clientSecret, setClientSecret] = useState("");
  const cartItem = useSelector((state) => state.publicActions.items);
  
  const  step  = useSelector((state) => state.publicActions.purcharse);

  useLayoutEffect(() => {
      if (cartItem.length > 0) {
    fetch(API_URL + "/create-payment-intent/" + step[0].shipment + "/" + step[2], {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(cartItem.map((item) => item)),
    }, )
      .then((res) => res.json())
      .then((data) => setClientSecret(data.clientSecret));
      
  } 
  
  }, [cartItem]);

  
  const appearance = {
    theme: 'stripe',
  };
  const options = {
    clientSecret,
    appearance,
  };
 
  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <CheckoutForm />
        </Elements>
      )}
    </div>
  )
}
