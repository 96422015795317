
import api from "./api";
import TokenService from "./token.service";
const register = (data) => {
  return api.post("/auth", {
   data
  });
};
const login = (email, password) => {
  return api
    .post("/auth/login", {
      email,
      password
    })
    .then((response) => {
      if (response.data.info.accessToken) {
        TokenService.setUser(response.data);
      }
      return response.data;
    });

};
const logout = () => {
  api.delete("/auth/logout");
  TokenService.removeUser();
  checkCurrentUser();

};

const getCurrentUser = () => {
  return JSON.parse(localStorage.getItem("user"));
};

const checkCurrentUser = async ()  => {
api.get("/auth/check").then((response) => {
  if (response.data.info?.accessToken) {
    TokenService.setUser(response.data);
  }
  else {
    TokenService.removeUser();
  }
  
});

return await api.get("/auth/check");
}


const Forgot = (email) => {
  return api 
  .post("/reset/forgot", {
    email,
  });
};
const Reset = (data) => {
 
  return api 
  .post("/reset/reset", {
    ...data
  });
};
export default {
  register,
  login,
  logout,
  Forgot,
  getCurrentUser,
  Reset,
  checkCurrentUser
 

};
