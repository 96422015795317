import React, { useEffect,  useState, createContext } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { getMovieDetail } from '../../actions/index';
import { useParams, Link  } from 'react-router-dom';
import '../../css/product-details.css';
import { Carousel } from 'react-responsive-carousel';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {addMovieFavorite, addToCart} from "../../actions/index";
import {formatCurrency} from "../../utils/formatCurrency";
import UserService from "../../services/user.service";
import Question from './Question';
import Review from './Review';
import { useNavigate } from "react-router-dom";


export const ItemContext = createContext(null);
export function Movie ({ socket }) {
    
    const navigate = useNavigate();
    const movie = useSelector((state) => state.publicActions.movieDetail);
    const { user: currentUser } = useSelector((state) => state.auth);
    const dispatch = useDispatch();
    const { SKU } = useParams();   
    const [itemRate, setItemRate] = useState(0);
    const [itemRateCount, setItemRateCount] = useState(0); 
    const [item , setItem] = useState(0);
    



   useEffect ( () => { 
         
    let mounted = true;
       dispatch(getMovieDetail(SKU)).then ( () => {
        const id = movie.id;
        if (id !== undefined) {
            setItem(movie);
       UserService.getRate(id).then(
            (response) => {
                setItemRate(response.data);
                setItemRateCount(response.data.length);
            }); 
    }

  return () => {
    mounted = false;
  }
    }); 

    }, [dispatch, SKU, movie.id, itemRateCount]);

   
    function addFavorite(movie) {
        if (currentUser) {
          UserService.addWishListUser({product_id:movie.id}).then(() => {
          dispatch(addMovieFavorite(movie));
          }) }
        else {
          dispatch(addMovieFavorite(movie));
        }  
        }
        function addCart(movie) {
            if (currentUser) {
              UserService.addCartListUser({product_id:movie.id}).then(() => {
              dispatch(addToCart(movie));
              }) }
            else {
              dispatch(addToCart(movie));
            }  
            
            }



        return (
            <div>

      

                <section className="inner-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-6">
                        <div className="details-gallery">
                            <div className="details-label-group">
                            {movie.sectionA === null ? null :   <label className="label-text sale">{movie.sectionA}</label>}
                                 {movie.sectionB === null ? null :   <label className="label-text off">{movie.sectionB}</label>}
                                    {movie.sectionC === null ? null :   <label className="label-text new">{movie.sectionC}</label>}
                                    {movie.sectionD === null ? null :   <label className="label-text feat">{movie.sectionD}</label>}
                                    </div>
                                
                                    <div className="details-preview">
                                     <Carousel
                                    autoPlay
                                    infiniteLoop
                                    showThumbs={true}
                                    showStatus={true}
                                    showIndicators={true}
                                    showArrows={true}
                                    dynamicHeight={false}
                                    height={100}
                                    >  
                                         <img src={process.env.PUBLIC_URL +"/" + movie.image } alt={movie.Title} />
                                         <img src={process.env.PUBLIC_URL + "/" +movie.image } alt={movie.Title} />
                                         <img src={process.env.PUBLIC_URL +"/" + movie.image } alt={movie.Title} />
                                         <img src={process.env.PUBLIC_URL +"/" + movie.image } alt={movie.Title} />
                                         <img src={process.env.PUBLIC_URL + "/" +movie.image } alt={movie.Title} />
                                         <img src={process.env.PUBLIC_URL +"/" + movie.image } alt={movie.Title} />
                                         <img src={process.env.PUBLIC_URL + "/" +movie.image } alt={movie.Title} />
                                        </Carousel>
                                    </div>
                                   
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-6">
                            <div className="details-content">
                            <h3 className="details-name">
                                        <a to="product-video.html">{movie.products}</a>
                                    </h3>
                                    <div className="details-meta">
                                       
                                        <p>Marca:   <Link  to={`/categories/sub/product/${movie.subcategory}/${movie.brand}`} key={movie.SKU + 'Masvendidosproduct-rating'}>{movie.brand}</Link></p>
                                        <p>Calidad: {movie.quality} </p>
                                    </div>
                                    <div className="details-meta">
                                        <p>Color: {movie.color} </p>
                                        <p>Disponibles: {movie.stock} </p>

                                    </div>
                                    <div className="details-rating">
                                    {[...Array(5)].map((star, i) => { 
                                            const ratingValue = i + 1;
                                            return (
                                                <i key={ratingValue}>
                                                  
                                                    <FontAwesomeIcon icon={["fas", "star"]}  style={ 
                                                         ratingValue <= itemRate.rateAverage  ?  { color: "#ffc107", stroke: "gold", strokeWidth: 25 } :

                                                    
                                                       {color: "#e4e5e9"}
                                                    } ></FontAwesomeIcon>
                                                     
                                                    

                                                </i>
                                            );
                                        })}
                                       
                                      
                                    </div>

                                    {movie.inOffer === true ?
                    
                                    <h3 className="details-price">
                                        <span> de </span><del>{formatCurrency(movie.price)} </del>
                                        <span>a &nbsp; {formatCurrency(movie.offerPrice)}<small>/pieza</small></span>
                                    </h3>
                                    :
                                    <h3 className="details-price">
                                        <span> {formatCurrency(movie.price)}<small>/pieza</small></span>
                                    </h3>
                                    }
                                    <p className="details-desc">{movie.description}</p>
                                    <div className="details-list-group">
                                        <label className="details-list-title">Categorias:</label>
                                        <ul className="details-tag-list">
                                            
                                            <li><a to="#">{movie.category}</a></li>
                                            <li><a to="#">{movie.subcategory}</a></li>
                                        </ul>
                                    </div>
                                    <div className="details-list-group">
                                        <label className="details-list-title">Compartir:</label>
                                        <ul className="details-share-list">
                                            <li><a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=www.maceronu.com/producto/${movie.SKU}`}  className="icofont-facebook" title="Facebook"><FontAwesomeIcon icon={['fab', 'fa-facebook']}/></a></li>
                                            <li><a target="_blank" href={`https://twitter.com/intent/tweet?text=www.maceronu.com/producto${movie.SKU}`} className="icofont-twitter" title="Twitter"><FontAwesomeIcon icon={['fab', 'fa-twitter']}/></a></li>
                                            <li><a target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=www.maceronu.com/producto/${movie.SKU}&title=La%20mejor%20tienda&summary=Compra%20Ahora%20!&source=www.maceronu.com/producto/${movie.SKU}`} className="icofont-linkedin" title="Linkedin"><FontAwesomeIcon icon={['fab', 'fa-linkedin']}/></a></li>
                                            <li><a target="_blank" href={`https://pinterest.com/pin/create/button/?url=www.maceronu.com/producto/${movie.SKU}&media=https%3A//drive.google.com/file/d/1pK9lbRURwV-kJ3dmGakkw5SUW5E-YmgO/view?usp=sharing&description=Compra%20Ahora%20!`} className="icofont-instagram" title="Instagram"><FontAwesomeIcon icon={['fab', 'fa-instagram']}/></a></li>
                                        </ul>
                                    </div>
                                    <div className="details-add-group">
                                        <button className="product-add" title="Add to Cart" onClick={() => addCart(movie)}>
                                            <i className="fas fa-shopping-basket"><FontAwesomeIcon icon={['fas', 'fa-shopping-basket']}/></i>
                                            <span>al carrito</span>
                                        </button>
                                        <div className="product-action">
                                            <button className="action-minus" title="Quantity Minus"><i className="icofont-minus"></i></button>
                                            <input className="action-input" title="Quantity Number" type="text" name="quantity" />
                                            <button className="action-plus" title="Quantity Plus"><i className="icofont-plus"></i></button>
                                        </div>
                                    </div>
                                    <div className="details-action-group">
                                        <a className="details-wish wish" title="Add Your Wishlist" onClick= {() => (addFavorite(movie))}>
                                            <i className="icofont-heart"><FontAwesomeIcon icon={['fas', 'fa-heart']}/></i>
                                            <span>a favoritos</span>
                                        </a>
                                      { /*  <a className="details-compare" to="compare.html" title="Compare This Item">
                                            <i className="fas fa-random"><FontAwesomeIcon icon={['fas', 'fa-random']}/></i>
                                            <span>Comparar</span>
                                        </a> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
           
        </section>
        <section className="inner-section">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="product-details-frame">
                            <h3 className="frame-title">Descripcion</h3>
                            <div className="tab-descrip">
                                <p>{movie.description}</p>
                            </div>
                        </div>
                        <div className="product-details-frame">
                            <h3 className="frame-title">Especificaciones</h3>
                            <table className="table table-bordered">
                                <tbody>
                                    <tr>
                                        <th scope="row">Marca</th>
                                        <td><Link  to={`/categories/sub/product/${movie.subcategory}/${movie.brand}`} key={movie.SKU + 'Masvendidosproduct-rating'}>{movie.brand}</Link></td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Color:</th>
                                        <td>{movie.color} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Calidad: </th>
                                        <td>{movie.quality} </td>
                                    </tr>
                                    <tr>
                                        <th scope="row">Categorias: </th>
                                        <td>{movie.subcategory} | {movie.category}</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                        <ItemContext.Provider value={{item, setItem, socket}} >
                           { currentUser ?  <div><Question/>  <Review/> </div>:  
                        <div className="col-lg-12">
                            <button className="btn btn-inline">
                                <i className="icofont-water-drop"></i>
                               <span onClick={()=> (navigate("/login"))}>Inicia sesion para preguntar</span>  
                               
                            </button>
                        </div>}
                        </ItemContext.Provider>

                        
                    </div>
                </div>
            </div>
        </section>
        </div>
                             
          
        )         
      }


export default Movie;
