import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../css/user-auth.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, NavLink } from "react-router-dom";



function Registration() {

    const responseFacebook = () => {

        window.open(API + "/auth/facebook", "_self");    
    }
const responseGoogle = () => {
    window.open(API + "/auth/google", "_self");
}

const responseTwitter = () => {
    window.open(API + "/auth/twitter", "_self");
}

const responseInstagram = () => {
    window.open(API + "/auth/facebook", "_self");
}

    const initialValues = {
      user: "",
      username: "",
      lastname: "",
      password: "",
      email: "",
 
    };
    const API = process.env.REACT_APP_URL;
    const navigate = useNavigate();   
    const validationSchema = Yup.object().shape({
        username: Yup.string().min(3,"minimo 3 caracteres").max(15, "maximo 15 caracteres").required("campo requerido"),
        lastname: Yup.string().min(3,"minimo 3 caracteres").max(15, "maximo 15 caracteres").required("campo requerido"),
        password: Yup.string().min(8, "minimo 8 caracteres").max(20, "maximo 15 caracteres").required("campo requerido"),
        email:    Yup.string().email("Correo no valido").required("campo requerido"),
        passwordvalidation: Yup.string().oneOf([Yup.ref('password'), null], 'Tu contraseña debe coincidir').required("campo requerido"),
      });

      const onSubmit = (data) => {
        axios.post(API + "/auth", data).then((response) => {
            
        if (response.data === "Usuario creado!") {
            alert("Usuario creado con exito");
            navigate("/login");
        }
else {
            alert("Error al crear usuario, ya existe un usuario con ese correo");
}
            
        });
      };


    return (
<div>
<Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema} >
    <section className="user-form-part">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-12 col-lg-12 col-xl-10">
                <div className="user-form-logo">
                    <a href="https://www.maceronu.com"><img src="images/logotipo2.svg" alt="logo"/></a>
                </div>
                <div className="user-form-card">
                    <div className="user-form-title">
                        <h2>¡Únete a nuestra comunidad!</h2>
                        <p>¡Crea tu cuenta en segundos!</p>
                    </div>
                    <div className="user-form-group">
                    <ul className="user-form-social">
                                
     
                                    
                                <li>
                             
                                  <a  onClick={ ()=>responseFacebook() } className="facebook"><i className="fab fa-facebook-f">
                                <FontAwesomeIcon  icon={['fab', 'fa-facebook-f']}/></i>ingresar con facebook</a></li> 
                                <li><a onClick={ ()=>responseTwitter() } className="twitter"><i className="fab fa-twitter"><FontAwesomeIcon  icon={['fab', 'fa-twitter']}/></i>ingresar con twitter</a></li>
                                <li><a onClick={ ()=>responseGoogle() } className="google"><i className="fab fa-google"><FontAwesomeIcon  icon={['fab', 'fa-google']}/></i>ingresar con google</a></li>
                                <li><a onClick={ ()=>responseInstagram() } className="instagram"><i className="fab fa-instagram"><FontAwesomeIcon  icon={['fab', 'fa-instagram']}/></i>ingresar con instagram</a></li>
                            </ul>
                 
      
                        <div className="user-form-divider">
               
                            <p> Otro </p>
                        </div>
                        <Form  className="user-form">

                        <div className="form-group">
                            <ErrorMessage name="user" component="span" />
          <Field
            autocomplete="off"
            className="form-control"
            name="user" 
            placeholder="Tu nombre de usuario"
          /></div>
                            <div className="form-group">
                            <ErrorMessage name="username" component="span" />
          <Field
            autocomplete="off"
            className="form-control"
            name="username" 
            placeholder="Escribe tu nombre"
          />
                            </div>
                            <div className="form-group">
                            <ErrorMessage name="lastname" component="span" />
          <Field
            autocomplete="off"
            className="form-control"
            name="lastname" 
            placeholder="Escribe tu apellido"
          />
                            </div>
                            <div className="form-group">
                            <ErrorMessage name="email" component="span" />
                            <Field
            autocomplete="off"
            className="form-control"
            name="email" 
            placeholder="Escribe tu email"
          />
                              
                            </div>
                            <div className="form-group">
                            <ErrorMessage name="password" component="span" />
                            <Field
            autocomplete="off"
            className="form-control"
            name="password" 
            placeholder="Escribe tu contraseña"
          />
                            
                            </div>
                            <div className="form-group">
                            <ErrorMessage name="passwordvalidation" component="span" />
                            <Field
            autocomplete="off"
            className="form-control"
            name="passwordvalidation" 
            placeholder="Repite tu contraseña"
          />
                            </div>
                            <div className="form-check mb-3">
                                <input className="form-check-input" type="checkbox" id="gridCheck"/>
                                <label className="form-check-label" for="check">Acepto<a href="/About">Terminos y Condiciones</a></label>
                            </div>
                            <div className="form-button">
                                <button type="submit">Registrar</button>
                            </div>
                        </Form>
                       
                    </div>
                </div>
                
                <div className="user-form-remind">
                    <p>Ya tienes una cuenta?<NavLink to="/login">Ingresa</NavLink></p>
                </div>
                <div className="user-form-footer">
                    <p>Maceronu Todos los derechos by <NavLink to="/privacy"> Maceruno </NavLink></p>
                </div>
            </div>
        </div>
    </div>
</section>
</Formik>
</div>
);
}
export default Registration;