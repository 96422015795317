import React, {useState, useLayoutEffect} from "react";
import {NavLink, useParams} from "react-router-dom";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import  celular  from "../../../src/img/iconos/celulares.svg";
import  herramientas  from "../../../src/img/iconos/herramientas1.svg";
import  herramienta  from "../../../src/img/iconos/herramientas2.svg";
import  accesorios  from "../../../src/img/iconos/accesorios2.svg";
import  pcaccesorios  from "../../../src/img/iconos/accesorios2.svg";
import  componentes  from "../../../src/img/iconos/componentes.svg";
import  desarmador  from "../../../src/img/iconos/desarmador.svg";
import  escritorio  from "../../../src/img/iconos/escritorio.svg";
import  otros  from "../../../src/img/iconos/otros.svg";
import  portatil  from "../../../src/img/iconos/portatil.svg";
import  refacciones  from "../../../src/img/iconos/refacciones.svg";
import  telefonia  from "../../../src/img/iconos/telefonia.svg";
import  telefoniafija  from "../../../src/img/iconos/telefoniafija.svg";
import  otro  from "../../../src/img/iconos/otros2.svg";
import  pcotro  from "../../../src/img/iconos/otros1.svg";


export function Categories () {
 
    const {id} = useParams();
    const [subcategory, setSubcategory] = useState([]);
    const API = process.env.REACT_APP_URL;

    useLayoutEffect (() => {
        fetch( API + '/categories/sub/'+id)
        .then(res => res.json())
        .then(data => {
            setSubcategory(data);
        }
        ).catch();
    } , []);
    

    return (
        <section className="inner-section">
            <div className="container">
            <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5 justify-content-center">
                {subcategory.map((categories) => (
                    <div className="col">
                        <div className="category-wrap">
                            <div className="category-media">
                                {categories.id === 1 ? <img src={celular} alt="celulares"/> :
                                 categories.id === 2 ? <img src={refacciones} alt="refacciones"/> :
                                categories.id === 3 ? <img src={accesorios} alt="accesorios"/> :
                                categories.id === 4 ? <img src={herramientas} alt="herramientas"/> :
                                categories.id === 5 ? <img src={telefoniafija} alt="telefonia fija"/> :
                                categories.id === 6 ? <img src={telefonia} alt="telefonia"/> :
                                categories.id === 7 ? <img src={otros} alt="otros"/> :
                                categories.id === 8 ? <img src={escritorio} alt="computadoras de escritorio"/> :
                                categories.id === 9 ? <img src={portatil} alt="computadoras portatiles"/> :
                                categories.id === 10 ? <img src={pcotro} alt="accesorios"/> :
                                categories.id === 11 ? <img src={herramienta} alt="herramienta"/> :
                                categories.id === 12 ? <img src={escritorio} alt="Componentes pc"/> :
                                categories.id === 13 ? <img src={otro} alt="otros"/> :
                                categories.id === 14 ? <img src={desarmador} alt="desarmador"/> : <img src={pcotro} alt="Otros"/>}
                                
                                <NavLink to={`/categories/sub/product/${categories.name}`} className="category-overlay">

                                <div className="category-overlay">
                                    <i className="fas fa-link"><FontAwesomeIcon icon={['fas', 'fa-right-long']}/> </i>
                                </div>
                                </NavLink>
                            </div>
                            <div className="category-meta">
                                <h4>{categories.name}</h4>
                                <p>({categories.description})</p>
                            </div>
                        </div>
                    </div>
                ))}


                    </div>
                </div>
                    </section>
    );
}

export default Categories;