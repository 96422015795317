import axiosInstance from "./api";
import TokenService from "./token.service";
import { refreshToken } from "../actions/auth";
import EventBus from "../utils/EventBus";
const setup = (store) => {
  axiosInstance.interceptors.request.use(
    (config) => {
      const token = TokenService.getLocalAccessToken();
      if (token) {
       
        config.headers["x-access-token"] = token; 
      }
      return config;
    },
    (error) => {
      return Promise.reject(error);
    }
  );
  const { dispatch } = store;
  axiosInstance.interceptors.response.use(
    (res) => {
      return res;
    },
    async (err) => {
      const originalConfig = err.config;
     
      if (originalConfig?.url !== "/auth/login" && err.response) {
        // Access Token was expired
        if (err.response.status === 401 && !originalConfig._retry) {
          originalConfig._retry = true;
          try {
            
            const rs = await axiosInstance.post("auth/refreshtoken", {
              refreshToken: TokenService.getLocalRefreshToken()
              
            }).then (res => {
              
              TokenService.updateLocalAccessToken(res.data.accessToken);
              originalConfig.headers["x-access-token"] = res.data.accessToken;
              return axiosInstance(originalConfig);
            }).catch(err => {
              if( err.response.status === 403) {
              
              EventBus.dispatch("logout");
              
              axiosInstance.delete("/auth/logout");
              window.location.reload("/");
              }
            });
          
            
          } catch (_error) {
            return Promise.reject(_error);
          }
        }
      }
      return Promise.reject(err);
    }
  );
};
export default setup;