import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from 'react-responsive-carousel';
import '../../css/faq.css';


export default function Help() {
    return (  

<div key='Aboutkey'> 
<section className="inner-section faq-part">
<div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <nav className="nav nav-pills flex-column" id="scrollspy">
                            <a className="nav-link" href="#item-1">¿Cómo son los pagos seguros?</a>
                            <a className="nav-link" href="#item-2">¿Cómo sé que el pago se efectuó correctamente?</a>
                            <a className="nav-link" href="#item-3">¿Puedo pagar en efectivo y recibir mi pedido?</a>
                            <a className="nav-link" href="#item-4">¿Cómo solicito una factura?</a>
                            <a className="nav-link" href="#item-5">No puedo encontrar una respuesta a mi pregunta, ¿cómo me pongo en contacto?</a>
                        </nav>
                    </div>
                    <div className="col-lg-9">
                        <div data-bs-spy="scroll" data-bs-target="#scrollspy" data-bs-offset="0" tabIndex="0">
                            <div className="scrollspy-content" id="item-1">
                                <h3>¿Cómo son los pagos seguros?</h3>
                                <p>En nuestra página, ofrecemos varios métodos de pago seguros, incluyendo tarjetas de crédito y débito, PayPal y transferencias bancarias. Además, utilizamos tecnología de encriptación para proteger tus datos personales y financieros durante el proceso de pago.</p>
                            </div>
                            <div className="scrollspy-content" id="item-2">
                                <h3>¿Cómo sé que el pago se efectuó correctamente?</h3>
                                <p>
                                Después de realizar el pago, recibirás un correo electrónico de confirmación que incluirá un resumen de tu pedido y el comprobante de pago correspondiente. También puedes verificar el estado de tu pedido en tu cuenta de usuario en nuestra página web.
                                 </p>
                            </div>
                            <div className="scrollspy-content" id="item-3">
                                <h3>¿Puedo pagar en efectivo y recibir mi pedido?</h3>
                                <p>Sí, puedes pagar en efectivo y recibir tu pedido. Ofrecemos la opción de pago en efectivo a través de Oxxo, en la que recibirás un número de referencia para realizar el pago en cualquier sucursal Oxxo. Una vez que se haya confirmado el pago, enviaremos tu pedido. Asegúrate de proporcionar una dirección de entrega precisa al momento de realizar tu pedido para que podamos entregarlo sin problemas. </p>
                            </div>
                            <div className="scrollspy-content" id="item-4">
                                <h3>¿Cómo solicito una factura?</h3>
                                <p>Después de realizar tu compra, te enviaremos la factura correspondiente por correo electrónico. Si necesitas una factura con información específica, por favor contáctanos a través de nuestro servicio de atención al cliente con los detalles de tu solicitud.</p>
                            </div>
                            <div className="scrollspy-content" id="item-5">
                                <h3>No puedo encontrar una respuesta a mi pregunta, ¿cómo me pongo en contacto?</h3>
                                <p>Si tienes alguna pregunta o preocupación sobre tu pedido, puedes ponerte en contacto con nuestro equipo de atención al cliente por correo electrónico o por teléfono. También puedes utilizar nuestro chat en línea para obtener ayuda en tiempo real. Estamos disponibles para ayudarte de lunes a viernes de 9 a.m. a 5 p.m. hora local.</p>
                                <ul className="footer-contact">
                                <li>
                                    <i className="icofont-ui-email"><FontAwesomeIcon  icon={['fas', 'fa-envelope']}/></i>
                                    <p>
                                    <a href="mailto:soporte@maceronu.com?Subject=Hola%20Maceronu%20quiero%20informes"><span> soporte@maceronu.com</span></a>
                                        
                                    </p>
                                </li>
                                <li>
                                    <i className="icofont-ui-touch-phone"><FontAwesomeIcon  icon={['fab', 'fa-whatsapp']}/></i>
                                    <p>
                                    <a href='https://api.whatsapp.com/send?phone=+524773959779&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"><span>+52 477-395-9779</span> </a> <br/>
                                    
                                        <a href='https://api.whatsapp.com/send?phone=+524778751774&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"> <span>+52 477-875-1774</span> </a>
                                    </p>
                                </li>
                               </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
)
}