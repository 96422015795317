export default function authHeader() {
    const user = JSON.parse(localStorage.getItem('user'));
   
    if (user && user?.info?.accessToken) {
      // for Node.js Express back-end
      
      return { 'token': user.accessToken,
    id: user.user.id,
    username: user.user.username,
    email: user.user.email, };
    } else {
      return {};
    }
  }