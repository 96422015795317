import  React, {useEffect, useState} from   'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { useNavigate, Link} from 'react-router-dom';
import { addToCart} from "../../actions/index";
import {  useDispatch } from "react-redux";
import authHeader from '../../services/auth-header';
import userService from '../../services/user.service';



export default function OrderHistory({user}) {
const navigate = useNavigate();
    const [orders, setOrders] = useState([]);
const [order , setOrderView] = useState(null);
const [orderWindow , setOrderWindow] = useState(false);
const dispatch = useDispatch();  

const API = process.env.REACT_APP_URL;
useEffect(() => {
    (async () => {
    fetch( API + '/order/user/'+ user.user.id  , { headers: authHeader() })
        .then((res) => res.json())
        .then((data) => setOrders(data));
    })();
}, [user]);

const orderDetails = (id) => {
    setOrderWindow(id.paymentIntent);
 setOrderView(id);


}

const buyAgain = () => {
   
    order.orderItems?.map((item) => { 
        
        
        for (let i = 0; i < item.quantity; i++) {
 
            userService.addCartListUser({product_id:item.product.id}).then(() => {
                dispatch(addToCart(item.product));
                navigate('/checkout');
                })    
    };
               
    });
}


    return ( 
<section class="inner-section orderlist-part">
            <div class="container">
                <div class="row">
                    <div class="col-lg-12">
                        <div class="orderlist-filter">
                            <h5>Total de ordenes <span>- ({orders?.length})</span></h5>
                            <div class="filter-short">
                                <label class="form-label">Ordenar por:</label>
                                <select class="form-select">
                                    <option value="all" selected>Todas</option>
                                    <option value="recieved">Generadas</option>
                                    <option value="processed">Procesadas</option>
                                    <option value="shipped">Enviadas</option>
                                    <option value="delivered">Recibidas</option>
                                </select>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row">
                    <div class="col-lg-12">
                     
                                {orders?.map((orderSimple, index) => ( 
                                <div key={index}>
                                <div class="orderlist" key={orderSimple.id} onClick= {()=> orderDetails({...orderSimple}) }>
                                <div class="orderlist-head">
                                <h5>Orden: #{orderSimple.id}</h5>
                                <h5>{orderSimple.orderItems[0].product.products} <img width={50} height={50} src={process.env.PUBLIC_URL + "/" + orderSimple.orderItems[0].product.image} />  </h5>
                                <h5>Fecha: {new Date(orderSimple.createdAt).toLocaleString() }</h5>
                                <h5>Total: ${orderSimple.total}</h5>
                                </div>
                                </div>
                                { orderWindow === orderSimple.paymentIntent && order !== null ?   <>  


<div class="orderlist-body" key={orderSimple.id}>
    <div class="row">
        <div class="col-lg-12">
            <div class="order-track">
                <ul class="order-track-list">
                    <li class="order-track-item active">
                        <i class="icofont-check"> <FontAwesomeIcon icon={['fas',"fa-check"]}></FontAwesomeIcon></i>
                        <span>Orden recibida</span>
                    </li>
                    
                        { orderSimple.status ==="paid" || orderSimple.shipment?.status ==="shipped" || orderSimple.shipment?.status ==="delivered" ? <li class="order-track-item active"> <i class="icofont-check"> <FontAwesomeIcon icon={['fas',"fa-check"]}></FontAwesomeIcon></i> <span>Orden procesada</span></li> : <li class="order-track-item"><i class="icofont-close"> <FontAwesomeIcon icon={['fas',"fa-clock"]}></FontAwesomeIcon></i> <span>Orden procesada</span></li> }
                       
                  
                        { orderSimple.shipment?.status ==="shipped" || orderSimple.shipment?.status ==="delivered" ? <li class="order-track-item active"> <i class="icofont-check"> <FontAwesomeIcon icon={['fas',"fa-check"]}></FontAwesomeIcon></i> <span>Paquete enviado</span></li>: <li class="order-track-item"><i class="icofont-close"> <FontAwesomeIcon icon={['fas',"fa-clock"]}></FontAwesomeIcon></i><span>Paquete enviado</span></li>}
                        

                        { orderSimple.shipment?.status ==="delivered" ? <li class="order-track-item active"> <i class="icofont-check"> <FontAwesomeIcon icon={['fas',"fa-check"]}></FontAwesomeIcon></i> <span>Paquete recibido</span></li>: <li class="order-track-item"><i class="icofont-close"> <FontAwesomeIcon icon={['fas',"fa-clock"]}></FontAwesomeIcon></i><span>Paquete recibido</span></li>}
                        
                    
                </ul>
            </div>
        </div>
        <div class="col-lg-5">
            <ul class="orderlist-details">
                <li>
                    <h6>Orden #</h6>
                    <p>{order.id}</p>
                </li>
                <li>
                    <h6>Total de productos</h6>
                    <p>{order.orderItems.map((item) => item.quantity).reduce((a, b) => a + b, 0)}</p>

                </li>
                <li>
                    <h6>Fecha de creacion</h6>
                    <p>{new Date(order.createdAt).toLocaleString() }</p>
                </li>
                <li>
                    <h6>Fecha de envio</h6>
                    <p>{new Date(order.createdAt).toLocaleString() }</p>
                </li>
            </ul>
        </div>
        <div class="col-lg-4">
            <ul class="orderlist-details">
                   
                
                <li>
                    <h6>Total<small>(Incl. IVA)</small></h6>
                    <p>$ {order.total}</p>
                </li>
            </ul>
        </div>
        <div class="col-lg-3">
            <div class="orderlist-deliver">
                <h6>Lugar de envio </h6>
                {order.address}
           <p> C.product. {order.zipcode} </p>
            <p>Localidad: {order.city}, {order.country}</p>
            <h6>Contacto:{order.phone} </h6>
        
            </div>
        </div>
        <div className="col-lg-12">
<div className="table-scroll">
<table className="table-list">
    <thead>
        <tr>
            <th scope="col">Producto</th>
            <th scope="col">Imagen</th>
            <th scope="col">Calidad</th>
            <th scope="col">Precio</th>
            <th scope="col">Marca</th>
            <th scope="col">Cantidad</th>
        </tr>
    </thead>
    <tbody>
        
            {order.orderItems.map((item) => ( 

<tr key={item.product.SKU}>
            <td className="table-serial"><h6>{item.product.products}</h6></td>
            <td className="table-image"><img src={process.env.PUBLIC_URL + "/" + item.product.image}  alt="product"/></td>
            <td className="table-name"><h6>{item.product.quality}</h6></td>
            <td className="table-price">{item.product.inOffer === true ?  <h6>$ {item.product.offerPrice}<small>C/U</small></h6> : <h6>$ {item.product.price}<small>C/U</small></h6>} </td>
            <td className="table-brand"><h6>{item.product.brand}</h6></td>
            <td className="table-quantity"><h6>{item.quantity}</h6></td>
            </tr>
            ))}

       

    </tbody>
</table>
</div>

</div>
    </div>



</div>
<div class="container">
<div className='row'>
<div class="col">
<h6> 
Tienes problemas con el producto? <Link to="/contact"><button class="btn btn-danger btn-sm" >  Contactanos </button> </Link> 

</h6>
</div>

<div class="col">
<h6>
Quieres volver a comprar? <button class="btn btn-success btn-sm"  onClick={() => buyAgain() }> Volver a comprar! </button>
</h6>
</div>
</div>
</div>
<br/>

</> : ( null

) }
                                </div>
                            ))}

                           



                        </div>

                  
                </div>
                {/* <div class="row">
                    <div class="col-lg-12">
                        <ul class="pagination">
                            <li class="page-item">
                                <a class="page-link" href="#">
                                    <i class="icofont-arrow-right"></i>
                                </a>
                            </li>
                            <li class="page-item"><a class="page-link active" href="#">1</a></li>
                            <li class="page-item"><a class="page-link" href="#">2</a></li>
                            <li class="page-item"><a class="page-link" href="#">3</a></li>
                            <li class="page-item">...</li>
                            <li class="page-item"><a class="page-link" href="#">6</a></li>
                            <li class="page-item">
                                <a class="page-link" href="#">
                                    <i class="icofont-arrow-left"></i>
                                </a>
                            </li>
                        </ul>
                    </div>
                </div> */}
            </div>
        </section>

    )

    }