import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from 'react-responsive-carousel';
import '../../css/faq.css';


export default function Help() {
    return (  

<div key='Aboutkey'> 
<section class="inner-section faq-part">
<div class="container">
                <div class="row">
                    <div class="col-lg-3">
                        <nav class="nav nav-pills flex-column" id="scrollspy">
                            <a class="nav-link" href="#item-1">Como me pongo en contacto con atencion al cliente?</a>
                            <a class="nav-link" href="#item-2">No encuentro un producto, puedo efectuarlo bajo pedido?</a>
                            <a class="nav-link" href="#item-3">Website es lento, como puedo mejorar la navegacion?</a>
                            <a class="nav-link" href="#item-4">Como creo una cuenta?</a>
                            <a class="nav-link" href="#item-5">No puedo encontrar una respuesta a mi pregunta!</a>
                        </nav>
                    </div>
                    <div class="col-lg-9">
                        <div data-bs-spy="scroll" data-bs-target="#scrollspy" data-bs-offset="0" tabindex="0">
                            <div class="scrollspy-content" id="item-1">
                                <h3>Como me pongo en contacto con atencion al cliente?</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam officia expedita beatae tempore facilis ex maiores, assumenda nostrum minus. Autem nemo corrupti consequuntur incidunt quibusdam dicta. Quasi atque deserunt totam hic voluptatibus veritatis. Ducimus dicta esse praesentium tenetur obcaecati reprehenderit, recusandae ab explicabo maxime deserunt mollitia. Aliquid distinctio tenetur dolore!</p>
                            </div>
                            <div class="scrollspy-content" id="item-2">
                                <h3>No encuentro un producto, puedo efectuarlo bajo pedido?</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam officia expedita beatae tempore facilis ex maiores, assumenda nostrum minus. Autem nemo corrupti consequuntur incidunt quibusdam dicta. Quasi atque deserunt totam hic voluptatibus veritatis. Ducimus dicta esse praesentium tenetur obcaecati reprehenderit, recusandae ab explicabo maxime deserunt mollitia. Aliquid distinctio tenetur dolore!</p>
                            </div>
                            <div class="scrollspy-content" id="item-3">
                                <h3>Website reponse taking time, how to improve?</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam officia expedita beatae tempore facilis ex maiores, assumenda nostrum minus. Autem nemo corrupti consequuntur incidunt quibusdam dicta. Quasi atque deserunt totam hic voluptatibus veritatis. Ducimus dicta esse praesentium tenetur obcaecati reprehenderit, recusandae ab explicabo maxime deserunt mollitia. Aliquid distinctio tenetur dolore!</p>
                            </div>
                            <div class="scrollspy-content" id="item-4">
                                <h3>How do I create a account?</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam officia expedita beatae tempore facilis ex maiores, assumenda nostrum minus. Autem nemo corrupti consequuntur incidunt quibusdam dicta. Quasi atque deserunt totam hic voluptatibus veritatis. Ducimus dicta esse praesentium tenetur obcaecati reprehenderit, recusandae ab explicabo maxime deserunt mollitia. Aliquid distinctio tenetur dolore!</p>
                            </div>
                            <div class="scrollspy-content" id="item-5">
                                <h3>I cannot find an answer to my question!</h3>
                                <p>Lorem ipsum dolor sit amet consectetur adipisicing elit. Quisquam officia expedita beatae tempore facilis ex maiores, assumenda nostrum minus. Autem nemo corrupti consequuntur incidunt quibusdam dicta. Quasi atque deserunt totam hic voluptatibus veritatis. Ducimus dicta esse praesentium tenetur obcaecati reprehenderit, recusandae ab explicabo maxime deserunt mollitia. Aliquid distinctio tenetur dolore!</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
)
}