import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from 'react-responsive-carousel';
import '../../css/faq.css';


export default function Faqs() {
    return (  

<div key='Aboutkey'> 
<section class="inner-section faq-part">
            <div class="container">
                <div class="row">
                    <div class="col-lg-10 mx-auto">
                        <div class="faq-parent">
                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>¿Cómo rastreo mi pedido?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>Tras haber realizado tu compra, en 24 horas serás contactado 
                                        por Whatsapp y por correo para brindarte la guía de envío que 
                                        genera la paquetería. Si no recibes tu guía tras 24 horas, 
                                        puedes contactarte por medio de Whatsapp al número 477 395 9779 
                                        para saber cómo va tu envío.</p>
                                </div>
                            </div>

                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>Si no encuentro una pieza, ¿se puede realizar un pedido especial?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>Sí, tenemos una gran variedad de artículos disponibles en nuestra página, 
                                        sin embargo, puede ser que haya algún artículo que necesites y que aún no 
                                        esté visible en la página; de ser así, sólo debes contactarte por Whatsapp 
                                        al número 477 395 9779 para solicitar las piezas que necesites.</p>
                                </div>
                            </div>

                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>¿Cuál es la garantía que me brinda Maceronu en las pantallas?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>Las pantallas cuentan con una garantía limitada, siempre que se cumpla con los siguientes requisitos:</p>
                                        <li>La pantalla no debe estar tronada, rota o estrellada, ya sea interna o externamente.</li>
                                        <li>La pantalla no debe venir mojada o húmeda.</li>
                                        <li>La pantalla no debe estar fundida, es decir, tiene que encender.</li>
                                        <li>La pantalla se envía con micas y sellos: éstos no deben ser removidos.</li>
                                        <li>Las peinetas y los flexores no deben estar rotos o dañados.</li>
                                        <li>La pantalla no debe haber sido pegada, no debe tener pegamento, ni debe venir sucia.</li>
                                </div>
                            </div>

                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>¿Cuál es la garantía que me brinda Maceronu en otros productos que no sean pantallas?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>Otros productos que no son pantallas no cuentan con garantía, siendo que:</p>
                                        <li>Las piezas se prueban antes de enviarlas al cliente y se verifica que funcionen al 100%.</li>
                                        <li>Son piezas delicadas y requieren de una instalación efectuada por personal capacitado.</li>
                                        <li>Un mal manejo o manejo no adecuado de estos productos puede dañarlo y con ello, impedir su correcto funcionamiento.</li>
                                        
                                </div>
                            </div>

                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>¿Se puede realizar una devolución de efectivo?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>Es importante que el cliente verifique sus pedidos, tanto en la generación de la compra, como en la recepción del producto, ya que Maceronu no puede realizar la devolución de efectivo.</p>
                                </div>
                            </div>

                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>¿Cuáles son los métodos de pago?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>Aceptamos todas las tarjetas de crédito VISA, Mastercard y AMEX. Adicionalmente, contamos con pagos referenciados en OXXO.</p>
                                </div>
                            </div>

                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>¿Cómo me pongo en contacto con atención al cliente?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>Puedes contactarte por medio de Whatsapp al número 477 395 9779 y un asesor te ayudará a resolver cualquier duda que tengas sobre nuestros productos y servicios.</p>
                                </div>
                            </div>

                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>¿Qué paquetería realizará la entrega de mi pedido?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>Tenemos varios medios de entrega como la paquetería DHL, FedEx, Estafeta, etc., Cualquiera de éstas puede cambiar de acuerdo a las necesidades del cliente o de la empresa.</p>
                                </div>
                            </div>

                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>¿Cuánto tarda en llegar mi pedido?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>El envío de los productos es un proceso rápido en la mayoría de los casos, que va de 1 a 3 días hábiles, sin embargo, puede haber excepciones o conflictos con el envío por parte de la paquetería, las cuales serán notificadas al cliente.</p>
                                </div>
                            </div>

                            <div class="faq-child">
                                <div class="faq-que">
                                    <button>¿Hay descuento si compro por mayoreo?</button>
                                </div>
                                <div class="faq-ans">
                                    <p>Sí, Maceronu actualmente tiene el 5% de descuento en compras mayores a $5,000 pesos. Mientras mayor sea el volumen de piezas y el monto efectivo, mayores descuentos pueden aplicar. Si eres un cliente interesado en compras mayores a esta cantidad, te invitamos a contactarnos por Whatsapp al número 477 395 9779 para solicitar más información.</p>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
)
}