import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import logo from  "../../img/isotipo.svg";
import oxxo from  "../../img/oxxo-color.svg";
import { Link } from "react-router-dom";

const currentYear = new Date().getFullYear();

export default function Footer() 
{

    return ( <div key='Footerkey' name="news-part" id="news-part">
<section className="news-part" >
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-md-5 col-lg-6 col-xl-7">
                        <div className="news-text">
                            <h2>Suscríbete para más ofertas!</h2>
                            <p>Obtén un 5% de descuento adicional en todas tus compras con valor de $5,000 pesos tan sólo ingresando tu correo.</p>
                        </div>
                    </div>
                    <div className="col-md-7 col-lg-6 col-xl-5">
                        <form className="news-form">
                            <input type="text" placeholder="Ingresa tu correo"/>
                            <button><span><i className="icofont-ui-email">  <FontAwesomeIcon icon={['fas', 'fa-at']}/></i>Suscríbete</span></button>
                        </form>
                    </div>
                </div>
            </div>
        </section>
       
        <section className="intro-part">
            <div className="container">
                <div className="row intro-content">
                    <div className="col-sm-6 col-lg-3">
                        <div className="intro-wrap">
                        <Link to="/delivery">
                            <div className="intro-icon">  
                                <i className="fas fa-truck"><FontAwesomeIcon  icon={['fas', 'fa-truck']}/></i>
                            </div>
                            <div className="intro-content">
                                <h5>Envíos a domicilio</h5>
                                <p>Tenemos servicio de entregas a domicilio para toda la república mexicana.</p>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="intro-wrap">
                        <Link to="/return">
                            <div className="intro-icon">
                                <i className="fas fa-sync-alt"><FontAwesomeIcon  icon={['fas', 'fa-sync-alt']}/></i>
                            </div>
                            <div className="intro-content">
                                <h5>Política de devolución</h5>
                                <p>Cambio de pieza o bonificación en otras compras dentro de la tienda.</p>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="intro-wrap">
                        <Link to="/support">
                            <div className="intro-icon">
                                <i className="fas fa-headset"><FontAwesomeIcon  icon={['fas', 'fa-headset']}/></i>
                            </div>
                            <div className="intro-content">
                                <h5>Soporte 24/7</h5>
                                <p>Tenemos atención telefónica, por correo y redes sociales los 365 días del año.</p>
                            </div>
                            </Link>
                        </div>
                    </div>
                    <div className="col-sm-6 col-lg-3">
                        <div className="intro-wrap">
                        <Link to="/payment">
                            <div className="intro-icon">
                                <i className="fas fa-lock"><FontAwesomeIcon  icon={['fas', 'fa-lock']}/></i>
                            </div>
                            <div className="intro-content">
                                <h5>Pagos seguros</h5>
                                <p>Toda la seguridad de que tu pago se realizará exitosamente con VISA, MASTERCARD, AMEX y otros métodos de pago.</p>
                            </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <footer className="footer-part">
            <div className="container">
                <div className="row">
                    <div className="col-sm-6 col-xl-4">
                        <div className="footer-widget">
                            <a className="footer-logo" href="/">
                                <img src={logo} alt="logo"/>
                                <h3 className="logo-mace-footer">MACERONU</h3>
                            </a>
                            <p className="footer-desc">Somos una empresa dedicada a la importación y venta de piezas, accesorios, herramientas y refacciones para celular.</p>
                            <ul className="footer-social">
                           
                            
                            
                           
                                <li><a className="icofont-facebook" href="https://www.facebook.com/WorldCellT"><FontAwesomeIcon  icon={['fab', 'fa-facebook-f']} target="_blank" rel="noreferrer" /></a></li>
                                <li><a className="icofont-twitter"  href="https://twitter.com/WorldCell10"><FontAwesomeIcon  icon={['fab', 'fa-twitter']} target="_blank"/></a></li>
                                <li><a className="icofont-google"  href="https://www.google.com/search?q=maceronu&oq=maceronu&aqs=edge..69i57j0i10l4j69i60j69i61l3.735j0j4&sourceid=chrome&ie=UTF-8&newwindow=1&tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=ALiCzsbYGGD9LBXKCp6arR7SM7cPI7hCNQ:1661376742989&rflfq=1&num=10&rldimm=10441443659896202324&lqi=CghtYWNlcm9udVoKIghtYWNlcm9udZIBBXN0b3Jl&ved=2ahUKEwivierUtuD5AhXzKkQIHeVUAYsQvS56BAgREAE&sa=X&rlst=f#rlfi=hd:;si:10441443659896202324,l,CghtYWNlcm9udVoKIghtYWNlcm9udZIBBXN0b3Jl;mv:[[21.157379,-101.680596],[21.1223802,-101.68202319999999]];tbs:lrf:!1m4!1u2!2m2!2m1!1e1!2m1!1e2!3sIAE,lf:1,lf_ui:2" rel="noreferrer" target="_blank"><FontAwesomeIcon  icon={['fab', 'fa-google']} /></a></li>
                                <li><a className="icofont-instagram" href="https://www.instagram.com/maceronu_/" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fab', 'fa-instagram']} /></a></li>
                                <li><a className="icofont-ML" href="https://worldcellmx.mercadoshops.com.mx/" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fas', 'fa-basket-shopping']} /></a></li>
                                <li><a className="icofont-tiktok" href="https://www.tiktok.com/@maceronu" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fab', 'fa-tiktok']} /></a></li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-sm-6 col-xl-4">
                        <div className="footer-widget contact">
                            <h3 className="footer-title">contacto </h3>
                            <ul className="footer-contact">
                                <li>
                                    <i className="icofont-ui-email"><FontAwesomeIcon  icon={['fas', 'fa-envelope']}/></i>
                                    <p>
                                    <a href="mailto:soporte@maceronu.com?Subject=Hola%20Maceronu%20quiero%20informes"><span> soporte@maceronu.com</span></a>
                                        
                                    </p>
                                </li>
                                <li>
                                    <i className="icofont-ui-touch-phone"><FontAwesomeIcon  icon={['fab', 'fa-whatsapp']}/></i>
                                    <p>
                                    <a href='https://api.whatsapp.com/send?phone=+524773959779&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"><span>+52 477-395-9779</span> </a> <br/>
                                    
                                        <a href='https://api.whatsapp.com/send?phone=+524778751774&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"> <span>+52 477-875-1774</span> </a>
                                    </p>
                                </li>
                                <li>
                                  <i className="icofont-location-pin"><FontAwesomeIcon  icon={['fas', 'fa-location-dot']}/></i>
                                  <a href="https://www.google.com/maps/place/Maceronu/@21.1242551,-101.6806725,15z/data=!4m5!3m4!1s0x0:0x90e775b0025e2454!8m2!3d21.1242551!4d-101.6806725">  <p> 5 de Mayo #216 Col. Centro.
                                Plaza de la TecnologÍa 
                                    Local #184 
                                    C.P. 37000, León, Guanajuato</p></a>
                                </li>
                            </ul>
                        </div>
                    </div>
                    
                    <div className="col-sm-6 col-xl-4">
                        <div className="footer-widget">
                            <h4 className="footer-title">Descarga nuestra app! Próximamente</h4>
                            <p className="footer-desc">Sabemos que te interesa mucho tener nuestra app y estamos ansiosos por que pronto la tengas en tus manos; trabajamos para ti y para tu comodidad, espérala! </p>
                            <div className="footer-app">
                            
                                <a href="https://www.apple.com/app-store"><i><FontAwesomeIcon  icon={['fab', 'fa-app-store-ios']}/></i></a> 
                                <a href="https://play.google.com"><i><FontAwesomeIcon  icon={['fab', 'fa-google-play']}/></i></a> 
                                
                            </div>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-12">
                        <div className="footer-bottom">
                            <p className="footer-copytext">&copy; {currentYear} Todos los derechos reservados <Link to="/about">Maceronu</Link></p>
                            
                          
                               <div className="Footer-brand-icons">
                                <a href="https://www.visa.com"> <FontAwesomeIcon  icon={['fab', 'fa-cc-visa']}/></a> 
                                <a href="https://www.mastercard.com"><FontAwesomeIcon  icon={['fab', 'fa-cc-mastercard']}/></a>
                                <a href="https://www.paypal.com/"><img src={oxxo} /></a>
                                <a href="https://www.americanexpress.com"><FontAwesomeIcon  icon={['fab', 'fa-cc-amex']}/></a>
                                </div>
                                
                            
                        </div>
                    </div>
                </div>
            </div>
        </footer>
        </div>
    );

}

