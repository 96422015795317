import React, { useState, useEffect} from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../css/profile.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { Link } from "react-router-dom";
import { phoneRegExp } from "../../utils/validation";
import UserService from "../../services/user.service";
import authService from "../../services/auth.service";
import ingresa from  "../../img/ingresa.svg";



export default function MyAccount ({user}) {
 
 /// verify auth status //    
 const [show, setShow] = useState(null);
 const handleClose = () => setShow(false);
 const handleShow = () => setShow(true);
 const [active , setActive] = useState("");
 const handleActive = (Event) => {
     setActive(Event.target.id);
   };
 const [addressMapUser, setAddress] = useState([]);
const [currentUser, setCurrentUser] = useState({});

useEffect(() => {
    let componentMounted = true;
    setCurrentUser(user); 
    if (user === undefined || user === null) {
        const userauth = authService.getCurrentUser()
        if (userauth) {
            setCurrentUser(userauth);
                
            }
    }
       
    
    return () => {
      componentMounted = false;
    }
  }, [user]); 

 
      useEffect(() => {
        UserService.getAddresses().then(response => {
                setAddress(response.data);
                setShow(false);
            }
        );
       
}, []);


          
// formink address validation  ///      

const initialValues = {
    names: "",
    id: "", 
    address: "",
    phone: "",
    city: "",
    country: "",
    zipcode: ""
};

const AddressSchema = Yup.object().shape({
  
    phone: Yup.string()
      .required("Número de teléfono requerido")
      .matches(phoneRegExp, "Número de teléfono inválido, se requieren 10 dígitos")
      .min(10, "Número de teléfono muy corto, se requieren 10 dígitos")
      .max(10, "Número de teléfono muy largo, se requieren 10 dígitos"),
      address: Yup.string().required("Nuúmero de domicilio y calle requerido"),
    city: Yup.string().required("La ciudad dónde vives es requerida"),
    country: Yup.string().required("El Estado dónde vives requerido"),
    zipcode: Yup.string().required("El código Postal es requerido!").min(5, "El código Postal es muy corto").max(5, "El código Postal es muy largo"),
    
  });
 function  onSubmitAddres (data)  { 
   
    UserService.addAddresses(data).then(response => {
            UserService.getAddresses().then(response => {
              
      window.location.reload();
                    
                    
                }
            )},[]);

            }
function onSubmitEditAddress  (data, id)  {
    
    let newData =  {...data, id: id};
    UserService.updateAddress(newData).then(response => {
        UserService.getAddresses().then(response => {
           
            setAddress(response.data);
            window.location.reload();
        }
    )}, []);
    }
    function onSubmitDeleteAddress  (id)  {
let newData =  {del: id};
    UserService.deleteAddress(newData).then(response => {
        window.location.reload();
       
    }, []);
}
function onSubmitEditUser  (data)  {
   
    UserService.updateUser(data).then(response => {
            window.location.reload();
         
    }, []);
}

  
  ///modal bootstrap // 
 
    
    
      
  



return (
<div key='user-auth'>
    <section className="inner-section single-banner">
    <div className="container">
        <h2>mi perfil</h2>
        <ol className="breadcrumb">
            <li className="breadcrumb-item"><Link to="/">Tienda</Link></li>
            <li className="breadcrumb-item active" aria-current="page">Perfil</li>
        </ol>
    </div>
</section>

<section className="inner-section profile-part">
    <div className="container">
        <div className="row">
            <div className="col-lg-12">
                <div className="account-card">
                    <div className="account-title">
                        <h4>Tu perfil</h4>
                        <button data-bs-toggle="modal"  key="profile-edit-modal" data-bs-target="#profile-edit" onClick={handleShow}>Editar perfil</button>
                    </div>
                    <div className="account-content">
                        <div className="row">
                            <div className="col-lg-2">
                                <div className="profile-image">
                                    <a href="#"><img src={currentUser?.user?.picture ? currentUser.user.picture : ingresa} alt="user"/></a>
                                </div>
                            </div>
                            <div className="col-md-6 col-lg-4 col-profile">
                            {currentUser && currentUser.user && currentUser.user.username   && (
                                <div className="form-group">
                            

                                    <label className="form-label">Nombre:</label>
                                    <input  className="form-control" type="text"  defaultValue={`${currentUser?.user?.username+ " " + currentUser?.user?.lastname}`} readOnly=""/> 
                                    
                                    

                                </div>
                                )}
                            </div>
                            <div className="col-md-6 col-lg-4 col-profile">
                                <div className="form-group">
                                    <label className="form-label">Correo</label>
                                    <input className="form-control" type="text" defaultValue={currentUser?.user?.email} readOnly=""/> 
                                </div>
                            </div>
                            <div className="col-lg-2">
                                <div className="profile-btn">
                                    <Link to="/change/pass">Cambiar la contraseña</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
           
            <div className="col-lg-12">
                <div className="account-card">
                    <div className="account-title">
                        <h4>Domicilios de envío</h4>
                        <button data-bs-toggle="modal"  data-bs-target="#address-add" onClick={handleShow}>Agregar Domicilio</button>
                    </div>
                    <div className="account-content">
                        <div className="row-profile">
                            
                               
                                    {addressMapUser.map(addressUser => ( 
                                        
                                            <div className="col-md-6 col-lg-4 alert fade show" key={addressUser.phone + "add" + addressUser.zipcode}>
                                             <div className= {`${"profile-card address" + (active === addressUser.id+addressUser.zipcode ? " active": "")} `}  key={addressUser.id} id={addressUser.id+addressUser.zipcode} onMouseEnter={handleActive}>
                                        <h6>Domicilio de envio:</h6>
                                        <p>Nombres: <label>{addressUser.names}</label></p>
                                        <p>Teléfono:<label>{addressUser.phone}</label></p>
                                        <p>Dirección:<label>{addressUser.address}</label></p>
                                        <p>Ciudad:<label>{addressUser.city}</label></p>
                                        <p>Estado:<label>{addressUser.country}</label></p>
                                        <p>Código postal:<label>{addressUser.zipcode}</label></p>
               
                                        <ul className="user-action">
                                            <li><button className="edit icofont-edit" title="Editar" data-bs-toggle="modal" data-bs-target={"#address-edit"+addressUser.id+addressUser.zipcode} onClick={handleShow}> <FontAwesomeIcon icon={['fas', 'fa-edit']}/></button></li>
                                            <li><button className="trash icofont-ui-delete" title="Remove This" data-bs-dismiss="alert" onClick={()=> onSubmitDeleteAddress(addressUser.id)}><FontAwesomeIcon icon={['fas', 'fa-trash']}/></button></li>
                                        </ul> 
                                        </div> </div> ))}   

</div>
                        
                    </div>
                </div>
            </div>
           
        </div>
    </div>
</section>



  <Formik  
        initialValues={initialValues}
        onSubmit={(values) => { onSubmitAddres(values)}}
        validationSchema={AddressSchema}
        >

<div className="modal fade"  id="address-add" key="address-add">
    <div className="modal-dialog modal-dialog-centered"> 
        <div className="modal-content">
            <button className="modal-close" data-bs-dismiss="modal" ><i className="icofont-close"><FontAwesomeIcon icon={['fas', 'fa-xmark']}/></i></button>
        
            <Form className="modal-form" >
                <div className="form-title">
                    <h3>Agregar nuevo domicilio de entrega</h3>
                </div>
                <label className="form-label">Nombres: </label>
                <Field name="names" type='text' placeholder='Nombre completo' className="form-control"/>
                <ErrorMessage name="names" component="span"  className="alert alert-danger"/>
                <br/>
               
                <label className="form-label">Telefono:</label>
                <Field name="phone" type='text' placeholder='Telefono' pattern="[0-9]{10}" maxLength="10" className="form-control"/>
                <ErrorMessage name="phone" component="span"  className="alert alert-danger"/>
                <br/>
                <label className="form-label">Domicilio:</label>
                 <Field name="address" component="textarea" rows="3"  placeholder='Domicilio' className="form-control" />   
                 <ErrorMessage name="address" component="span"  className="alert alert-danger"/>
                 <br/>
                    <label className="form-label">Estado:</label>
                    <Field as="select" className="form-select" name='country'>
<option disabled  > Elige uno ...</option>
<option>Aguascalientes</option>
<option>Baja California</option>
<option>Baja California Sur</option>
<option>Campeche</option>
<option>Chiapas</option>
<option>Chihuahua</option>
<option>Coahuila de Zaragoza</option>
<option>Colima</option>
<option>Distrito Federal</option>
<option>Durango</option>
<option>Guanajuato</option>
<option>Guerrero</option>
<option>Hidalgo</option>
<option>Jalisco</option>
<option>México</option>
<option>Michoacán de Ocampo</option>
<option>Morelos</option>
<option>Nayarit</option>
<option>Nuevo León</option>
<option>Oaxaca</option>
<option>Puebla</option>
<option>Querétaro</option>
<option>Quintana Roo</option>
<option>San Luis Potosí</option>
<option>Sinaloa</option>
<option>Sonora</option>
<option>Tabasco</option>
<option>Tamaulipas</option>
<option>Tlaxcala</option>
<option>Veracruz de Ignacio de la Llave</option>
<option>Yucatán</option>
<option>Zacatecas</option>
                      
                    </Field>
                    <ErrorMessage name="country" component="span"  className="alert alert-danger"/>
                    <label className="form-label">Ciudad: </label>
                <Field name="city" type='text' placeholder='Ciudad' className="form-control"/>
                <ErrorMessage name="city" component="span"  className="alert alert-danger"/>
                <br/>
                <div className="form-group">
                    <label className="form-label">Codigo postal: </label>
                <Field name="zipcode"  type="text" pattern="[0-9]{5}" maxLength="5" placeholder='Codigo postal' className="form-control"/>
                <ErrorMessage name="zipcode" component="span" className="alert alert-danger" />

                </div>
                <button className="form-btn" type="submit" > Guardar </button>
                

                </Form>
               
        </div> 
    </div> 
</div>
</Formik>


<Formik 
initialValues={{
    user: currentUser?.user?.user,
    username: currentUser?.user?.username,
    email: currentUser?.user?.email,
    lastname: currentUser?.user?.lastname,
}}
onSubmit={(values) =>{onSubmitEditUser(values,currentUser?.user?.id)} } >
<div className="modal fade"  id="profile-edit"  key="profile-edit" >
    <div className="modal-dialog modal-dialog-centered"> 
        <div className="modal-content">
            <button className="modal-close" data-bs-dismiss="modal" onClick={handleClose}><FontAwesomeIcon icon={['fas', 'fa-xmark']}/><i className="icofont-close"></i></button>
            <Form className="modal-form"  >
                <div className="form-title">
                    <h3>Editar perfil</h3>
                </div>
                <div className="form-group">
                    <label className="form-label">Foto: </label>
                    <input className="form-control" type="file"/>
                </div>
                <div className="form-group">
                    <label className="form-label">Usuario: {currentUser?.user?.user}</label>
                    
                </div>
                <div className="form-group">
                    <label className="form-label">Nombre/s: </label>
                    <Field className="form-control" name='username' key={"form-control" + currentUser?.user?.username} type="text" defaultValue={currentUser?.user?.username}/>
                </div>
                <div className="form-group">
                    <label className="form-label">Apellido/s: </label>
                    <Field className="form-control"  name='lastname' key={"form-control" + currentUser?.user?.lastname} type="text" defaultValue={currentUser?.user?.lastname}/>
                </div>
                <div className="form-group">
                    <label className="form-label">Correo: {currentUser?.user?.email}</label>
                    
                </div>
                <button  className="form-btn" type="submit">Actualizar información</button>
            </Form>
        </div> 
    </div> 
</div>
</Formik>

<>
               {addressMapUser.map(addressUser => ( 
                <div key={"adress"+addressUser.id+addressUser.zipcode}> 
                <Formik  
                initialValues= { {
                    
                    names: addressUser.names,
                    address: addressUser.address,
                    phone: addressUser.phone,
                    city: addressUser.city,
                    country: addressUser.country,
                    zipcode: addressUser.zipcode
                }}
                onSubmit={(values) => {onSubmitEditAddress(values,addressUser.id)}}
                validationSchema={AddressSchema}>
                     
<div className="modal fade"   id={"address-edit"+addressUser.id+addressUser.zipcode} >
    <div className="modal-dialog modal-dialog-centered"> 
        <div className="modal-content"  key="address-edit" >
            <button className="modal-close" data-bs-dismiss="modal" onClick={handleClose}><FontAwesomeIcon icon={['fas', 'fa-xmark']}/><i className="icofont-close"></i></button>
          
            <Form className="modal-form">
          
                <div className="form-title">
                    <h3>Editar Dirección</h3>
                </div>
             
                <div className="form-group">
              

                <label className="form-label">Nombre y apellido de quien recibe</label>
                <Field name="names" type='text' defaultValue={addressUser.names}   className="form-control" />
                <ErrorMessage name="names" component="span"  className="alert alert-danger"/>
                <br/>
                
                <label className="form-label">Telefono</label>
                <Field name="phone" type='text' pattern="[0-9]{10}" maxLength="10" defaultValue={addressUser.phone}   className="form-control" />
                <ErrorMessage name="phone" component="span"  className="alert alert-danger"/>
                <br/>
               
                <label className="form-label">Domicilio</label>
                 <Field name="address" component="textarea" rows="3"   className="form-control" >   
                 {addressUser.address} 
                    </Field>
                 <ErrorMessage name="address" component="span"  className="alert alert-danger"/>
                 <br/>
                    <label className="form-label">Estado</label>
                    <Field as="select" className="form-select" name='country' 
                    
                    >
<option disabled  >{addressUser.country}</option>
<option>Aguascalientes</option>
<option>Baja California</option>
<option>Baja California Sur</option>
<option>Campeche</option>
<option>Chiapas</option>
<option>Chihuahua</option>
<option>Coahuila de Zaragoza</option>
<option>Colima</option>
<option>Distrito Federal</option>
<option>Durango</option>
<option>Guanajuato</option>
<option>Guerrero</option>
<option>Hidalgo</option>
<option>Jalisco</option>
<option>México</option>
<option>Michoacán de Ocampo</option>
<option>Morelos</option>
<option>Nayarit</option>
<option>Nuevo León</option>
<option>Oaxaca</option>
<option>Puebla</option>
<option>Querétaro</option>
<option>Quintana Roo</option>
<option>San Luis Potosí</option>
<option>Sinaloa</option>
<option>Sonora</option>
<option>Tabasco</option>
<option>Tamaulipas</option>
<option>Tlaxcala</option>
<option>Veracruz de Ignacio de la Llave</option>
<option>Yucatán</option>
<option>Zacatecas</option>
                      
                    </Field>
                    <label className="form-label">Ciudad</label>
                <Field name="city" type='text'  defaultValue={addressUser.city}  className="form-control"/>
                <ErrorMessage name="city" component="span"  className="alert alert-danger"/>
                <br/>
                    <label className="form-label">Codigo postal</label>
                <Field name="zipcode" type='text' pattern="[0-9]{5}" maxLength="5"  defaultValue={addressUser.zipcode}  className="form-control"/>
                <ErrorMessage name="zipcode" component="span" className="alert alert-danger" />
                </div> 
                    <div>
                    <br/>
                <button className="form-btn" type="submit"> Guardar domicilio </button> </div> 
            </Form>
        </div> 
    </div> 
   </div>
                    
                        
   </Formik>
   </div>
   ))}
    </>
   </div>
   

)

}