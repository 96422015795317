import React, {useEffect}  from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { addMovieFavorite, addToCart, getSection, getMovieFavorite} from "../../actions/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Masvendidos from "../../components/masvendidos/Masvendidos";
import NuevosP from "../../components/NuevosP/NuevosP";
import Recomendados from "../../components/Recomendados/Recomendados";
import {formatCurrency} from "../../utils/formatCurrency";
import UserService from "../../services/user.service";
import Move from "../../components/Move.js";

export function ConnectedList () {
 const navigate = useNavigate();
    const dispatch = useDispatch();
    
    const movies = useSelector((state) => state.publicActions.moviesFavs);
    const cartItem = useSelector((state) => state.publicActions.items);
    const { user: currentUser } = useSelector((state) => state.auth);
    
    function addCart(movie) {
      if (currentUser) {
        UserService.addCartListUser({product_id:movie.id}).then(() => {
        dispatch(addToCart(movie));
        }) }
      else {
        dispatch(addToCart(movie));
      }  
      
      }
      useEffect (() => { 
        dispatch(getMovieFavorite());
      }
        , []);  
  function addFavorite(movie) {
  if (currentUser) {
    UserService.addWishListUser({product_id:movie.id}).then(() => {
    dispatch(addMovieFavorite(movie));
    }) }
  else {
    dispatch(addMovieFavorite(movie));
  }  
  }
function goSection () {
  
  dispatch(getSection("sectionA", "En Oferta" )).then(() => {
    navigate("/section/En oferta");
  }
  );

} 

    return (
      <div key={cartItem + 'section'}>
        <div className="section-heading-offer"></div>
        <section className="section recent-part">
        <div className="container">
          
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-heading">
                        <h2 >Productos en Oferta: </h2>
                        </div>
                   
                </div>
                </div>
     <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-5"> 
        {
          movies.map(movie => (
          <div  key={movie.SKU + 'product'} >
          <div className="col" key={movie.SKU  + 'col'}>
              <div className="product-card">
                  <div className="product-media">
                      <div className="product-label">
                          <label className="label-text sale">venta</label>
                      </div>
                      <button className="product-wish wish" onClick= {() => (addFavorite(movie))}>
                          
                      </button>
                      <Link  key={movie.SKU} to={`/producto/${movie.SKU}`} className="product-image"> 
                      <img src={process.env.PUBLIC_URL + "/" + movie.image } alt={movie.SKU} />
                      </Link>
                      <div className="product-widget">
                           { /*
                           <a key={movie.SKU + 'ProductCompare'+ movie.SKU} title="Product Compare" href="compare.html" className="fas fa-random"><FontAwesomeIcon  icon={['fas', 'fa-random']}/> </a>
                          <a title="Product Video" key={movie.SKU + 'venobox'+ movie.SKU} href={`/producto/${movie.SKU}`} className="venobox fas fa-play" data-autoplay="true" data-vbtype="video"><FontAwesomeIcon  icon={['fas', 'fa-play']}/></a>
                          <i className="fas fa-heart"><FontAwesomeIcon  icon={['fas', 'fa-heart']}/></i>
          
                          <a title="Product View" key={movie.SKU + 'fa-eye'+ movie.SKU} href={`/producto/${movie.SKU}`} className="fas fa-eye" data-bs-toggle="modal" data-bs-target="#product-view"><FontAwesomeIcon  icon={['fas', 'fa-eye']}/></a> */}
                          <a title="Product Video" key={movie.SKU + 'venobox'+ movie.SKU}  onClick= {() => (addFavorite(movie))} className="venobox fas fa-play" data-autoplay="true" data-vbtype="video"><i className="fas fa-heart"><FontAwesomeIcon  icon={['fas', 'fa-heart']}/></i></a>
                      </div>
                  </div>
                  <div className="product-content">
                      <div className="product-rating">
                         
                          <Link to={`/categories/sub/product/${movie.subcategory}/${movie.brand}`} key={movie.SKU + 'Masvendidosproduct-rating'}>( {movie.brand} )</Link>
                      </div>
                      <h6 className="product-name">
                          <Link to={`/producto/${movie.SKU}`} key={movie.SKU + 'Masvendidosproduct-name'}>{movie.products}</Link>
                      </h6>
                      {movie.inOffer === true ?
                      <h6 className="product-price">
                          <del>{formatCurrency(movie.price)}</del>
                          <span>{formatCurrency(movie.offerPrice)}<small>/pieza</small></span>
                         
                      </h6> : 
                      <h6 className="product-price">
                          <span>{formatCurrency(movie.price)}<small>/pieza</small></span>
                      </h6>
                       }
                      <button className="product-add" title="Add to Cart" onClick= {() => (addCart(movie))}>
                          <i className="fas fa-shopping-basket"><FontAwesomeIcon icon={['fas', 'fa-shopping-basket']}/></i>
                          <span>Agregar</span>
                      </button>
                    
                  </div>
              </div>
          </div>
          </div>

            ))

          }
          
          <div className="product-button">
          <button className="btn btn-outline-secondary " onClick={ ()=> goSection()}><span>Ver mas ...</span><Move x={15} rotation={0} timing={500}> <FontAwesomeIcon icon={['fas', 'fa-angles-right']}/> </Move></button></div>
       
          </div>
        </div>
        </section>
        <Masvendidos/>
        <NuevosP/>
        <Recomendados/>
      </div>
    );
  }






export default ConnectedList;



