import React, { useEffect, useState } from "react";
import {
 PaymentElement,
 useStripe,
 useElements
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { setCheckoutStep} from "../../actions/index";
import UserService from "../../services/user.service";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { clearCart} from "../../actions/index";
import authService from "../../services/auth.service";


export default function CheckoutForm() {
  
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const stripe = useStripe();
 const elements = useElements();
 const [message, setMessage] = useState(null);
 const [isLoading, setIsLoading] = useState(false);
 const  step  = useSelector((state) => state.publicActions.purcharse);
 const [currentUser, setCurrentUser] = useState(undefined);
const API = process.env.REACT_APP_URL;
  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  }, []);



 //// solicta el pago con stripe al servidor  ////// 
 useEffect(() => {
   if (!stripe) {
     return;
   }
   const clientSecret = localStorage.getItem("clientSecret");
    if (clientSecret) {
      setIsLoading(true);
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(PaymentElement),
          },
        })
        .then((result) => {
          if (result.error) {
            setMessage(result.error.message);
          } else {
            setMessage(result.paymentIntent.status);
          }
          setIsLoading(false);
        }

   );
  }
   if (!clientSecret) {
     return;
   }
  
   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      
     switch (paymentIntent.status) {
       case "succeeded":
         setMessage("Pago realizado con éxito");
         
         break;
       case "processing":
         setMessage("Tu pago esta en proceso.");
         break;
       case "requires_payment_method":
         setMessage("Tu pago fue rechazado, Porfavor, intenta nuevamente.");
         break;
       default:
         setMessage("Algo salio mal.");
         break;
     }

   });
 }, [stripe]);

 
 function setStepPurchase(e) {
  dispatch(setCheckoutStep(e));

}
//////// fin del pago con stripe ////////

/////// limpia el carro si el pago fue efectuado con exito/// 
function clear() {
  if(currentUser){  /// lipiar si el usuario esta logueado
  fetch (API + "/cart/clear/" + currentUser.user.id , {
    method: "DELETE",
    headers: {
      "Content-Type": "application/json",
    },
    body: JSON.stringify(),
  })
    .then((res) => res.json())
  dispatch(clearCart());
}
else {  /// limpiar si el usuario no esta logueado
  dispatch(clearCart());}
}

 /////// fin de limpiar el carro //////


////// handle para verificar el pago y guardarlo en la DB////////
 const handleSubmit = async (e) => {
   e.preventDefault();
   if (!stripe || !elements) {
     return;
   }
   setIsLoading(true);
  
   const { error } = await stripe.confirmPayment({
     elements,
     redirect:  "if_required",
     confirmParams: {
      return_url: API,
    },
  /// si el pago fue exitoso guarda la orden en la base de datos si el usuario esta logueado guarda el id del usuario y el id del pago /// 
    }).then((result) => { 
      if (result.paymentIntent) { 
        
         if (currentUser && currentUser.user.id) {
          UserService.OrderUser([...step, {idPi: elements._commonOptions.clientSecret.id,  }]);
          setMessage("Pago realizado con éxito");
          setIsLoading(true);
          clear();
           setTimeout(() => {
            
            setIsLoading(false);
            
        navigate("/order/"+elements._commonOptions.clientSecret.id);
        window.location.reload();
    
            }, 1000);
         }
          else {   /// si el usuario no esta logueado guarda el id del pago en la base de datos ///
        UserService.Order([...step, {idPi: elements._commonOptions.clientSecret.id,  }]);
        setMessage("Pago realizado con éxito");
        setIsLoading(true);
        clear();
         setTimeout(() => {
          
          setIsLoading(false);
          
      navigate("/order/"+elements._commonOptions.clientSecret.id);
      window.location.reload();
  
          }, 1000);
      
        }  
      if (result.paymentIntent.status === "requires_action") {   /// si el pago requiere de una accion adicional  guarda la orden ///
        setMessage("Tu pago esta en proceso, estamos preparando tu orden, espera por favor");
        setIsLoading(true);
        UserService.Order([...step, {idPi: elements._commonOptions.clientSecret.id,  }]); 
        clear();
        setTimeout(() => {  
          
          setIsLoading(false);
         navigate("/order/"+elements._commonOptions.clientSecret.id);
         window.location.reload();
    
        }, 1000);
      }
    }  
      if (result.error)  /// si el pago fue rechazado muestra el error al usaurio  ///
      {
        setMessage(result.error.message); 
        setIsLoading(false);
      } 
      else {
        setMessage(result.error.message);
        setIsLoading(false);
      }
    });
  };



 return (

   <form id="payment-form" onSubmit={handleSubmit}> 
     <PaymentElement id="payment-element" />
     
     <button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-inline checkout-proced">
     <span id="button-text">
         {isLoading ? <div className="spinner "  id="spinner">Cargando</div> : "Pagar Ahora"}
       </span>
     </button>
     <br/> 
     {message && <div id="payment-message">{message}</div>}   {isLoading ?  <span>estamos trabajando para ti...  <br/> <LoadingSpinner/></span> : null}
   </form>
 );
}