import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { Provider } from "react-redux";
import store from "./store/index";
import Reg from "./components/Login/Reg";
import Login from "./components/Login/Login";
import ResetForgot from "./components/User/Forgot";
import Reset from "./components/User/Reset";
import Survey from './components/Survey/Survey';
import PageSurvey from './components/Survey/PageSurvey';
import setupInterceptors from "./services/setupInterceptors";



require('bootstrap/dist/js/bootstrap.js');



const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
 
  <Provider store={store}>
       
    <BrowserRouter>
    
    <Routes>  
 
      <Route path="*" element={<App />} />
      <Route path="/login" element={<Login />} /> 
       <Route path="/reg" element={<Reg />} />
       <Route path="/forgot" element={<ResetForgot />} />
       <Route path="/reset/:token" element={<Reset />} />
        <Route path="/survey/:id" element={<Survey />} />
        <Route path="/pagesurvey/:id" element={<PageSurvey />} />

      </Routes>
 
    </BrowserRouter>
     
    </Provider>
 
);

setupInterceptors(store);