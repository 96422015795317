import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import UserService from "../../services/user.service";
import SweetPagination from "sweetpagination";
import { NavLink, Link } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
export default function QuestionRequest () {
    const [numberOfPages, setnumberOfPages] = useState(3);
    const { user: currentUser } = useSelector((state) => state.auth);  
    const [itemQuestion, setItemQuestion] = useState([]);
    const [Questions , setQuestions] = useState([]);
    const [itemQuestionCount, setItemQuestionCount] = useState(0);
    const [page, setPage] = useState(3);
    const [pageAnswer, setPageAnswer] = useState(2);
    const [HasMore, setHasMore] = useState(true);
    const [currentPageData, setCurrentPageData] = useState(itemQuestion?.question?.slice(0,numberOfPages));
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const API = process.env.REACT_APP_URL;
    useEffect (() => { 
        let mounted = true;     
        UserService.getRequestedQuestions().then(
            (response) => { 
                setItemQuestion(response.data);
                setItemQuestionCount(response.data.count);
                setHasMore( response.data.questions.length < response.data.count);     
            }); 

       
        return () => mounted = false;
    }, [ itemQuestionCount]);
   
    useEffect (() => {
        fetch(API + '/categories/')
        .then(res => res.json())
        .then(data => {
            setCategory(data);
        }).catch();
        fetch(API + '/categories/sub/')
        .then(res => res.json())
        .then(data => {
            setSubcategory(data);
        }
        ).catch();
    } , []);

return (

    <section className="inner-section blog-standard">
        <br/>
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-lg-8">
           
                <div className="row">
                    <div className="col-lg-12">
                    {itemQuestionCount > 12 &&
                        <div className="top-filter">
                            <div className="filter-show">
                                <label className="filter-label">Ver :</label>
                                <select className="form-select filter-select" defaultValue={numberOfPages} onChange={ (e)=>  setnumberOfPages(e.target.value)}>
                                    <option value={3} >{3}</option>
                                    <option value={6} >{6}</option>
                                    <option value={12}>{12}</option>
                                </select>
                            </div>
                            <div className="filter-short">
                                <label className="filter-label">Ordenar por :</label>
                                <select className="form-select filter-select">
                                    <option selected>Recientes</option>
                                    
                                    <option defaultValue="1">Producto</option>
                                    <option defaultValue="2">Recomendado</option>
                                </select>
                            </div>
                            <div className="filter-action">
                                <a href="blog-grid.html" title="Two Column"><i className="fas fa-th-large"></i></a>
                                <a href="blog-standard.html" className="active" title="One Column"><i className="fas fa-th-list"></i></a>
                            </div>
                        </div>
}
                    </div>
                   
                  
                        
                        {currentPageData  && currentPageData?.map((item, index) => (
  <div className="col-lg-12">
                        <div className="blog-card" key={index}>
                            <div className="blog-media">
                                <NavLink to={`/producto/${item.product.SKU}`} title="Producto">
                            <h4 className="blog-title">
                                 {item.product.products}  </h4>
                                <div className="blog-img">
                                    <img src={process.env.PUBLIC_URL +"/" + item.product.image} alt="Producto"/>
                                    </div>    
                                </NavLink>
                                
                            </div>
                            <div className="blog-content">
                                <ul className="blog-meta">
                                    <li>
                                        <i className="icofont-ui-calendar"></i>
                                        <span>{new Date(item.createdAt).toLocaleString()}</span>
                                    </li>
                                    <li>
                                        <i className="icofont-user-alt-3"></i>
                                        <span>{item.Users.user}</span>
                                    </li>
                                    <li>
                                        <i className="icofont-speech-comments"></i>
                                        
                                        <span> {item.answers.length} Respuestas</span>
                                    </li>
                                </ul>
                              
                                   <h6 className="blog-title">  <NavLink to={`/producto/${item.product.SKU}/#question`} title="Producto"><strong>Pregunta: </strong>{item.question}</NavLink> </h6>
                                
                               
                                {item.answers.map((answer, index) => ( 
                                <p className="blog-desc"  key={index}><strong>Respuesta: </strong>{answer.answer}</p>
                                
                                ))} 
                               

                               <NavLink to={`/producto/${item.product.SKU}`} title="Producto">
                               <FontAwesomeIcon icon={['fas', 'fa-arrow-right']}/>
                                    <span>Ir al producto</span>
                                </NavLink>
                            </div>
                        </div>
                        </div> 
                        ))}
                      
                     
                      
                  
                    <div className="col-lg-12">
                        <div className="bottom-paginate">
                            <p className="page-info">Mostrando {numberOfPages} de {itemQuestionCount} Resultados</p>
                           
                                    {itemQuestion && 
                                     <SweetPagination 
                                        
                                         currentPageData={setCurrentPageData}
                                         dataPerPage={numberOfPages}
                                         getData={itemQuestion?.questions || itemQuestion} 
                                         navigation={true}/>
                                    }
        
       
                    
                        </div>
                    </div>

                </div>
   
            </div>


            <div className="col-sm-10 col-md-7 col-lg-4">
                
                <div className="blog-widget">
                    <h3 className="blog-widget-title">Productos Populares</h3>
                    <ul className="blog-widget-feed">
                        <li>
                            <a className="blog-widget-media" href="#">
                                <img src="images/blog-widget/01.jpg" alt="blog-widget"/>
                            </a>
                            <h6 className="blog-widget-text">
                                <a href="#">Lorem ipsum dolor sit amet consectetur</a>
                                <span>Octubre 02, 2022</span>
                            </h6>
                        </li>
                        <li>
                            <a className="blog-widget-media" href="#">
                                <img src="images/blog-widget/02.jpg" alt="blog-widget"/>
                            </a>
                            <h6 className="blog-widget-text">
                                <a href="#">Lorem ipsum dolor sit amet consectetur</a>
                                <span>Octubre 02, 2022</span>
                            </h6>
                        </li>
                        <li>
                            <a className="blog-widget-media" href="#">
                                <img src="images/blog-widget/03.jpg" alt="blog-widget"/>
                            </a>
                            <h6 className="blog-widget-text">
                                <a href="#">Lorem ipsum dolor sit amet consectetur</a>
                                <span>Octubre 02, 2022</span>
                            </h6>
                        </li>
                    </ul>
                </div>
                <div className="blog-widget">
                    <h3 className="blog-widget-title">Mas productos</h3>
                    <ul className="blog-widget-category">
                    {category.map((categories, index) => (
                    
                        <li  key={categories.name + index}><NavLink to={`/categories/sub/product/${categories.name}`} >{categories.name} <span>{categories.id}</span></NavLink></li>
                        ))}
                    </ul>
                  
                </div>
                <div className="blog-widget">
                    <h3 className="blog-widget-title">Refacciones populares</h3>
                    <ul className="blog-widget-tag">
                    {subcategory.map((categories, index) => (
                        <li key={categories.name + index}><NavLink to={`/categories/sub/product/${categories.name}`} >{categories.name}</NavLink></li>
                   
                    ))}
                    </ul>
                </div>
                
                <div className="blog-widget">
                    <a href="#"><img className="img-fluid" src="images/promo/blog/01.jpg" alt="promo"/></a>
                </div>
            </div>
        </div>
    </div>
</section>
)

}