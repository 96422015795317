import React, { useContext, useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import '../../css/user-auth.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../utils/AuthContext";
import { Reset } from "../../actions/auth";
import logo from  "../../img/isotipo.svg";
import AuthService from "../../services/auth.service";
/* import FacebookLogin from '@greatsumini/react-facebook-login';*/

export function ChangePass() {
 
  const [loading, setLoading] = useState(false);
    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const [currentUser, setCurrentUser] = useState("");
    const dispatch = useDispatch();
    const navigate = useNavigate();

    useEffect(() => {
        let componentMounted = true;
        const user = AuthService.getCurrentUser();
        if (user) {
          setCurrentUser(user);
        }
    
        return () => {
          componentMounted = false;
        }
      }, []);
 
    const initialValues = {
        email: "",
        password: "",
        newPassword: "",
        id: "",
       
     
      };
      
     
    const loginState = (data) => {
        setLoading(true);
       
      dispatch(Reset({...data, email: currentUser.user.email } ))
        .then(res => {
            setLoading(false);
        
        }).catch(err => {
            setLoading(false);
          
        }
        );
    }
    
     /* const responseFacebook = (response) => {
        console.log(response);
        dispatch(login(response.email, response.password))
        .then(res => {
            setLoading(false);
            navigate("/");
            window.location.reload();
        }).catch(err => {
            setLoading(false);
        })} */
        

    return (
<div>

    <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
            loginState(values)}}
        
        validationSchema={Yup.object().shape({
           
            password: Yup.string().min(8, "minimo 8 caracteres").max(20, "maximo 15 caracteres").required("campo requerido"),
            newPassword: Yup.string().min(8, "minimo 8 caracteres").max(20, "maximo 15 caracteres").required("campo requerido"),
            
            passwordvalidation: Yup.string().oneOf([Yup.ref('newPassword'), null], 'Tu password debe contraseña').required("campo requerido"),
            
        })}>

<section className="user-form-part">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-12 col-lg-12 col-xl-10">
                        <div className="user-form-logo">
                            <a href="/"><img src={logo} alt="logo"/></a>
                            <h3>Maceronu</h3>
                        </div>
                        <div className="user-form-card">
                            <div className="user-form-title">
                                <h2>¿Deseas restablecer tu contraseña?</h2>
                                <p>Descuida!, sólo coloca tu contraseña anterior y escribe una nueva</p>
                            </div>
                           
                         
                                 <Form className="user-form">
                               
                                    <div className="form-group">
                                    <Field name="password" type="text" className="form-control" placeholder="Ingresa tu contraseña anterior"/>
                                       <ErrorMessage name="password" component="div" />
                                    </div>
                                    <div className="form-group">
                                    <Field name="newPassword" type="text" className="form-control" placeholder="Ingresa tu nueva contraseña"/>
                                    <ErrorMessage name="NewPassword" component="div" />
                                    </div>
                                    <div className="form-group">
                                    <Field name="passwordvalidation" type="text" className="form-control" placeholder="Repite tu nueva contraseña"/>
                                       <ErrorMessage name="passwordvalidation" component="div" />
                                    </div>
                                  
                                   
                                    <div className="form-check mb-3">
                                    {message ?  
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                <span>{message} </span>
              </div> 
            </div>
            : null}

                                    </div>
                                    <div className="form-button">
                                        <button type="submit">Cambiar contraseña</button>
                                        {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
                                       
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="user-form-remind">
                            <p>¿No receurdas tu contraseña?<a href="/forgot">Recuperala aquí</a></p>
                        </div>
                        </div>
                        </div>
                  
        
        </section>


    
       
    </Formik>
</div>
    );
}

/* <FacebookLogin 
                                        appId="6105692556114248"
                                    onSuccess={(response) => {
                                        console.log('Login Success!', response);
                                      }}
                                      onFail={(error) => {
                                        console.log('Login Failed!', error);
                                      }}
                                      onProfileSuccess={(response) => {
                                        console.log('Get Profile Success!', response);
                                      }}> </FacebookLogin> 
                                      */
 export default ChangePass;
