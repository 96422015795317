
import  { SET_MESSAGE,
} from "./type";

const API_URL = process.env.REACT_APP_URL;
export function addMovieFavorite(payload) {
  return { type: "ADD_MOVIE_FAVORITE", payload };
}

export function removeMovieFavorite(payload) {
  return { type: "REMOVE_MOVIE_FAVORITE", payload };
}

export function getSearch(titulo, error) {
  return function(dispatch) {
    return fetch(API_URL+"/items/search/" + titulo)
    .catch(error => {
      dispatch({
        type: "GET_SEARCH",
        payload: error
      });})
      .then(response => response.json()
    )
      .then(json => {
        dispatch({ type: "GET_SEARCH", payload: json }); 
      })
      
     
  };
}

export function getRecommendProducts(titulo, error)  {
  return function (dispatch) {
    return fetch(API_URL+"/items/reco/" + titulo)
    .catch(error => {
      dispatch({
        type: "GET_RECO",
        payload: error
      });})
      .then(response => response.json()
    )
      .then(json => {
        dispatch({ type: "GET_RECO", payload: json }); 
      })
      
    };
  } 

  

  
  
  




export function getSection(a,b) {

  return function (dispatch) {
    return fetch(API_URL+`/items/section/all/?${a}=${b}`)
      .then(response => response.json())
      .then(json => {
        dispatch({ type: "GET_SECTION", payload: json });
      }
      );
  }
}

export function getMovieDetail(payload) {
  return function (dispatch) { 
    return fetch(API_URL+"/items/" + payload)
      .then(response => response.json())
      .then(json => {
        dispatch({ type: "GET_MOVIE_DETAIL", payload: json });
      
      });

}
}

export function getMovieFavorite(payload) {
  return function(dispatch) {
    return fetch(API_URL+"/items/section/a")
      .then(response => response.json())
      .then(json => {
        dispatch({ type: "GET_MOVIE_FAVORITE", payload: json });
       
        
      });
  };
}

export function Masvendidos(payload) {
  return function(dispatch) {
    return fetch(API_URL+"/items/section/b")
      .then(response => response.json())
      .then(json => {
        dispatch({ type: "GET_MAS_VENDIDOS", payload: json });
       
        
      });
  };
}

export function NuevosP(payload) {
  return function(dispatch) {
    return fetch(API_URL+"/items/section/c")
      .then(response => response.json())
      .then(json => {
        dispatch({ type: "GET_NUEVOS_P", payload: json });
       
        
      });
  };
}

export function Recomendados(payload) {
  return function(dispatch) {
    return fetch(API_URL+"/items/section/d")
      .then(response => response.json())
      .then(json => {
        dispatch({ type: "GET_RECOMENDADOS", payload: json });
       
        
      });
  };
}

export const addToCart = (cartItem) => {
  return { 
    type: "ADD_TO_CART", payload: {
    cartItem: cartItem}
   }
   ;
 
};

export const Plus = (cartItem) => {
  return { type: "PLUS", payload: {
    cartItem: cartItem} };
 
};

export const Minus = (cartItem) => {
  return { type: "MINUS", payload: {
    cartItem: cartItem} };
 
};


export const removeFromCart = (payload) => {
  return{
    type: "REMOVE_FROM_CART",
    payload
    
  
  };
};

export const clearCart = (dispatch) => {
  return  function (dispatch) {
  dispatch({
    type: "CLEAR_CART"
  });
};
};


export const setCheckoutStep = (payload) => {
  return {
    type: "SET_CHECKOUT_STEP",
    payload
  };
};

export const saveShippingAddress = (payload, shippingAddress) => {
  return function (dispatch) { 
  dispatch({
    type: "SET_SHIPPING_ADDRESS",
    payload: {
      shippingAddress
    }
    
  });
  return setCheckoutStep(dispatch, CHECKOUT_STEPS.PAYMENT);
}
}
export const CHECKOUT_STEPS = {
  AUTH: "auth",
  SHIPPING: "shipping",
  PAYMENT: "payment"
};

export const Purcharse = (payload) => {
  return function(dispatch) {
    return fetch(API_URL+"/purcharse" + payload)
      .then(response => response.json())
      .then(json => {
        dispatch({ type: "GET_RECOMENDADOS", payload: json });
       
        
      });
  };
}

export const getCategory = (id) => {
  return function (dispatch) {
    return   fetch(API_URL+'/categories/sub/product/'+id)
    .then(response => response.json())
    .then(json => {
      dispatch({ type: "GET_CATEGORY", payload: json });
     
    });
  };

}

