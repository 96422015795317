import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from 'react-responsive-carousel';
import '../../css/about.css';


export default function About() {
    return (  

<div key='Aboutkey'> 
    <section className="inner-section single-banner" >
            <div className="container">
                <h2>Acerca de maceronu</h2>
                <ol className="breadcrumb">
                    <li className="breadcrumb-item"><a href="https://www.maceronu.com">Maceronu</a></li>
                    <li className="breadcrumb-item active" aria-current="page">Acerca de...</li>
                </ol>
            </div>
        </section>

        <section className="inner-section about-company">
            <div className="container">
                <div className="row align-items-center">
                    <div className="col-lg-6">
                        <div className="about-content">
                            <h2>Nuestra mision es darte el mejor servicio</h2>
                            <p>Somos una empresa mexicana dedicada a la venta de refacciones y accesorios para celulares con la mejor calidad. 
                               Los valores fundamentales y la misión son el ADN de nuestro éxito en el pasado y del futuro que creemos.
                                Actualmente somos líderes en ventas dentro de Mercado Libre México. 
                                Contamos con envios a toda la republica mexicana. </p>
                                <p> En Maceronu nuestro objetivo es proveer productos de la mejor calidad en el mercado y ofrecer 
                                un servicio transparente, honesto y eficaz. Nos esforzamos por ofrecer a nuestra comunidad de 
                                técnicos la conveniencia de tratar con una sola fuente.</p>
                        </div>

                        <div className="about-content">
                            <h2>Nuestra visión</h2>
                            <p>Ser siempre la primera opción para los talleres de reparación de celulares, ofreciendo siempre 
                            las refacciones y aceesorios para celular más actuales del mercado con la mejor calidad, abarcando 
                            así un mercado más amplio a nivel nacional.
                            </p>
                        </div>

                        <div className="about-content">
                            <h2>Nuestros valores</h2>
                            <p>Eficiencia 
                               Honestidad 
                               Responsabilidad 
                               Trabajo duro </p>
                        </div>


                        <ul className="about-list">
                            <li>
                                <h3>4785</h3>
                                <h6>Usuarios que nos han comprado</h6>
                            </li>
                            <li>
                                <h3>2623</h3>
                                <h6>Usuarios que nos visitan</h6>
                            </li>
                            <li>
                                <h3>64785</h3>
                                <h6>Refacciones vendidas</h6>
                            </li>
                        </ul>
                    </div>
                    <div className="col-lg-6">
                        <div className="about-img">
                            <img src="images/about/01.jpg" alt="about"/>
                            <img src="images/about/02.jpg" alt="about"/>
                            <img src="images/about/03.jpg" alt="about"/>
                            <img src="images/about/04.jpg" alt="about"/>
                        </div>
                    </div>
                </div>
            </div>
        </section>

        <section className="inner-section about-testimonial">
            <div className="container">
                <ul className="testi-slider slider-arrow">
                    <li>
                        <div className="testi-content">
                            <a className="testi-img" href="#">
                                <img src="images/testimonial/01.jpg" alt="testimonial"/>
                            </a>
                            <div className="testi-quote">
                                <i className="icofont-quote-left"></i>
                                <p>Desde que comencé hace 6 años a incursionar en el área de reparaciones, tuve oportunidad de conocer a varios proveedores de refacciones y herramientas para teléfono, sin embargo, cuando conocí  a MACERONU definitivamente los elegi por sobre otros como mi único proveedor, en MACERONU tienen todo lo que necesito en materia de reparaciones, además de contar con los mejores precios del mercado, el local cuenta con un espacio para que pruebes tú mismo las refacciones que compras. 
Y si por prisa no tienes oportunidad de probar la refacción, quédate tranquilo, pues cuentan con un excelente modelo de cambios y garantías de piezas. Sin duda, la atención al cliente personalizada tanto en línea como en la tienda física es incomparable, aunque vayas casi al cierre de la tienda siempre tienen la mejor actitud para atenderte, no solo estoy a gusto con MACERONU estoy agradecido, los recomiendo ampliamente. Constantemente innovan y eso se agradece.</p>
                                <h4>Erick</h4>
                                <h6>Cliente satisfecho</h6>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div className="testi-content">
                            <a className="testi-img" href="#">
                                <img src="images/testimonial/02.jpg"  alt="testimonial"/>
                            </a>
                            <div className="testi-quote">
                                <i className="icofont-quote-left"></i>
                                <p>Me ha tocado conocer a muchos proveedores de refacciones, pero en mi experiencia les comparto que con MACERONU fue lo mejor en todo sentido por que hacen todo lo posible por tener todo lo que necesitas, nunca dejan de pelear por estar en los mejores precios y la mejor calidad, también ofrecen unas instalaciones en las que te sientes como en casa por que cuentan con espacios adecuados para probar tus refacciones equipados con cargadores, herramientas y tapetes. Por último, su atención al cliente siempre es buena y eficiente tanto en sus grupos como en persona.</p>
                                <h4>Charly</h4>
                                <h6>Cliente satisfecho</h6>
                            </div>
                        </div>
                    </li>
                </ul>
            </div>
        </section>


        <section className="about-choose">
            <div className="container">
                <div className="row">
                    <div className="col-11 col-md-9 col-lg-7 col-xl-6 mx-auto">
                        <div className="section-heading">
                            <h2>Por que las personas compran en Maceronu</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-6">
                        <div className="choose-card">
                            <div className="choose-icon">
                            <i class="fas fa-sync-alt"><FontAwesomeIcon  icon={['fas', 'fa-sync-alt']}/></i>
                            </div>
                            <div className="choose-text">
                                <h4>Productos economicos 100% garantizados </h4>
                                <p> Contamos con la clasificación de calidad y los estándares de control de calidad más 
                                    claros y transparentes. Al mismo tiempo, cooperamos con muchas refaccionarias diferentes.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="choose-card">
                            <div className="choose-icon">
                            <i class="fas fa-truck"><FontAwesomeIcon  icon={['fas', 'fa-truck']}/></i>
                            </div>
                            <div className="choose-text">
                                <h4>Envios en menos de 24 horas</h4>
                                <p>Todas tus compras son enviadas el mismo día de la compra 
                                    antes de las 15:00 hrs. hora México. Trabajamos con las mejores empresas
                                    mundiales en envíos.
                                </p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="choose-card">
                            <div className="choose-icon">
                            <i class="fas fa-headset"><FontAwesomeIcon  icon={['fas', 'fa-headset']}/></i>
                            </div>
                            <div className="choose-text">
                                <h4>Garantia: </h4>
                                <p>Contamos con 7 días de garantia, dentro de las condiciones de las politicas de garantia.</p>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="choose-card">
                            <div className="choose-icon">
                            <i class="fas fa-lock"><FontAwesomeIcon  icon={['fas', 'fa-lock']}/></i>
                            </div>
                            <div className="choose-text">
                                <h4>Soporte las 24 horas del dia </h4>
                                <p>Para abordar las necesidades de nuestros clientes, Maceronu estableció un equipo único 
                                de expertos para brindar el mejor servicio. Contamos con atención telefónica, 
                                por correo y redes sociales.</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>


 <section className="inner-section about-team">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="section-heading">
                            <h2>Nuestros integrantes</h2>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12">
                    <ul className="team-slider slider-arrow">
                        <Carousel>
                       
                            <li>
                                <figure className="team-media">
                                    <img src="images/team/01.jpg" alt="team"/>
                                    <div className="team-overlay">
                                    <ul className="details-share-list">
                                            <li><a to="https://www.facebook.com/cec.nunez" className="icofont-facebook" title="Facebook"><FontAwesomeIcon icon={['fab', 'fa-facebook']}/></a></li>
                                          
                                        </ul>
                                    </div>
                                </figure>
                                <div className="team-meta">
                                    <h5><a href="#">Cecilia Romero</a></h5>
                                    <p>Propietaria</p>
                                </div>
                            </li>
                            <li>
                                <figure className="team-media">
                                    <img src="images/team/02.jpg" alt="team"/>
                                    <div className="team-overlay">
                                    <ul className="details-share-list">
                                            <li><a to="https://www.facebook.com/profile.php?id=100074825924675" className="icofont-facebook" title="Facebook"><FontAwesomeIcon icon={['fab', 'fa-facebook']}/></a></li>

                                        </ul>
                                    </div>
                                </figure>
                                <div className="team-meta">
                                    <h5><a href="#">Daniel Martínez</a></h5>
                                    <p>Redes Sociales</p>
                                </div>
                            </li>
                            <li>
                                <figure className="team-media">
                                    <img src="images/team/03.jpg" alt="team"/>
                                    <div className="team-overlay">
                                    <ul className="details-share-list">
                                            <li><a to="#" className="icofont-facebook" title="Facebook"><FontAwesomeIcon icon={['fab', 'fa-facebook']}/></a></li>
                                            <li><a to="#" className="icofont-twitter" title="Twitter"><FontAwesomeIcon icon={['fab', 'fa-twitter']}/></a></li>
                                            <li><a to="#" className="icofont-linkedin" title="Linkedin"><FontAwesomeIcon icon={['fab', 'fa-linkedin']}/></a></li>
                                            <li><a to="#" className="icofont-instagram" title="Instagram"><FontAwesomeIcon icon={['fab', 'fa-instagram']}/></a></li>
                                        </ul>
                                    </div>
                                </figure>
                                <div className="team-meta">
                                    <h5><a href="#">Karla Torres</a></h5>
                                    <p>Redes Sociales</p>
                                </div>
                            </li>
                            <li>
                                <figure className="team-media">
                                    <img src="images/team/04.jpg" alt="team"/>
                                    <div className="team-overlay">
                                    <ul className="details-share-list">
                                            <li><a to="#" className="icofont-facebook" title="Facebook"><FontAwesomeIcon icon={['fab', 'fa-facebook']}/></a></li>
                                            <li><a to="#" className="icofont-twitter" title="Twitter"><FontAwesomeIcon icon={['fab', 'fa-twitter']}/></a></li>
                                            <li><a to="#" className="icofont-linkedin" title="Linkedin"><FontAwesomeIcon icon={['fab', 'fa-linkedin']}/></a></li>
                                            <li><a to="#" className="icofont-instagram" title="Instagram"><FontAwesomeIcon icon={['fab', 'fa-instagram']}/></a></li>
                                        </ul>
                                    </div>
                                </figure>
                                <div className="team-meta">
                                    <h5><a href="#">Bella Tovar</a></h5>
                                    <p> Soporte Comercial</p>
                                </div>
                            </li>
                            <li>
                                <figure className="team-media">
                                    <img src="images/team/05.jpg" alt="team"/>
                                    <div className="team-overlay">
                                    <ul className="details-share-list">
                                            <li><a to="#" className="icofont-facebook" title="Facebook"><FontAwesomeIcon icon={['fab', 'fa-facebook']}/></a></li>
                                            <li><a to="#" className="icofont-twitter" title="Twitter"><FontAwesomeIcon icon={['fab', 'fa-twitter']}/></a></li>
                                            <li><a to="#" className="icofont-linkedin" title="Linkedin"><FontAwesomeIcon icon={['fab', 'fa-linkedin']}/></a></li>
                                            <li><a to="#" className="icofont-instagram" title="Instagram"><FontAwesomeIcon icon={['fab', 'fa-instagram']}/></a></li>
                                        </ul>
                                    </div>
                                </figure>
                                <div className="team-meta">
                                    <h5><a href="#">Alexa Vilches</a></h5>
                                    <p>Soporte Comercial</p>
                                </div>
                            </li>
                       
                        </Carousel>
                        </ul>
                    </div>
                </div>
            </div>
        </section>
        </div>
)
}