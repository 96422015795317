import React from 'react';
import { useState } from 'react';
import { useParams } from 'react-router-dom';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./Survey.scss";
import { Formik, Form, Field } from "formik";
import * as Yup from "yup";

   

export default function Survey() {

    const [survey, setSurvey] = useState([]);
    const { id } = useParams();

    const validationSchema = Yup.object().shape({
      rating : Yup.string().required("Este campo es requerido"),
      exp : Yup.string().required("Este campo es requerido"),
     
      comment: Yup.string().required("Este campo es requerido"),
      recommend : Yup.string().required("Este campo es requerido"),
    });
    const API = process.env.REACT_APP_URL;
    const onSubmit = (data) => {
      fetch (API + '/suggestion/send' , {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({...data, order_id: id, type: 'survey', }),
      })
      .then(response => response.json())
      .then(data => {
        setSurvey('success');
      }
      )
    };


    return (
        <div className='conten'>
          <div className="user-form-logo">             
           <a href="https://www.maceronu.com"><img src={process.env.PUBLIC_URL + "/images/logotipo2.svg"} alt="logo"/></a>
            </div>
            <div className="mx-0 mx-sm-auto">
  <div className="card">
  {survey === 'success' ? ( 
    <div className="card-body">
      <div className="text-center">
        <FontAwesomeIcon icon="check-circle" className="far fa-check-circle fa-4x mb-3 text-success"/>
        <p>
          <strong>¡Gracias por tomarte el tiempo de escribirnos! Leeremos tus comentarios con entusiasmo.</strong>
        </p>
      </div>
    </div>
  ) : (
    <div className="card-body">
    <div className="text-center">
    <div className="card-header bg-gradient-success bg-secondary">
      <h5 className="card-title text-white mt-2" id="exampleModalLabel">Nos gustaría saber las sugerencias que tienes por compartirnos</h5>
    </div>
    
    
    <div className="card-body">
      <div className="text-center">  
      
        <FontAwesomeIcon icon="file-alt" className="far fa-file-alt fa-4x mb-3 text-secondary"/>
        <p>
          <strong>Tu opinión nos importa</strong>
        </p>
        
      </div>

      <hr />

      <Formik
  initialValues={{
    rating: '',
    comment: '',
    recommend: '',
    exp: '',
  }}
  validationSchema={validationSchema}
  onSubmit={onSubmit}
>
  {({ values, errors, touched, setFieldValue }) => (
    <Form action="">
      <p className="text-center">
        <strong>La navegación en la página ¿Te parece agradabel y sencilla?</strong>
      </p>

      <div className="text-center mb-3">
        <div className="d-inline mx-3">Mala</div>

        {[1, 2, 3, 4, 5, 6].map((num) => (
          <div className="form-check form-check-inline " key={num}>
            <Field
              className="form-check-input"
              name="exp"
              type="radio"
              id={`inlineRadio${num}`}
              value={`${num}`}
            />
            <label
              className="form-check-label"
              htmlFor={`inlineRadio${num}`}
            >
              {num}
            </label>
          </div>
        ))}

        <div className="d-inline me-4">Excelente</div>

        {errors.exp && touched.exp && (
          <div className="alert alert-danger text-center">{errors.exp}</div>
        )}
      </div>

      <hr />

      <p className="text-center">
        <strong>¿Qué calificación le das al diseño dle comercio?</strong>
      </p>

      <div className="row">
                                    <div className="col-lg-12">
                                   
                                        <div className="star-rating">
                                        
                                            <Field name="rating" type="radio" id="star5" value="5" />
                                            <label htmlFor="star5" title="5 stars">
                                                 
                                            </label>
                                            <Field name="rating" type="radio" id="star4" value="4" />
                                            <label htmlFor="star4" title="4 stars">
                                                
                                            </label>
                                            <Field name="rating" type="radio" id="star3" value="3" />
                                            <label htmlFor="star3" title="3 stars">
                                                
                                            </label>
                                            <Field name="rating" type="radio" id="star2" value="2" />
                                            <label htmlFor="star2" title="2 stars">
                                                
                                            </label>
                                            <Field name="rating" type="radio" id="star1" value="1" />
                                            <label htmlFor="star1" title="1 star">    
                                            </label>
                                            <span> : Calificacion </span>
                                             
                                        </div>
                                    </div>
                                    {errors.rating && touched.rating && ( 
                                        <div className="alert alert-danger text-center">{errors.rating}</div>
                                    )}
                                </div>

      <hr />
      <div className="text-center">
        <p>
          <strong>
            ¿Cuál es la probabilidad de recomendar comprar en MACERONU a algún
            familiar o amigo?
          </strong>
        </p>
      </div>

      <div
        className="btn-toolbar justify-content-center"
        role="toolbar"
        aria-label="Toolbar with button groups"
      >
        {[0, 1, 2, 3, 4, 5,6,7,8,9,10].map((num) => (
          <button
            key={num}
            type="button"
            className={`btn ${
              values.recommend === num.toString() ? 'btn-secondary' : ''
            }`}
            onClick={() => setFieldValue('recommend', num.toString())}
          >
            {num}
          </button>
        ))}
      </div>

      {errors.recommend && touched.recommend && (
        <div className="alert alert-danger text-center">{errors.recommend}</div>
      )}
<hr />
  
   <p><strong>Nos encantaría saber lo que piensas. </strong>¿Tienes algún comentario, sugerencia o felicitación que nos quieras compartir? 
        </p>
        <div className="form-outline mb-4 text-start" >
          <Field as="textarea" className="form-control" name="comment" placeholder="Escribe aquí tu opinion"/>
          <label className="form-label " >Tu opinión 
          </label>
          {errors.comment && touched.comment && (
        <div className="alert alert-danger text-center">{errors.comment}</div>
      )}
        </div>
<div className="card-footer text-end">
      <input type="submit" className="btn btn-secondary"/>
    </div>
    </Form>
  )}
</Formik>

       
     
    </div>
  </div>
  </div>
  )}

</div>
</div>
        </div>


    )
}



    

 