import React, { useEffect, useState } from "react";
import {
 PaymentElement,
 useStripe,
 useElements
} from "@stripe/react-stripe-js";
import { useSelector, useDispatch } from "react-redux";
import { setCheckoutStep} from "../../actions/index";
import { useNavigate } from "react-router-dom";
import LoadingSpinner from "../../utils/LoadingSpinner";
import { clearCart} from "../../actions/index";
import authService from "../../services/auth.service";


export default function CheckoutForm() {
  
 const navigate = useNavigate();
 const dispatch = useDispatch();
 const stripe = useStripe();
 const elements = useElements();
 const [message, setMessage] = useState(null);
 const [isLoading, setIsLoading] = useState(false);
 const  step  = useSelector((state) => state.publicActions.purcharse);
 const [currentUser, setCurrentUser] = useState(undefined);


  useEffect(() => {
    const user = authService.getCurrentUser();
    if (user) {
      setCurrentUser(user);
    }
  }, []);


 //// solicta el pago con stripe al servidor  ////// 
 useEffect(() => {
   if (!stripe) {
     return;
   }
   const clientSecret = localStorage.getItem("clientSecret");
    if (clientSecret) {
      setIsLoading(true);
      stripe
        .confirmCardPayment(clientSecret, {
          payment_method: {
            card: elements.getElement(PaymentElement),
          },
        })
        .then((result) => {
          
          if (result.error) {
            setMessage(result.error.message);
          } else {
            setMessage(result.paymentIntent.status);
          }
          setIsLoading(false);
        }

   );
  }
   if (!clientSecret) {
     return;
   }
  
   stripe.retrievePaymentIntent(clientSecret).then(({ paymentIntent }) => {
      
     switch (paymentIntent.status) {
       case "succeeded":
         setMessage("Pago realizado con éxito");
         
         break;
       case "processing":
         setMessage("Tu pago esta en proceso.");
         break;
       case "requires_payment_method":
         setMessage("Tu pago fue rechazado, Porfavor, intenta nuevamente.");
         break;
       default:
         setMessage("Algo salio mal.");
         break;
     }

   });
 }, [stripe]);

 

//////// fin del pago con stripe ////////

/////// limpia el carro si el pago fue efectuado con exito/// 


 /////// fin de limpiar el carro //////


////// handle para verificar el pago y guardarlo en la DB////////
 const handleSubmit = async (e) => {
   e.preventDefault();
   if (!stripe || !elements) {
     return;
   }
   setIsLoading(true);
  
   const { error } = await stripe.confirmPayment({
     elements,
     redirect:  "if_required",
     confirmParams: {
      return_url: 'https://www.maceronu.com',
    },
  /// si el pago fue exitoso guarda la orden en la base de datos si el usuario esta logueado guarda el id del usuario y el id del pago /// 
    }).then((result) => { 
      if (result.paymentIntent) { 
        if(currentUser){
          fetch("https://localhost:3000/offer/confirm/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              data: {...step},
              userId: currentUser.user.id,
              paymentIntentId: result.paymentIntent.id,
            }),
          })
            .then((res) => res.json())
            .then((data) => {
            
            });
        }
        else{
          fetch("https://localhost:3000/offer/confirm/", {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              paymentIntentId: result.paymentIntent.id,
              data: {...step},
            }),
          })
            .then((res) => res.json())
            .then((data) => {
              
            });
        }
       
        navigate("/success");
      }
    });
            
  };



 return (

   <form id="payment-form" onSubmit={handleSubmit}> 
     <PaymentElement id="payment-element" />
     
     <button disabled={isLoading || !stripe || !elements} id="submit" className="btn btn-inline checkout-proced">
     <span id="button-text">
         {isLoading ? <div className="spinner "  id="spinner">Cargando</div> : "Pagar Ahora"}
       </span>
     </button>
     <br/> 
     {message && <div id="payment-message">{message}</div>}   {isLoading ?  <span>estamos trabajando para ti...  <br/> <LoadingSpinner/></span> : null}
   </form>
 );
}