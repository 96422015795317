import React, { useEffect, useState} from  'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../css/blog-details.css";
import logo from  "../../img/isotipo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {  Link } from "react-router-dom";
import LoadingSpinner from "../../utils/LoadingSpinner";
import userService from '../../services/user.service';
import { phoneRegExp } from "../../utils/validation";




export default function Service() {

   
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(!show);
    const handleShow = () => setShow(!show);

    

const formSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    email: Yup.string().email("Email inválido").required("Campo requerido"),
    phone: Yup.string()
      .required("Número de teléfono requerido")
      .matches(phoneRegExp, "Número de teléfono inválido, se requieren 10 dígitos")
      .min(10, "Número de teléfono muy corto, se requieren 10 dígitos")
      .max(10, "Número de teléfono muy largo, se requieren 10 dígitos"),
    text: Yup.string().required("Campo requerido"),
    terms: Yup.boolean().oneOf([true], "Debes aceptar los términos y condiciones"),
    });

    
const initialValues = {
    name: "",
   text: "",
    email: "",
    shipment: "",
    terms: false,
};


const onSubmit = (values) => {
    userService.createService(values).then(
        (response) => {
           
            handleClose();
        },
        (error) => {
           
        }
    );
};


    return (
        <div>
          
            <section className="inner-section blog-details-part">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-xl-10">
                        <article className="blog-details">
                        <h1>Sevicio Maceronu</h1>
                            <a className="blog-details-thumb" href="">
                                <img src={process.env.PUBLIC_URL + "/images/slider/1.jpeg"} alt="blog"/>
                            </a>
                            <div className="blog-details-content">
                                <ul className="blog-details-meta">
                                    <li>
                                        <i className="icofont-ui-calendar"> <FontAwesomeIcon icon={['fas', 'fa-calendar']}/></i>
                                        <span>{new Date().toLocaleDateString()}</span>
                                    </li> 
                                    <li>
                                        <i className="icofont-user-alt-3"><FontAwesomeIcon icon={['fas', 'fa-tag']}/></i>
                                        <span>Maceronu</span>
                                    </li>
                                    <li>
                                        <i className="icofont-speech-comments"> <FontAwesomeIcon icon={['fas', 'fa-mobile']}/></i>
                                        <span>Servicio disponible en 5 dias habiles</span>
                                    </li>
                                    <li>
                                        <i className="icofont-share-boxed"><FontAwesomeIcon icon={['fas', 'fa-handshake-simple']}/></i>
                                        <span> 4 servicios ya concretados</span>
                                    </li>
                                </ul>
                                <h2 className="blog-details-title">Conseguimos las piezas que necesitas.</h2>
                                <p className="blog-details-desc">Para conseguir refacciones para celular con nuestro servicio, Maceronu, solo debes seguir los siguientes pasos:</p>
                                <blockquote className="blog-details-quote">
                                    <p>Te aseguramos que tendras un servicio de calidad</p>
                                    <footer>- Maceronu</footer>
                                </blockquote>
                                <p className="blog-details-desc">Recuerda que, para poder conseguir refacciones con nuestro servicio, es necesario que proporciones la información de tu dispositivo de forma precisa y detallada. Además, el costo de la pieza y del servicio dependerá de las características de tu dispositivo y de la complejidad de la reparación. En Maceronu nos esforzamos por ofrecerte un servicio de calidad y con garantía, para que puedas disfrutar de tu dispositivo móvil sin preocupaciones.</p>
                                <div className="row blog-details-grid">
                                    <div className="col-md-6 col-lg-6">
                                    <img src={process.env.PUBLIC_URL + "/images/slider/2.jpeg"} alt="blog"/>
                                    </div>
                                    <div className="col-md-6 col-lg-6 ">
                                        <p className='image-shadow'>Si vives en Leon GTO. Mexico puedes acuidr a  <i className="icofont-location-pin"><FontAwesomeIcon  icon={['fas', 'fa-location-dot']}/></i> <a href="https://www.google.com/maps/place/Maceronu/@21.1242551,-101.6806725,15z/data=!4m5!3m4!1s0x0:0x90e775b0025e2454!8m2!3d21.1242551!4d-101.6806725">  <p> 5 de Mayo #216 Col. Centro.
                                Plaza de la TecnologÍa 
                                    Local #184 
                                    C.P. 37000, León, Guanajuato</p></a></p>
                                    </div>
                                </div>
                                <div className="blog-details-subtitle">
                                    <h3>Pasos a seguir:</h3>
                                    <ul className="blog-details-list">
                                    <li>Ponte en contacto con nosotros a través de nuestro sitio web o número telefónico, y proporciónanos la marca y modelo de tu dispositivo, así como la pieza que necesitas.</li>
                                    <li>Te proporcionaremos un presupuesto detallado de la reparación, incluyendo el costo de la pieza y el servicio técnico.</li>
                                    <li>Si estás de acuerdo con el presupuesto, te indicaremos el tiempo de espera estimado para la llegada de la pieza y para la reparación completa.</li>
                                    <li>Al recibir la pieza, nuestro equipo de técnicos altamente capacitados realizará la reparación de tu dispositivo en un plazo no mayor a 5 días hábiles.</li>
                                    <li>Te notificaremos cuando tu dispositivo esté listo para ser recogido en nuestro taller o para ser enviado a tu domicilio, según tu preferencia.</li>
                                </ul>
                                </div>
                               
                                <a className="blog-details-thumb mb-4" href="">
                                <img src={process.env.PUBLIC_URL + "/images/slider/3.jpeg"} alt="blog"/>
                                </a>
                                
                                <h3>Preguntas frecuentes:</h3>
                                <ul className="blog-details-list">
                                    <li>
                                    <h4>¿Qué tipos de refacciones para celular puedo conseguir con este servicio?</h4>
                                    <p>Ofrecemos una amplia variedad de refacciones para celulares de diferentes marcas y modelos, incluyendo pantallas, baterías, cámaras, botones, conectores, entre otros.</p>
                                    </li>
                                    <li>
                                    <h4>¿Cómo sé si la refacción que necesito está disponible?</h4>
                                    <p>Por favor, contáctanos a través de nuestro formulario de contacto o vía telefónica para verificar la disponibilidad de la refacción que necesitas. En caso de que no la tengamos en stock, haremos todo lo posible por conseguirla para ti.</p>
                                    </li>
                                    <li>
                                    <h4>¿Cuánto tiempo tarda en llegar la refacción?</h4>
                                    <p>El tiempo de entrega puede variar dependiendo de la ubicación y la disponibilidad de la refacción. En promedio, nuestro servicio de entrega tarda entre 2 y 5 días hábiles en llegar.</p>
                                    </li>
                                    <li>
                                    <h4>¿Puedo instalar la refacción yo mismo?</h4>
                                    <p>La instalación de refacciones para celular puede ser complicada y requerir de conocimientos técnicos. Si no estás seguro de cómo hacerlo, te recomendamos que acudas con un técnico especializado para evitar dañar tu dispositivo.</p>
                                    </li>
                                    <li>
                                    <h4>¿Qué garantía ofrecen en caso de que la refacción falle?</h4>
                                    <p>Todas nuestras refacciones cuentan con una garantía de funcionamiento de 90 días. En caso de que la refacción presente alguna falla en este periodo, por favor contáctanos para hacer valida la garantía.</p>
                                    </li>
                                </ul>
                                <div className="blog-details-footer">
                                    <ul className="blog-details-share">
                                        <li><span>compartir:</span></li>
                                        <li><a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=www.maceronu.com/offer`}  className="icofont-facebook" title="Facebook"><FontAwesomeIcon icon={['fab', 'fa-facebook']}/></a></li>
                                            <li><a target="_blank" href={`https://twitter.com/intent/tweet?text=www.maceronu.com/offer`} className="icofont-twitter" title="Twitter"><FontAwesomeIcon icon={['fab', 'fa-twitter']}/></a></li>
                                            <li><a target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=www.maceronu.com/offer&title=La%20mejor%20tienda&summary=Compra%20Ahora%20!&source=www.maceronu.com/offer`} className="icofont-linkedin" title="Linkedin"><FontAwesomeIcon icon={['fab', 'fa-linkedin']}/></a></li>
                                            <li><a target="_blank" href={`https://pinterest.com/pin/create/button/?url=www.maceronu.com/offer&media=https%3A//drive.google.com/file/d/1pK9lbRURwV-kJ3dmGakkw5SUW5E-YmgO/view?usp=sharing&description=Compra%20Ahora%20!`} className="icofont-instagram" title="Instagram"><FontAwesomeIcon icon={['fab', 'fa-instagram']}/></a></li>
                                    </ul>
                                    <ul className="blog-details-tag">
                                        <li><span>Etiquetas:</span></li>
                                        <li><a href="#">Celulares</a></li>
                                        <li><a href="#">Accesorios</a></li>
                                        <li><a href="#">Refacciones</a></li>
                                    </ul>
                                </div>
                            </div>
                        </article>
                        <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={(values) => {onSubmit(values)} }>
                        

                        <Form className="blog-details-comment">
                            <h3 className="blog-details-comment-title">Haz tu cotizacion, contáctanos por WhatsApp:  
                                    <a href='https://api.whatsapp.com/send?phone=+524773959779&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"><i className="icofont-ui-touch-phone"><FontAwesomeIcon  icon={['fab', 'fa-whatsapp']}/></i><span>+52 477-395-9779</span> </a> <span> y </span>
                                    
                                        <a href='https://api.whatsapp.com/send?phone=+524778751774&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"><i className="icofont-ui-touch-phone"><FontAwesomeIcon  icon={['fab', 'fa-whatsapp']}/></i> <span>+52 477-875-1774 </span> </a> 

                                        </h3>
                            <div className="row">
                                <div className="col-lg-6">
                                
                                
                </div>
                
                                                <h3>Ó envianos tu solicitud por aqui</h3>

                                <div className="col-lg-6">
                                    
                                    <div className="form-group">
                                        <Field type="text" name="name"  className="form-control" placeholder="Nombre completo"/>
                                        <ErrorMessage name="name" component="span"  className="alert alert-danger"/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Field type="email" name="email"  className="form-control" placeholder="Tu correo"/>
                                        <ErrorMessage name="email" component="span"  className="alert alert-danger"/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Field type="number" name="phone"  className="form-control" placeholder="Tu número"/>
                                        <ErrorMessage name="phone" component="span"  className="alert alert-danger"/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <Field component="textarea" rows="3" name='text' className="form-control" placeholder="Equipo a reparar y detalles"/>
                                        <ErrorMessage name="text" component="span"  className="alert alert-danger"/>
                                    </div>
                                    <div className="checkout-check">
                  
                  <Field name="terms" type="checkbox"  />
                  
                     <label htmlFor="checkout-check">Si realizas esta solictud aceptas nuestros <Link to="/privacy">terminos y condiciones</Link>.</label>
                     <ErrorMessage name="terms" component="span"  className="alert alert-danger"/>
                 </div>  
                                </div>
                                <div className="details-add-group">
                          
                                    
                          
                                        <button className="product-add" title="Add to Cart"  >
                                            <i className="fas fa-shopping-basket"><FontAwesomeIcon icon={['fas', 'fa-sheet-plastic']}/></i>
                                            <span>Enviar cotizacion</span>
                                        </button>
                                      
                                    </div>
                                
                            </div>
                        </Form>
                        </Formik>


                        <div className="blog-details-profile">
                            <a href=""><img src={logo} alt="avatar"/></a>
                            <h3>Maceronu</h3>
                            <h4>soporte@maceronu.com</h4>
                            <ul>
                            <li><a className="icofont-facebook" href="https://www.facebook.com/WorldCellT"><FontAwesomeIcon  icon={['fab', 'fa-facebook-f']} target="_blank" rel="noreferrer" /></a></li>
                                <li><a className="icofont-twitter" href="https://twitter.com/WorldCell10"><FontAwesomeIcon  icon={['fab', 'fa-twitter']} target="_blank"/></a></li>
                                <li><a className="icofont-google" href="https://www.google.com/search?q=maceronu&oq=maceronu&aqs=edge..69i57j0i10l4j69i60j69i61l3.735j0j4&sourceid=chrome&ie=UTF-8&newwindow=1&tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=ALiCzsbYGGD9LBXKCp6arR7SM7cPI7hCNQ:1661376742989&rflfq=1&num=10&rldimm=10441443659896202324&lqi=CghtYWNlcm9udVoKIghtYWNlcm9udZIBBXN0b3Jl&ved=2ahUKEwivierUtuD5AhXzKkQIHeVUAYsQvS56BAgREAE&sa=X&rlst=f#rlfi=hd:;si:10441443659896202324,l,CghtYWNlcm9udVoKIghtYWNlcm9udZIBBXN0b3Jl;mv:[[21.157379,-101.680596],[21.1223802,-101.68202319999999]];tbs:lrf:!1m4!1u2!2m2!2m1!1e1!2m1!1e2!3sIAE,lf:1,lf_ui:2" 
                                rel="noreferrer" target="_blank">
                                    <FontAwesomeIcon  icon={['fab', 'fa-google']} /></a></li>
                                <li><a className="icofont-instagram" href="https://www.instagram.com/maceronu_/" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fab', 'fa-instagram']} /></a></li>
                                <li><a className="icofont-ML" href="https://worldcellmx.mercadoshops.com.mx/" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fas', 'fa-basket-shopping']} /></a></li>
                                <li><a className="icofont-tiktok" href="https://www.tiktok.com/@maceronu" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fab', 'fa-tiktok']} /></a></li>
                            </ul>
                          
                        </div>
                        </div>
           
        </div> 
    </div>
    </section>
    </div>
    
);}


