import React, { useEffect, useState } from "react";
import AuthService from "../../services/auth.service";



export default function  Chat({socket}) {

    const [messages, setMessages] = useState([]);
    const [message, setMessage] = useState("");
    const [currentUser, setCurrentUser] = useState(null);
    const [typeofService, setTypeofService] = useState("");
    const [conversation, setConversation] = useState([]);
    const [conversationResolved, setConversationResolved] = useState(false);
    const [placeHolder, setPlaceHolder] = useState("Escribe un mensaje...");

    useEffect(() => {
        let componentMounted = true;
        const user = AuthService.getCurrentUser();
        if (user) {
          setCurrentUser(user);
          socket.on("getMessages", (messages) => {
            setMessages(messages);
        });
        }

        return () => {
            componentMounted = false;
        };

    }, []);


useEffect (() => {
    let componentMounted = true;

    if (currentUser !== null && currentUser !== undefined && typeofService !== "") {
        socket?.emit('joinChat', {...currentUser.user, room: typeofService + " " +   socket.id}, socket.id, (error) => {
          if(error) {
            alert(error);
          }
        }
    
          ); 

    }
    
    else if (typeofService !== "") {
        socket?.emit('joinChat', {username: "Guest_"+ socket.id , room: typeofService}, socket.id, (error) => {
            if(error) {
                alert(error);
            }
        }
        );
    }
    setPlaceHolder("Escribe un mensaje...");
   return () => {
    componentMounted = false;
};  
}, [socket, currentUser, typeofService]);

useEffect (() => {
    let componentMounted = true;
    socket?.on('message', (message) => {
        setMessages([...messages, message]);
    });
    return () => {
        componentMounted = false;
    };
}, [messages, message]);

useEffect (() => {
    let componentMounted = true;
    socket?.on('newConversation', (conversation) => {
        setConversation(conversation);
    });
    return () => {
        componentMounted = false; 
    };
}, [conversation]);

useEffect (() => {
    let componentMounted = true;
    socket?.on('conversationResolved', (conversation) => {
        setConversationResolved(!conversationResolved);
        setPlaceHolder("La conversación termino");
    });
    return () => {
        componentMounted = false;
    };
}, [socket, conversationResolved]);



function sendMessage(e) {
    e.preventDefault();
    if(message && conversation !== null) {
        socket?.emit('addNewMessage', {...currentUser.user, room: typeofService + " " +   socket.id, message, conversation: conversation},socket.id, () => setMessage(''));
        setMessage('');
    }
}


  return (
    <div>
        {currentUser ? 


    <div className="chat-window">


{typeofService === "" ? (    
   <div className="chat-header">
        <div className="chat-window__header__title">
            <h3>Maceronu</h3>
            </div>
            <div className="chat-body">
                <h6>Hola, estamos muy interesados en asistirte que tipo de atencion necesitas?</h6>
                <input type="radio"
                name="service"
                value="support"
                onChange={(e) => setTypeofService(e.target.value)}
                />
                <label htmlFor="support">Soporte</label>
                <input type="radio"
                name="service"
                value="sales"
                onChange={(e) => setTypeofService(e.target.value)}
                />
                <label htmlFor="sales">Ventas</label>
                </div>
    </div>
) : ( <>
    <div className="chat-header">
            <div className="chat-header-title">
                <span>Soporte Maceronu</span>
            </div>
        </div>
        <div className="chat-body">
            <div className="chat-body__messages">
                {messages.map((message, index) => (
                    <div key={index} className={`chat-message ${message.user_id === currentUser?.user.id ? "" : "chat-reciever"}`}>
                        {message.user === "admin" ? <div className="admin-message"> <span className="admin-message-content"> "{message.message}" </span> </div>:  <div className="chat-message-flex"> <span className="chat-message-content">{message.message}</span> </div>}
                       
                    </div>
                ))}
            </div>
        </div>
        <div className="chat-footer">
            <form className="chat-form"
                onSubmit={(e) => {
                    e.preventDefault();
                    sendMessage(e);
                    
                }}
            >   
                <input
                    type="text"
                    className="chat-textarea"
                    placeholder={placeHolder}
                    value={message}
                    onChange={(e) => setMessage(e.target.value)}
                    disabled={conversationResolved}
                   
                />
                <button className="chat-submit" type="submit" disabled={conversationResolved}>Enviar</button>
                
            </form>
        </div>

        </>)}
    </div>
        : <div className="chat-window"> 
        <div className="chat-header">
        <div className="chat-window__header__title">
            <h3>Maceronu</h3>
            </div>
            <div className="chat-body">
                <h6>Hola, estamos muy interesados en asistirte para recibir asistencia debes registrarte o iniciar sesion</h6>
                <p> 
                    <a href="/login">Iniciar sesion</a>
                    <span> o </span>
                    <a href="/register">Registrarse</a>
                </p>
        </div>
    </div>
    </div>

        }
    </div>
    
  );
}