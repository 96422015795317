export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE = "SET_MESSAGE";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const ADD_TO_CART = "ADD_TO_CART";
export const REMOVE_FROM_CART = "REMOVE_FROM_CART";
export const CLEAR_CART = "CLEAR_CART";
export const PLUS = "PLUS";
export const MINUS = "MINUS";
export const ADD_MOVIE_FAVORITE = "ADD_MOVIE_FAVORITE";
export const GET_RECO = "GET_RECO";
export const GET_SEARCH = "GET_SEARCH";
export const GET_MOVIE_DETAIL = "GET_MOVIE_DETAIL";
export const REMOVE_MOVIE_FAVORITE = "REMOVE_MOVIE_FAVORITE";
export const GET_MOVIE_FAVORITE = "GET_MOVIE_FAVORITE";
export const GET_MAS_VENDIDOS = "GET_MAS_VENDIDOS";
export const GET_NUEVOS_P = "GET_NUEVOS_P";
export const GET_RECOMENDADOS = "GET_RECOMENDADOS";
export const PURCHARSE = "PURCHARSE";
export const PURCHARSE_SUCCESS = "PURCHARSE_SUCCESS";
export const SET_CHECKOUT_STEP = "SET_CHECKOUT_STEP";
export const SET_SHIPPING_ADDRESS = "SET_SHIPPING_ADDRESS";
export const GET_SECTION = "GET_SECTION";
export const FORGOT = "FORGOT";
export const REFRESH_TOKEN = "REFRESH_TOKEN";
export const GET_CATEGORY = "GET_CATEGORY";
export const RESET = "RESET";
