const getLocalRefreshToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
   
    return user?.info?.refreshToken;
  };
  const getLocalAccessToken = () => {
    const user = JSON.parse(localStorage.getItem("user"));
   
    return user?.info?.accessToken;
  };
  const updateLocalAccessToken = (token) => {
    let user = JSON.parse(localStorage.getItem("user"));
    user.info.accessToken = token;
    localStorage.setItem("user", JSON.stringify(user));
    
  };
  const getUser = () => {
    return JSON.parse(localStorage.getItem("user"));
  };
  const setUser = (user) => { 
    localStorage.setItem("user", JSON.stringify(user));
  };
  const removeUser = () => {
    localStorage.removeItem("user");
    
    
  };
  const TokenService = {
    getLocalRefreshToken,
    getLocalAccessToken,
    updateLocalAccessToken,
    getUser,
    setUser,
    removeUser,
  };
  export default TokenService;