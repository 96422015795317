import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from 'react-responsive-carousel';
import '../../css/faq.css';


export default function Support() {
    return (  

<div key='Aboutkey'> 
<section className="inner-section faq-part">
<div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <nav className="nav nav-pills flex-column" id="scrollspy">
                            <a className="nav-link" href="#item-1">¿Cómo me pongo en contacto con atencion al cliente?</a>
                            <a className="nav-link" href="#item-2">¿No encuentro un producto, puedo efectuarlo bajo pedido?</a>
                            <a className="nav-link" href="#item-3">Website es lento, ¿Cómo puedo mejorar la navegacion?</a>
                            <a className="nav-link" href="#item-4">¿Cómo creo una cuenta?</a>
                            <a className="nav-link" href="#item-5">No puedo encontrar una respuesta a mi pregunta, ¿cómo me pongo en contacto?</a>
                        </nav>
                    </div>
                    <div className="col-lg-9">
                        <div data-bs-spy="scroll" data-bs-target="#scrollspy" data-bs-offset="0" tabIndex="0">
                            <div className="scrollspy-content" id="item-1">
                                <h3>Como me pongo en contacto con atencion al cliente?</h3>
                                <p>Para ponerte en contacto con atención al cliente, puedes buscar en la página web de la empresa un apartado que se llame "contacto" o "atención al cliente" y encontrarás información de teléfonos, correo electrónico o formulario de contacto. También puedes buscar en las redes sociales de la empresa y enviarles un mensaje directo.</p>
                            </div>
                            <div className="scrollspy-content" id="item-2">
                                <h3>No encuentro un producto, puedo efectuarlo bajo pedido?</h3>
                                <p>Depende de la empresa y del producto. Lo mejor es buscar en la página web de la empresa si ofrecen la opción de efectuar un pedido especial o personalizado. Si no encuentras información al respecto, puedes contactar a atención al cliente para preguntarles si es posible realizar un pedido bajo pedido.</p>
                            </div>
                            <div className="scrollspy-content" id="item-3">
                                <h3>Website es lento, ¿Cómo puedo mejorar la navegacion?</h3>
                                <ul className='blog-details-list'>
                                    <li>Actualiza tu navegador web. Si tu navegador web no está actualizado, puede que no puedas ver correctamente las páginas web. Por ejemplo, si tu navegador web no es compatible con HTML5, no podrás ver vídeos en las páginas web.</li>
                                    <li>Actualiza tu sistema operativo. Si tu sistema operativo no está actualizado, puede que no puedas ver correctamente las páginas web. Por ejemplo, si tu sistema operativo no es compatible con HTML5, no podrás ver vídeos en las páginas web.</li>
                                    <li>Actualiza tu antivirus. Si tu antivirus no está actualizado, puede que no puedas ver correctamente las páginas web. Por ejemplo, si tu antivirus no es compatible con HTML5, no podrás ver vídeos en las páginas web.</li>
                                    <li>Actualiza tu software. Si tu software no está actualizado, puede que no puedas ver correctamente las páginas web. Por ejemplo, si tu software no es compatible con HTML5, no podrás ver vídeos en las páginas web.</li>
                                    <li>Actualiza tu hardware. Si tu hardware no está actualizado, puede que no puedas ver correctamente las páginas web. Por ejemplo, si tu hardware no es compatible con HTML5, no podrás ver vídeos en las páginas web.</li>
                                    <li>Actualiza tu conexión a Internet. Si tu conexión a Internet no está actualizada, puede que no puedas ver correctamente las páginas web. Por ejemplo, si tu conexión a Internet no es compatible con HTML5, no podrás ver vídeos en las páginas web.</li>
                                    <li>Actualiza tu dispositivo. Si tu dispositivo no está actualizado, puede que no puedas ver correctamente las páginas web. Por ejemplo, si tu dispositivo no es compatible con HTML5, no podrás ver vídeos en las páginas web.</li>

                                </ul>
                            </div>
                            <div className="scrollspy-content" id="item-4">
                                <h3>Como crear una cuenta</h3>
                                <p>Para crear una cuenta en la mayoría de las páginas web, deberás buscar un botón que diga "registrarse" o "crear cuenta". Luego, deberás seguir los pasos que se te indican, como proporcionar tu nombre, correo electrónico y crear una contraseña. También puedes crear una cuenta a través de redes sociales como Facebook o Google.</p>
                            </div>
                            <div className="scrollspy-content" id="item-5">
                                <h3>No puedo encontrar una respuesta a mi pregunta, ¿cómo me pongo en contacto?</h3>
                                <p>Si tienes alguna pregunta o preocupación sobre tu pedido, puedes ponerte en contacto con nuestro equipo de atención al cliente por correo electrónico o por teléfono. También puedes utilizar nuestro chat en línea para obtener ayuda en tiempo real. Estamos disponibles para ayudarte de lunes a viernes de 9 a.m. a 5 p.m. hora local.</p>
                                <ul className="footer-contact">
                                <li>
                                    <i className="icofont-ui-email"><FontAwesomeIcon  icon={['fas', 'fa-envelope']}/></i>
                                    <p>
                                    <a href="mailto:soporte@maceronu.com?Subject=Hola%20Maceronu%20quiero%20informes"><span> soporte@maceronu.com</span></a>
                                        
                                    </p>
                                </li>
                                <li>
                                    <i className="icofont-ui-touch-phone"><FontAwesomeIcon  icon={['fab', 'fa-whatsapp']}/></i>
                                    <p>
                                    <a href='https://api.whatsapp.com/send?phone=+524773959779&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"><span>+52 477-395-9779</span> </a> <br/>
                                    
                                        <a href='https://api.whatsapp.com/send?phone=+524778751774&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"> <span>+52 477-875-1774</span> </a>
                                    </p>
                                </li>
                               </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
)
}