import { useEffect, useState, useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { ItemContext } from "./Movie";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export default function Review () { 

    const [currentUser, setCurrentUser] = useState("");
    const { item } = useContext(ItemContext);
    const [itemReview, setItemReview] = useState(0);
    const [itemReviewCount, setItemReviewCount] = useState(0);
    const [itemComment, setItemComment] = useState(0);
    const [itemRate, setItemRate] = useState(0);
    const [itemRateCount, setItemRateCount] = useState(0);
    const [purchasedBefore, setPurchasedBefore] = useState(false);
   
    useEffect (() => {
        let mounted = true;
        const user = AuthService.getCurrentUser();
        if (user) {
            setCurrentUser(user);
        }
        return () => mounted = false;
    }, []);
    
    useEffect (() => { 
        
        if ( item ) {
        UserService.getReview(item.id).then(
         
            // (response) => {
            //     
            //     setItemReview( response.data);
            //     setItemReviewCount(response.data.count);
            //     setItemComment(response.data.comments);
            //     setItemRate(response.data.rate);
            //     setItemRateCount(response.data.rate_count);
            //     setPurchasedBefore(response.data.purchased_before);
            // }
            (response) => {
                
                setItemReview(response.data);
                setItemReviewCount(response.data.length);
    });
      if (currentUser) {
     UserService.getPurchasedBefore(item.id, currentUser.id).then(
        (response) => {
           
            setPurchasedBefore(response.data);

        });
    }
    }

    }, [item, itemReviewCount, currentUser]);

const reviewHandler = (values) => {
    if (item) {
    const product_id = item.id;
    const user_id = currentUser.user.id
    const opinion = values.opinion
    const rate = values.rate 

    UserService.addReview({product_id, user_id, opinion, rate}).then(
        (response) => {
            
            setItemReview(response.data);
            setItemReviewCount(response.data.length);
        });
    }
}


    
    return  (
        <div>
       

        {itemReviewCount > 0 && ( <> 

       <div className="product-details-frame">
                            <h3 className="frame-title">Opiniones ({itemReviewCount})</h3>
                            <ul className="review-list">
                              
                                    {itemReview.map((itemReview) => (
                                          <li className="review-item" key={itemReview.id}>
                                    <div className="review-media">
                                        <a className="review-avatar" href="#">
                                            <span> </span>
                                            <img src={process.env.PUBLIC_URL + "/images/user.png"} alt="review"/>
                                        </a>
                                        <h5 className="review-meta">
                                            
                                        {/* <a href="#"> {currentUser?.user?.id  === itemReview?.user_id ? "Tú" : itemReview.Users?.user}</a> */}
                                          
                                            <span> {new Date(itemReview.createdAt).toLocaleString()} </span>
                                        </h5>
                                    </div>
                                    <ul className="review-rating">
                                        {[...Array(5)].map((star, i) => { 
                                            const ratingValue = i + 1;
                                            return (
                                                <li key={ratingValue}>
                                                    <FontAwesomeIcon icon={["fas", "star"]} color={ratingValue <= itemReview.rating ? "#ffc107" : "#e4e5e9"} />
                                                </li>
                                            );
                                        })}
                                   
                                    </ul>
                                    <p className="review-desc">{itemReview.opinion}</p>
                                   {/* <form className="review-reply">
                                        <input type="text" placeholder="Agrega una opinion"/>
                                        <button><i className="icofont-reply"></i>Responder</button>
                                    </form>
                                     <ul className="review-reply-list"> 
                                        {itemComment?.map((itemComment) => ( 
                                        <li className="review-reply-item">
                                            <div className="review-media">
                                                <a className="review-avatar" href="#">
                                                    <img src={process.env.PUBLIC_URL + "/images/user.png"}alt="review"/>
                                                </a>
                                                <h5 className="review-meta">
                                                    <a href="#">{itemComment.user_id}</a>
                                                    <span>{new Date(itemComment.createdAt).toLocaleString()}</span>
                                                </h5>
                                            </div>
                                            <p className="review-desc">{itemComment.complement}</p>
                                            <form className="review-reply">
                                                <input type="text" placeholder="Agrega una opinion"/>
                                                <button><i className="icofont-reply"></i>Responder</button>
                                            </form>
                                        </li>
                                        ))}
                                    </ul> */}
                                </li>
                                    ))}
                            </ul>
                        </div> 
                        </> )}

   {currentUser ? ( <> 
    
                        {purchasedBefore?.user_id === currentUser.user?.id ? purchasedBefore.reviewed === false ?   

                          
                        <div className="product-details-frame">
                            <h3 className="frame-title">Agregar una opinion</h3>
                            <Formik 
                                initialValues={{
                                    opinion: "",
                                    rate: 0,
                                   
                                }}
                                validationSchema={Yup.object().shape({
                                    opinion: Yup.string().required("Opinion requerida"),
                                    rate: Yup.number().required("Calificacion requerida"),
                                })}
                                onSubmit ={ (values, {resetForm}) => {
                                    reviewHandler(values);
                                    resetForm();
                                }}>
                                
                                    <Form className="review-form">


                       
                                <div className="row">
                                    <div className="col-lg-12">
                                   
                                        <div className="star-rating">
                                        <ErrorMessage name="rate" component="span"/> 
                                            <Field name="rate" type="radio" id="star5" value="5" />
                                            <label htmlFor="star5" title="5 stars">
                                                 
                                            </label>
                                            <Field name="rate" type="radio" id="star4" value="4" />
                                            <label htmlFor="star4" title="4 stars">
                                                
                                            </label>
                                            <Field name="rate" type="radio" id="star3" value="3" />
                                            <label htmlFor="star3" title="3 stars">
                                                
                                            </label>
                                            <Field name="rate" type="radio" id="star2" value="2" />
                                            <label htmlFor="star2" title="2 stars">
                                                
                                            </label>
                                            <Field name="rate" type="radio" id="star1" value="1" />
                                            <label htmlFor="star1" title="1 star">    
                                            </label>
                                            <span> : Calificacion </span>
                                             
                                        </div>
                                    </div>
                                    <div className="col-lg-12">
                                        <div className="form-group">
                                            <Field name="opinion" as="textarea" className="form-control" placeholder="Opinion" />
                                             <ErrorMessage name="opinion" component="span" /> 
                                        </div>
                                    </div>
                                   
                                    <div className="col-lg-12">
                                        <button className="btn btn-inline">
                                            <i className="icofont-water-drop"></i>
                                            <span>Enviar opinion</span>
                                        </button>
                                    </div>
                                </div>
                            </Form>
                                
                            </Formik>

                        </div>
                        : <div className="product-details-frame">
                            <h3 className="frame-title">Ya has calificado este producto</h3>
                        </div>
                        : <div className="product-details-frame">
                            <h3 className="frame-title">Debes comprar el producto para poder calificarlo</h3>
                        </div>
                        }
                        </> ) : <div className="product-details-frame">
                            <h3 className="frame-title">Debes iniciar sesion para poder calificar el producto</h3>
                        </div>
                        }
                    </div>




                    
      
    ) 
}


                