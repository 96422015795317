 
import React, { useState, useEffect } from "react";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import OfferForm from "./OfferForm";


const STRIPE_PUBLISHABLE_KEY =  process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY;
const stripePromise = loadStripe(STRIPE_PUBLISHABLE_KEY.toString());

const API = process.env.REACT_APP_URL;


export default function App() {
  
  const [clientSecret, setClientSecret] = useState("");

  useEffect(() => {
    
    fetch(API + "/offer/create-payment-intent", {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      
    }, )
      .then((res) => res.json())
      .then((data) => 
      setClientSecret(data.clientSecret));
  
  
  }, []);

  
  const appearance = {
    theme: 'stripe',

  };
  
  const options = {
    clientSecret,
    appearance,
  };
 
  
  return (
    <div className="App">
      {clientSecret && (
        <Elements options={options} stripe={stripePromise}>
          <OfferForm/>
        </Elements>
      )}
    </div>
  )
}
