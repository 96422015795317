import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from 'react-responsive-carousel';
import '../../css/faq.css';


export default function Help() {
    return (  

<div key='Aboutkey'> 
<section className="inner-section faq-part">
<div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <nav className="nav nav-pills flex-column" id="scrollspy">
                            <a className="nav-link" href="#item-1">¿Cómo será enviado mi producto?</a>
                            <a className="nav-link" href="#item-2">¿Cuánto tiempo demora el envío y cómo me doy cuenta que ya fue enviado?</a>
                            <a className="nav-link" href="#item-3">¿Puedo solicitar una paquetería diferente a las ofrecidas?</a>
                            <a className="nav-link" href="#item-4">¿Puede mi pedido ser enviado el mismo día que lo adquirí?</a>
                            <a className="nav-link" href="#item-5">No puedo encontrar una respuesta a mi pregunta, ¿cómo me pongo en contacto?</a>
                        </nav>
                    </div>
                    <div className="col-lg-9">
                        <div data-bs-spy="scroll" data-bs-target="#scrollspy" data-bs-offset="0" tabIndex="0">
                            <div className="scrollspy-content" id="item-1">
                                <h3>¿Cómo será enviado mi producto?</h3>
                                <p>En Maceronu.com, enviamos nuestros productos por medio de nuestras paqueterías aliadas para asegurarnos de que lleguen en buenas condiciones y lo más pronto posible a tus manos.</p>
                            </div>
                            <div className="scrollspy-content" id="item-2">
                                <h3>¿A cuánto tiempo demora el envío y cómo me doy cuenta que ya fue enviado?</h3>
                                <p>LEl tiempo de envío puede variar dependiendo de tu ubicación y del tipo de envío que hayas elegido. Para saber cuánto tiempo tardará tu envío, puedes consultar la página de detalle del producto o contactar a nuestro equipo de atención al cliente. Una vez que tu pedido haya sido enviado, te enviaremos un correo electrónico de confirmación con el número de rastreo de la paquetería para que puedas darle seguimiento a tu pedido.</p>
                            </div>
                            <div className="scrollspy-content" id="item-3">
                                <h3>¿Puedo solicitar una paquetería diferente a las ofrecidas?</h3>
                                <p>En este momento, solo trabajamos con paqueterías aliadas seleccionadas para asegurarnos de que tu pedido llegue en buenas condiciones y en el tiempo establecido. Si tienes alguna preocupación sobre el envío de tu pedido, por favor contáctanos para que podamos ayudarte a encontrar la mejor solución.</p>
                            </div>
                            <div className="scrollspy-content" id="item-4">
                                <h3>¿Puede mi pedido ser enviado el mismo día que lo adquirí?</h3>
                                <p>¡Por supuesto! Si realizas tu pedido antes de las 12 del mediodía (hora local), haremos todo lo posible para enviar tu pedido el mismo día. Si realizas tu pedido después de las 12 del mediodía, lo enviaremos al día siguiente hábil.</p>
                            </div>
                            <div className="scrollspy-content" id="item-5">
                                <h3>No puedo encontrar una respuesta a mi pregunta, ¿cómo me pongo en contacto?</h3>
                                <p>Si tienes alguna pregunta o preocupación sobre tu pedido, puedes ponerte en contacto con nuestro equipo de atención al cliente por correo electrónico o por teléfono. También puedes utilizar nuestro chat en línea para obtener ayuda en tiempo real. Estamos disponibles para ayudarte de lunes a viernes de 9 a.m. a 5 p.m. hora local.</p>
                                <ul className="footer-contact">
                                <li>
                                    <i className="icofont-ui-email"><FontAwesomeIcon  icon={['fas', 'fa-envelope']}/></i>
                                    <p>
                                    <a href="mailto:soporte@maceronu.com?Subject=Hola%20Maceronu%20quiero%20informes"><span> soporte@maceronu.com</span></a>
                                        
                                    </p>
                                </li>
                                <li>
                                    <i className="icofont-ui-touch-phone"><FontAwesomeIcon  icon={['fab', 'fa-whatsapp']}/></i>
                                    <p>
                                    <a href='https://api.whatsapp.com/send?phone=+524773959779&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"><span>+52 477-395-9779</span> </a> <br/>
                                    
                                        <a href='https://api.whatsapp.com/send?phone=+524778751774&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"> <span>+52 477-875-1774</span> </a>
                                    </p>
                                </li>
                               </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
)
}