//// libraries part ////
import React, {useCallback, useEffect, useState, useLayoutEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {Route, Routes, useNavigate, useLocation } from "react-router-dom";
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
import { fab } from '@fortawesome/free-brands-svg-icons'
import { io } from "socket.io-client";
import 'bootstrap/dist/css/bootstrap.min.css';
import "./scss/main.scss";
import AuthService from "./services/auth.service";
///// component part ////
import Movie from "./components/Movie/Movie";
import Slider from "./components/Slider/Slider";
import Home from "./components/Home/Home";
import Favorites from "./components/Favorites/Favorites";
import Buscador from "./components/Buscador/Buscador";
import Footer from "./components/Footer/Footer";
import MyAccount from "./components/User/MyAccount";
import CheckOut from "./components/Cart/CheckOut";
import Order from "./components/User/Order";
import Categories from "./components/Category/Categories";
import SubCategory from "./components/Category/SubCategory";
import SearchCategory from "./components/Buscador/SearchCategory";
import SearchBrand from "./components/Buscador/SearchBrand";
import Wrapper from "./utils/Wrapper";
import { logout } from "./actions/auth";
import EventBus from "./utils/EventBus";
import About from "./components/About/About";
import Faqs from "./components/About/Faqs";
import Help from "./components/About/Help";
import Privacy from "./components/About/Privacy";
import Section from "./components/Buscador/SearchSection";
import Delivery from "./components/About/Delivery";
import PaymentSecure from "./components/About/PaymentSecure";
import ReturnPolicy from "./components/About/ReturnPolicy";
import Support from "./components/About/Support";
import Invoice from "./components/User/Invoice";
import ChangePass from "./components/User/ChangePass"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import QuestionRequest from "./components/User/QuestionRequest";
import Chat from "./components/Support/Chat";
import Offer from "./components/Offers/Offer";
import Service from "./components/Service/Service";
import OrderHistory from "./components/User/OrdersHistory";
import OrderStatus from "./components/User/OrderStatus";
import SuggestionBox from './components/Survey/SuggestionBox';





library.add(fab, far, fas);
function App() {
const navigate = useNavigate();


const [checkUser , setCheckUser] = useState(true);
const [currentUser, setCurrentUser] = useState(null); 
const [socket, setSocket] = useState(null);
const [chatWindow, setChatWindow] = useState(false);

const dispatch = useDispatch();

useEffect(() => {
  let componentMounted = true;
try {
  (async () => {
    const user = await AuthService.checkCurrentUser();
    
    if (user?.data.info) {
      setCurrentUser(user.data);
    }
  })();
}
catch (err) {
  

}
return () => {
  componentMounted = false;
}

}, []);

const { pathname, hash, key } = useLocation();

  useEffect(() => {
    // if not a hash link, scroll to top
    if (hash === '') {
      window.scrollTo(0, 0);
    }
    // else scroll to id
    else {
      setTimeout(() => {
        const id = hash.replace('#', '');
        const element = document.getElementById(id);
        if (element) {
          element.scrollIntoView();
        }
      }, 0);
    }
  }, [pathname, hash, key]); // do this on route change


const logOut = useCallback(() => {
  dispatch(logout());
}, [dispatch]);
useEffect(() => {
  EventBus.on("logout", () => {
    logOut();
    navigate ("/");
  });
  
  
  return () => {
    EventBus.remove("logout");
  };
  }, [currentUser, logOut]);


useEffect(() => {
    let componentMounted = true;
    setSocket(io(process.env.REACT_APP_URL));
  
    return () => {
      componentMounted = false;
    }
  }, []);

useEffect(() => {
    let componentMounted = true;
    if (currentUser !== null && currentUser !== undefined) {
    socket?.emit('join', {...currentUser.user, room: "Global_user"}, socket.id, (error) => {
      if(error) {
        alert(error);
      }
    }

      ); 
}
return () => {
  componentMounted = false;
}
  }, [socket, currentUser]);


function openChat() {  
  setChatWindow(!chatWindow);
}

function leaveChat() {
  socket?.emit('leaveChat', {...currentUser?.user, room: "Global_user"}, socket.id, (error) => {
    if(error) {
      alert(error);
    }
  }
    );
}

function createChat() {
  if (chatWindow) {
    return (
      <Chat socket={socket} user={currentUser}/>
    ) 
  }

}

  return (
   
      <React.Fragment>
        
      <Wrapper>
      <div  className="backdrop"></div>
        <a href="#"  className="backtop"><FontAwesomeIcon  icon="fa-arrow-up"></FontAwesomeIcon></a>
       <Buscador socket={socket}  user={currentUser}/>
    
       <Routes>
        <Route  path="/preguntas" element={<QuestionRequest />} /> 
        <Route  path="/change/pass" element={<ChangePass />} /> 
        <Route  path="/favs" element={<Favorites />} /> 
        <Route path="/producto/:SKU" element={<Movie socket={socket} user={currentUser}/>} />
        <Route path="/Perfil/:id" element={<MyAccount user={currentUser}/>} />
        <Route path="/checkout" element={<CheckOut />} />
        <Route path="/order/:id" element={<Order socket={socket} user={currentUser}/>} />
        <Route exact path="/categories" element={<Categories />} />
        <Route path="/categories/sub/:id" element={<SubCategory />} />
        <Route path="/categories/sub/product/:id" element={<SearchCategory />} />
        <Route path="/categories/sub/product/:id/:brand" element={<SearchBrand />} />
        <Route path="/about" element={<About />} />
        <Route path="/faqs" element={<Faqs />} />
        <Route path="/help" element={<Help socket={socket} user={currentUser}/>} />
        <Route path="/privacy" element={<Privacy />} />
        <Route path="/section/:id"  element={<Section />} />
        <Route path="/delivery" element={<Delivery socket={socket} user={currentUser}/>} />
        <Route path="/payment" element={<PaymentSecure />} />
        <Route path="/return" element={<ReturnPolicy />} />
        <Route path="/support" element={<Support socket={socket} user={currentUser}/>} />
        <Route path="/invoice/:id" element={<Invoice socket={socket} user={currentUser}/>} />
        <Route path="/offer" element={<Offer />} />
        <Route path="/service" element={<Service />} />
        <Route path="/user/history" element={<OrderHistory socket={socket} user={currentUser}/>} />
        <Route path="/order/status" element={<OrderStatus socket={socket} user={currentUser}/>} />
        <Route path="/suggestion" element={<SuggestionBox socket={socket} user={currentUser}/>} />
        

        </Routes>
        

       
        <Routes> 
        <Route path="/" element={<Slider />} />
        </Routes>
        <Routes> 
        <Route index element={<Home />} />
        </Routes>
        <Footer/>
        <div> 
           <button onClick={() => openChat()} className= {chatWindow === false ?  "chat": "open-chat"  }  >  <FontAwesomeIcon  icon="fa-message"/></button> 
         {chatWindow ?  createChat() : leaveChat()}
         </div>
        </Wrapper>
    
      </React.Fragment>
     
      
  );
}

export default App;
