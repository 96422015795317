import React, { useState,  useEffect, useCallback} from "react";
import { useSelector, useDispatch } from "react-redux";
import {Modal} from 'bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../css/checkout.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { phoneRegExp } from "../../utils/validation";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import ingresa from  "../../img/ingresa.svg";
import { removeFromCart, Plus, Minus,setCheckoutStep} from "../../actions/index";
import {  Link, useNavigate } from "react-router-dom";
import CartPreview from "./CartPreview";
import LoadingSpinner from "../../utils/LoadingSpinner";



export function CheckOut ()  {
  
    const dispatch = useDispatch();
    const [shipment , setShipment] = useState("");
    const [haveCoupon, setHaveCoupon] = useState(false);
    const [coupon, setCoupon] = useState("");
    const [couponError, setCouponError] = useState("");
    const [couponSuccess, setCouponSuccess] = useState("");
    const { message } = useSelector((state) => state.message);
    const [currentUser, setCurrentUser] = useState({});
    const [addressMapUser, setAddress] = useState([]);
    const cartItem = useSelector((state) => state.publicActions.items);
    const [Order, setOrder] = useState([]);
    const  [step , setStep] = useState(0);
    const [totalProducts, setTotalProducts] = useState(0);

   
    useEffect(() => {
        if (currentUser) {
      UserService.getAddresses().then(response => {
              setAddress(response.data);
          }
      );
      const user = AuthService.getCurrentUser()

       setCurrentUser(user);
       
    
}
else {
    setCurrentUser(null);
}
  
}, []);



const cleanCouponIfProductChanged = useCallback(() => {
    if (totalProducts !== cartItem?.length) {
        setCoupon("");
        setCouponError("Tu cupón ha sido eliminado porque has modificado tu carrito de compras");
        setCouponSuccess("");
        setHaveCoupon(false);
    }
}, [ totalProducts, cartItem.length]);

useEffect(() => {
    const total = cartItem.reduce((acc, product) => acc + product?.quantity, 0);
    setTotalProducts(total);
    cleanCouponIfProductChanged();
}, [cartItem, cleanCouponIfProductChanged]);


const checkCoupon = (e) => {

        
    UserService.checkCoupon(e).then(response => {
        if(response.data){
            const coupon = response.data;
           
          
                const total = coupon.min_order;
                const productsTotal = cartItem.reduce((acc, product) => acc + product.price * product.quantity, 0);
                
                if(productsTotal > total && coupon.couponConstraint.length < 1) {
                    setCouponSuccess("Cupón aplicado correctamente");
                    setCouponError("");
                    setHaveCoupon(true);
                    setCoupon(response.data);
                }
                
                 if (productsTotal > total) {
                    
              if(coupon.couponConstraint[0]?.category) {
                const category = coupon.couponConstraint[0].category.name;
                const productsCategory = cartItem.filter((product) => product.category === category);
                if(productsCategory) {
                    setCouponSuccess("Cupón aplicado correctamente");
                    setCouponError("");
                    setHaveCoupon(true);
                    setCoupon(response.data);
                } 
                else {
                    setCouponError("El cupón no es válido para la categoría seleccionada");
                    setCouponSuccess("");
                    setCoupon(response.data);
                    

                }
            }  if (coupon.couponConstraint[0]?.product) {
                const product = coupon.couponConstraint[0].product_id;
                const productsId = cartItem.filter((productCheck) => productCheck.id === product);
                
                if(productsId.length > 0) {
                    setCouponSuccess("Cupón aplicado correctamente");
                    setCouponError("");
                    setHaveCoupon(true);
                    setCoupon(response.data);

                } else {
                    setCouponError("El cupón no es válido para los productos seleccionados");
                    setCouponSuccess("");
                    setCoupon(response.data);
                    

                }



            } 

            if(coupon.couponConstraint[0]?.subcategory) {
                const subcategory = coupon.couponConstraint[0].subcategory.name;
                const productsSubcategory = cartItem.filter((product) => product.subcategory === subcategory);
                if(productsSubcategory.length > 0) {
                    setCouponSuccess("Cupón aplicado correctamente");
                    setCouponError("");
                    setHaveCoupon(true);
                    setCoupon(response.data);
                } else {
                    setCouponError("El cupón no es válido para la subcategoría seleccionada");
                    setCouponSuccess("");
                    setCoupon(response.data);
                    

                }
            }

            if (coupon.couponConstraint[0].brand) {
                const brand = coupon.couponConstraint[0].brand.name;
                
                const productsBrand = cartItem.filter((product) =>   product.brand === brand);
                
                if(productsBrand.length > 0) {
                    setCouponSuccess("Cupón aplicado correctamente");
                    setCouponError("");
                    setHaveCoupon(true);
                    setCoupon(response.data);
                } else {
                    setCouponError("El cupón no es válido para la marca seleccionada");
                    setCouponSuccess("");
                    setCoupon(response.data);
                    

                }
            }
           
            
                }
                if (productsTotal < total) {
                  
                    setCouponError("El cupón no es válido para el total de la compra");
                    setCouponSuccess("");
                    setCoupon(response.data);
                }
                  

            if (coupon.message) {
                     
                        setCouponError(coupon.message);
                        setCouponSuccess("");
                    }

                    

                
            
        }
    }).catch(error => {
        setCoupon(error);
    })
    //
    


}



const navigate = useNavigate();
// formink address validation  ///      

const initialValues = {
    names: "",
    id: "", 
    address: "",
    phone: "",
    city: "",
    country: "",
    zipcode: "",
    email: "",
    shipment: "",
    terms: false,
};

const AddressSchema = Yup.object().shape({

  phone: Yup.string()
    
    .required("Número de teléfono requerido")
    .matches(phoneRegExp, "Número de teléfono inválido, se requieren 10 dígitos")
    .min(10, "Número de teléfono muy corto, se requieren 10 dígitos")
    .max(10, "Número de teléfono muy largo, se requieren 10 dígitos"),
    email:    Yup.string().email("Correo no valido").required("campo requerido"),
    names: Yup.string().min(7, "minimo 7 caracteres").max(50, "maximo 50 caracteres").required("campo requerido"),
    address: Yup.string().required("Nuúmero de domicilio y calle requerido"),
    city: Yup.string().required("La ciudad dónde vives es requerida"),
    country: Yup.string().required("El Estado dónde vives requerido"),
    zipcode: Yup.string().required("El código Postal es requerido!"),
    terms: Yup.boolean().oneOf([true], "Debes aceptar los términos y condiciones"),
    shipment: Yup.string().required("Selecciona una opción de envío"),
});

const AddressSchemaNew =
Yup.object().shape({
    names: Yup.string().min(7, "minimo 7 caracteres").max(50, "maximo 50 caracteres").required("campo requerido"),
    phone: Yup.string()
      .required("Número de teléfono requerido")
      .matches(phoneRegExp, "Número de teléfono inválido, se requieren 10 dígitos")
      .min(10, "Número de teléfono muy corto, se requieren 10 dígitos")
      .max(10, "Número de teléfono muy largo, se requieren 10 dígitos"),
      address: Yup.string().required("Nuúmero de domicilio y calle requerido"),
    city: Yup.string().required("La ciudad dónde vives es requerida"),
    country: Yup.string().required("El Estado dónde vives requerido"),
    zipcode: Yup.string().required("El código Postal es requerido!"),
    
  });
const onSubmitAddres = (data) => { 
 
  UserService.addAddresses(data).then(response => {
     
    window.location.reload();
      
        }, []);

          }
async function onSubmitEditAddress  (data, id)  {
    
            let newData =  {...data, id: id};
            UserService.updateAddress(newData).then(response => {
                UserService.getAddresses().then(response => {
                   
                    setAddress(response.data);
                    window.location.reload();
        
                }

            )}, []);
            }

const onSubmitDeleteAddress = (id) => {
let newData =  {del: id};
  UserService.deleteAddress(newData).then(response => {
      UserService.getAddresses().then(response => {
          setAddress(response.data);
          window.location.reload();
      }
  )}, []);
}

function removeItemCart(cartItem) {
  if (currentUser) { 
      UserService.deleteAllCartListUser({product_id:cartItem.id}).then(() => {

  dispatch(removeFromCart(cartItem));
  }) }
  else {
  dispatch(removeFromCart(cartItem));
}
  }
  function Min (cartItem) {
    if (currentUser && cartItem.quantity > 1) {
        UserService.deleteCartListUser({product_id:cartItem.id})
        .then(() => {
            dispatch(Minus(cartItem));
        })
        
        }   
    if (!currentUser) {
  dispatch(Minus(cartItem));
}
}
function setStepPurchase() {
    if (coupon && couponSuccess === "Cupón aplicado correctamente") {
    dispatch(setCheckoutStep([Order, cartItem, coupon.id ]));
    setStep(1);

  
  }

    else {
    dispatch(setCheckoutStep([Order, cartItem, 0 ]));
    setStep(1);
    }
    }

    
function PlusPlus (cartItem) {
    if (currentUser && cartItem.stock > cartItem.quantity) {
        
      UserService.addCartListUser({product_id:cartItem.id}).then(() => {
        dispatch(Plus(cartItem));
        }) }
      if (!currentUser && cartItem.stock > cartItem.quantity) {
    dispatch(Plus(cartItem));
  }}
///modal bootstrap // 

  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const [active , setActive] = useState("");
 
  const handleActive = (Event) => {
      setActive(Event.target.id);
     
    };
  
const  Subtotal = cartItem.reduce((acc, curr) => {
    if (curr.inOffer === false) {
        return acc + curr.price * curr.quantity
        }
        else {
          return acc + curr.offerPrice * curr.quantity
          }
          }, 0);


    
   
     let Total = cartItem.reduce((acc, curr) => { 
        if (curr.inOffer === false) {
          return acc + curr.price * curr.quantity
          }
          else {
            return acc + curr.offerPrice * curr.quantity
            }
            }, 0);
 
  


if (coupon && couponSuccess ==="Cupón aplicado correctamente" ) {
    Total = Total - coupon?.discount_value;
}

if (shipment === "express") {

 Total = Total + 300;


}

if (shipment === "normal") {
Total = Total + 200;
}



let RealTotal = Subtotal -(Subtotal * 0.10);
let TotalIVA = (Total*0.08).toFixed(2);
let TotalISR = (Total*0.01).toFixed(2);
let TotalICG  = (Total*0.01).toFixed(2);

    return (

        <div>
      <section className="inner-section single-banner" >
      <div className="container">
          <h2>checkout</h2>
          <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="/Favs">favoritos</Link></li>
              <li className="breadcrumb-item active" aria-current="page">carrito</li>
          </ol>
      </div>
  </section>

  <section className="inner-section checkout-part">
      <div className="container">
          <div className="row">
          {!currentUser && (
                    
              <div className="col-lg-12">
                  <div className="alert-info">
                  
                    
                    
                      <p>Cliente frecuente? <a href="/login" className="header-widget" title="My Account"><img src={ingresa} alt="user"/>Da Click aquí para iniciar sesión!</a></p>
                  </div>
              </div> )}



              <div className="col-lg-12">
                  <div className="account-card">
                      <div className="account-title">
                          <h4>Tu orden</h4>
                      </div>
                      <div className="account-content">
                          <div className="table-scroll">
                              <table className="table-list">
                                  <thead>
                                      <tr>
                                          
                                          <th scope="col">Producto</th>
                                          <th scope="col">Nombre</th>
                                          <th scope="col">Precio</th>
                                          <th scope="col">Marca</th>
                                          <th scope="col">Cantidad</th>
                                          <th scope="col">Subtotal</th>
                                          <th scope="col">Modificar</th>
                                      </tr>
                                  </thead>
                                  <tbody>
                                  {cartItem.map(item => (
                                      <tr key={item.SKU}>
                                          
                                          <td className="table-image"><img src={process.env.PUBLIC_URL + "/" + item.image} alt={item.SKU}/></td>
                                          <td className="table-name"><h6>{item.products}</h6></td>
                                          <td className="table-price">{item.inOffer ===true ? <h6>${item.offerPrice}<small>C/U</small></h6>: <h6>${item.price}<small>C/U</small></h6>}</td>
                                          <td className="table-brand"><h6>{item.brand}</h6></td>
                                          <td className="table-quantity"> <button className="action-minus" title="Quantity Minus"onClick={() => (Min(item))} ><i className="icofont-minus"><FontAwesomeIcon icon={['fas', 'fa-minus']}/></i></button>
                                          &nbsp; <span>&nbsp; &nbsp;{item.quantity}&nbsp;&nbsp;</span>&nbsp;
                                            <button className="action-plus" title="Quantity Plus"onClick={() => (PlusPlus(item))} ><i className="icofont-plus"><FontAwesomeIcon icon={['fas', 'fa-plus']}/></i></button>
                                          </td>
                                          <td className="table-total">{item.inOffer === true ? <h6>${item.offerPrice * item.quantity}</h6> : <h6> ${item.price * item.quantity}  </h6>} </td>
                                          <td className="table-action">
                                          <Link to={`/producto/${item.SKU}`}className="view"  title="Detalles">  <i className="fas fa-eye"> <FontAwesomeIcon  icon={['fas', 'fa-eye']}/></i> </Link>
                                            <a className="trash"  title="Quitar del carrito" onClick={() => removeItemCart(item)}><i className="icofont-trash"><FontAwesomeIcon  icon={['fas', 'fa-trash']}/></i></a>
                                          </td>
                                      </tr>
                                     ))} 
                                     </tbody>
                              </table>
                          </div>
                          <div className="chekout-coupon">
                                    <button className="coupon-btn" onClick= {()=> setHaveCoupon(!haveCoupon) }>¿Cuentas con un código de descuento?</button>
                                    {haveCoupon === true ? 
                                    <Formik
                                    initialValues={{ coupon: "" }}
                                    validationSchema={Yup.object({
                                        coupon: Yup.string()
                                        .required("Campo requerido")
                                        .min(5, "El código debe tener al menos 5 caracteres")
                                        .max(10, "El código debe tener máximo 10 caracteres")
                                    })}
                                    onSubmit={(values) => {
                                        checkCoupon(values);
                                    }}
                                    >

                                    <Form className="coupon-form">
                                        <Field type="text" placeholder="Ingresa aquí tu código" name="coupon" id="coupon"/>
                                        <ErrorMessage name="coupon" component="div" />
                                        <button type="submit"><span>Aplicar</span></button>
                                    </Form>
                                    </Formik>
                                    : null }
                                  
                                  
                                    <div>

                                    {couponSuccess ? <div className="alert alert-success">

                                        <p>¡{couponSuccess}!</p>
                                        </div> : null
                                       
                                        }

                                        {couponError ? <div className="alert alert-danger">
                                        
                                            <p>{couponError}</p>
                                            
                                    </div> : null }
                                        
                                
                                </div>
                            </div>
                        
                          <div className="checkout-charge">
                              <ul>
                                  <li>
                                      <span>Sub total</span>
                                      <span>${RealTotal}</span>
                                  </li>
                                  <li> 
                                    <span>Descuento</span>
                                    {couponSuccess ? coupon.code ? <span>$ -{coupon.discount_value}</span> : <span>$0</span> : <span>$0</span>}
                                  </li>
                                  <li>
                                      <span>IVA 8% </span>
                                      <span>${TotalIVA}</span>
                                      </li>  <li>
                                      <span>ISR 1% </span>
                                      <span>${TotalISR}</span>
                                      </li>  <li>
                                      <span>Impuesto cedular de Guanajuato 1%</span>
                                      <span>${TotalICG}</span>
                                      </li>  
                                  <li>
                                      <span>Costo de envio</span>
                                      {shipment === "express" ? <span>$300</span> 
                                        : shipment === "normal" ? <span>$200</span>
                                      : <span>$0</span>}
                                       
                                  </li>
                                 
                                  <li>
                                      <span>Total<small>(Incluye IVA)</small></span>
                                   
                                         <span>${Total}</span>
                                      
                                  </li>
                              </ul>
                            

                          </div>
                      </div>
                  </div>
              </div>


             {currentUser ? 
             <section >

                 <Formik  
        initialValues= {{
            names: "",
            address: "",
            phone: "",
            city: "",
            country: "",
            zipcode: ""
        }}
            
               

   
        onSubmit={(values) => {onSubmitAddres(values)}}
        validationSchema={AddressSchemaNew}
        >

<div className="modal fade"  id="address-add">
    <div className="modal-dialog modal-dialog-centered"> 
        <div className="modal-content">
            <button className="modal-close" data-bs-dismiss="modal" ><i className="icofont-close"><FontAwesomeIcon icon={['fas', 'fa-xmark']}/></i></button>
        
            <Form className="modal-form" >
                <div className="form-title">
                    <h3>Agregar nuevo domicilio de entrega</h3>
                </div>
                <label className="form-label">Nombres: </label>
                <Field name="names" type='text' placeholder='Nombre completo' className="form-control"/>
                <ErrorMessage name="names" component="span"  className="alert alert-danger"/>
                <br/>
               
                <label className="form-label">Telefono:</label>
                <Field name="phone" type='text' placeholder='Telefono' pattern="[0-9]{10}" maxLength="10" className="form-control"/>
                <ErrorMessage name="phone" component="span"  className="alert alert-danger"/>
                <br/>
                <label className="form-label">Domicilio:</label>
                 <Field name="address" component="textarea" rows="3"  placeholder='Domicilio' className="form-control" />   
                 <ErrorMessage name="address" component="span"  className="alert alert-danger"/>
                 <br/>
                    <label className="form-label">Estado:</label>
                    <Field as="select" className="form-select" name='country'>
<option disabled  > Elige uno ...</option>
<option>Aguascalientes</option>
<option>Baja California</option>
<option>Baja California Sur</option>
<option>Campeche</option>
<option>Chiapas</option>
<option>Chihuahua</option>
<option>Coahuila de Zaragoza</option>
<option>Colima</option>
<option>Distrito Federal</option>
<option>Durango</option>
<option>Guanajuato</option>
<option>Guerrero</option>
<option>Hidalgo</option>
<option>Jalisco</option>
<option>México</option>
<option>Michoacán de Ocampo</option>
<option>Morelos</option>
<option>Nayarit</option>
<option>Nuevo León</option>
<option>Oaxaca</option>
<option>Puebla</option>
<option>Querétaro</option>
<option>Quintana Roo</option>
<option>San Luis Potosí</option>
<option>Sinaloa</option>
<option>Sonora</option>
<option>Tabasco</option>
<option>Tamaulipas</option>
<option>Tlaxcala</option>
<option>Veracruz de Ignacio de la Llave</option>
<option>Yucatán</option>
<option>Zacatecas</option>
                      
                    </Field>
                    <ErrorMessage name="country" component="span"  className="alert alert-danger"/>
                    <label className="form-label">Ciudad: </label>
                <Field name="city" type='text' placeholder='Ciudad' className="form-control"/>
                <ErrorMessage name="city" component="span"  className="alert alert-danger"/>
                <br/>
                <div className="form-group">
                    <label className="form-label">Codigo postal: </label>
                <Field name="zipcode"  type="text" pattern="[0-9]{5}" maxLength="5" placeholder='Codigo postal' className="form-control"/>
                <ErrorMessage name="zipcode" component="span" className="alert alert-danger" />

                </div>
                
                <div className="form-group">
                    <button type="submit" className="form-btn"> Agregar </button>
                </div>
                

                </Form>
               
        </div> 
    </div> 
</div>
</Formik>
             <div> 
                <Formik
    initialValues={{
       
        names: "",
        id: "", 
        email: "",
        address: "",
        phone: "",
        city: "",
        country: "",
        zipcode: "",
        shipment: "",
        terms: false,

    }}
    onSubmit={(values) => {
        setOrder(values);
    }
    }
    validationSchema={ 
        Yup.object().shape({
            terms: Yup.boolean().oneOf([true], "Debes aceptar los términos y condiciones"),
            id: Yup.string().required( "Debes seleccionar una direccion de envio"),
    shipment: Yup.string().required("Selecciona una opción de envío")
    }) 
    }> 
     {({ values, setFieldValue }) => (
        <Form   className="form-register">
<div className="col-lg-12">
                  <div className="account-card">
                      <div className="account-title">
                          <h4>Método de entrega</h4>
                      </div>
                      <div className="account-content">
                      <ErrorMessage name="shipment" component="span"  className="alert alert-danger"/>
                          <div className="row-profile">
                              <div className="col-md-6 col-lg-4 alert fade show">
                                  <div className={`${"profile-card schedule express"  + (shipment === "express" ? " active": " ") } `} id="express" onClick={() => setShipment("express")}> 
                                  
                                      <h6>Envío Express</h6>
                                      <p>de 2 a 3 días hábiles</p>
                                      <Field name="shipment" type="radio" value={"Envio Express"} />
                                      
                                  </div>
                              </div>
                              <div className="col-md-6 col-lg-4 alert fade show">
                                  <div className={`${"profile-card schedule store"  + (shipment === "store" ? " active": "") } `}  onClick={() =>setShipment("store")} id="store">
                                      <h6>Recoger en tienda </h6>
                                      <p>de 8.00 AM - 10.00 PM</p>
                                      <Field name="shipment" type="radio"  value={"Recoger en Tienda"} />
                                  </div>
                              </div>
                              <div className="col-md-6 col-lg-4 alert fade show">
                                  <div className={`${"profile-card schedule normal"  + (shipment === "normal" ? " active": "") } `} onClick={() =>setShipment("normal")} id="normal">
                                      <h6>Envío Normal</h6>
                                      <p>de 5 a 7 días hábiles</p>
                                      <Field name="shipment" type="radio"  value={"Envio Normal"} />
                                  </div>
                              </div>
                          </div>
                      </div>
                    
                  </div>
                  <div className="col-lg-12">
                <div className="account-card">
                    <div className="account-title">
                        <h4>Domicilios de envío</h4>
                        <button data-bs-toggle="modal"  data-bs-target="#address-add" type="button" onClick={ ()=>handleShow}>Agregar Domicilio</button>
                    </div>
                    <div className="account-content">
                        <div className="row-profile">
                            
                               
                                    {addressMapUser.map((addressUser, index) => ( 
                                        
                                            <div className="col-md-6 col-lg-4 alert fade show" key={index}>
                                                <span  onClick={ ()=> setOrder({
                                            ...addressUser,
                                            email: currentUser.user.email,
                                      })}> 
                                                <ErrorMessage name="id" component="span"  className="alert alert-danger"/>
                                             <div className= {`${"profile-card address" + (active === `${index}-active` ? " active": "")} `}  key={index} id={`${index}-active`}  onClick={handleActive} >
                                             <h6>Domicilio de envio:</h6>
                                        <p>Nombres: <label>{addressUser.names}</label></p>
                                        <p>Teléfono:<label>{addressUser.phone}</label></p>
                                        <p>Dirección:<label>{addressUser.address}</label></p>
                                        <p>Ciudad:<label>{addressUser.city}</label></p>
                                        <p>Estado:<label>{addressUser.country}</label></p>
                                        <p>Código postal:<label>{addressUser.zipcode}</label></p>
               
                                        <ul className="user-action">
                                            <li><button className="edit icofont-edit" title="Editar" data-bs-toggle="modal" data-bs-target={`#address-edit-${addressUser.id}`} type="button" onClick={handleShow}> <FontAwesomeIcon icon={['fas', 'fa-edit']}/></button></li>
                                            <li><button className="trash icofont-ui-delete" title="Remove This" data-bs-dismiss="alert" onClick={()=> onSubmitDeleteAddress(addressUser.id)}  type="button"><FontAwesomeIcon icon={['fas', 'fa-trash']}/></button></li>
                                        </ul> 
                                       
                                        <Field  
                                        name="id"
                                        render = {({field}) => (
                                            <div>
                                                < input type="radio" {...field} value={addressUser.id} onClick={()=> {
                                                    setFieldValue('names', addressUser.names); 
                                                    setFieldValue('email', currentUser.user.email); 
                                                    setFieldValue('phone', addressUser.phone); 
                                                    setFieldValue('address', addressUser.address);
                                                    setFieldValue('city', addressUser.city); 
                                                    setFieldValue('country', addressUser.country); 
                                                    setFieldValue('zipcode', addressUser.zipcode);  
                                                   
                                                    } } />
                                                    
                                                <label htmlFor="id"> Usar esta dirección </label>
                                                
                                            </div>
                                        )}

                                         />
                      
                                            
                                        
                                        
                                        </div> </span></div> ))}   

</div>
                        
                    </div>
                </div>
            </div>
                  <div className="checkout-check">
                  
                             <Field name="terms" type="checkbox"  />
                             
                                <label htmlFor="checkout-check">Si realizas esta compra aceptas nuestros <a href="#">terminos y condiciones</a>.</label>
                                <ErrorMessage name="terms" component="span"  className="alert alert-danger"/>
                            </div>  


                 {Order?.terms  ?  null : <button className="form-btn" type="submit">Guardar</button>}

          </div>  
         
                  </Form>
        )}
                  </Formik>

                  <>
                 
                    <div key="adressUser" >
               {addressMapUser.map((addressUser, index) => ( 
                <div key={index}> 
                <Formik  
                initialValues= { {
                    
                    names: addressUser.names,
                    address: addressUser.address,
                    phone: addressUser.phone,
                    city: addressUser.city,
                    country: addressUser.country,
                    zipcode: addressUser.zipcode
                }}
                onSubmit={(values) => {onSubmitEditAddress(values,addressUser.id)}}
                validationSchema={AddressSchemaNew}>
                     
<div className="modal fade"   id={`address-edit-${addressUser.id}`}>
    <div className="modal-dialog modal-dialog-centered"> 
        <div className="modal-content"  key="address-edit" >
            <button className="modal-close" data-bs-dismiss="modal" onClick={handleClose}><FontAwesomeIcon icon={['fas', 'fa-xmark']}/><i className="icofont-close"></i></button>
          
            <Form className="modal-form">
          
                <div className="form-title">
                    <h3>Editar Dirección</h3>
                </div>
             
                <div className="form-group">
              

                <label className="form-label">Nombre y apellido de quien recibe</label>
                <Field name="names" type='text' defaultValue={addressUser.names}   className="form-control" />
                <ErrorMessage name="names" component="span"  className="alert alert-danger"/>
                <br/>
                
                <label className="form-label">Telefono</label>
                <Field name="phone" type='text' pattern="[0-9]{10}" maxLength="10" defaultValue={addressUser.phone}   className="form-control" />
                <ErrorMessage name="phone" component="span"  className="alert alert-danger"/>
                <br/>
               
                <label className="form-label">Domicilio</label>
                 <Field name="address" component="textarea" rows="3"   className="form-control" >   
                 {addressUser.address} 
                    </Field>
                 <ErrorMessage name="address" component="span"  className="alert alert-danger"/>
                 <br/>
                    <label className="form-label">Estado</label>
                    <Field as="select" className="form-select" name='country'>
                        
<option disabled  >{addressUser.country}</option>
<option>Aguascalientes</option>
<option>Baja California</option>
<option>Baja California Sur</option>
<option>Campeche</option>
<option>Chiapas</option>
<option>Chihuahua</option>
<option>Coahuila de Zaragoza</option>
<option>Colima</option>
<option>Distrito Federal</option>
<option>Durango</option>
<option>Guanajuato</option>
<option>Guerrero</option>
<option>Hidalgo</option>
<option>Jalisco</option>
<option>México</option>
<option>Michoacán de Ocampo</option>
<option>Morelos</option>
<option>Nayarit</option>
<option>Nuevo León</option>
<option>Oaxaca</option>
<option>Puebla</option>
<option>Querétaro</option>
<option>Quintana Roo</option>
<option>San Luis Potosí</option>
<option>Sinaloa</option>
<option>Sonora</option>
<option>Tabasco</option>
<option>Tamaulipas</option>
<option>Tlaxcala</option>
<option>Veracruz de Ignacio de la Llave</option>
<option>Yucatán</option>
<option>Zacatecas</option>
                      
                    </Field>
                    <label className="form-label">Ciudad</label>
                <Field name="city" type='text'  defaultValue={addressUser.city}  className="form-control"/>
                <ErrorMessage name="city" component="span"  className="alert alert-danger"/>
                <br/>
                    <label className="form-label">Codigo postal</label>
                <Field name="zipcode" type='text' pattern="[0-9]{5}" maxLength="5"  defaultValue={addressUser.zipcode}  className="form-control"/>
                <ErrorMessage name="zipcode" component="span" className="alert alert-danger" />
                </div> 
                    <div>
                    <br/>
                <button className="form-btn" type="submit"> Guardar domicilio </button> </div> 
            </Form>
        </div> 
    </div> 
   </div>
                    
                        
   </Formik>
   </div>
   
   ))}
</div>
   </>  


              </div>
              
              </section>
                    
           : null }
              

       {currentUser === null ? 


<Formik
    initialValues={{
        names: "",
        id: 0, 
        email: "",
        address: "",
        phone: "",
        city: "",
        country: "",
        zipcode: "",
        shipment: "",
        terms: false,

    }}
    onSubmit={(values) => {
        setOrder(values)
    }
    }
    validationSchema={AddressSchema}>
        <Form className="form-register">
<div className="col-lg-12">
                  <div className="account-card">
                      <div className="account-title">
                          <h4>Método de entrega</h4>
                      </div>
                      <div className="account-content">
                      <ErrorMessage name="shipment" component="span"  className="alert alert-danger"/>
                          <div className="row-profile">
                              <div className="col-md-6 col-lg-4 alert fade show">
                                  <div className={`${"profile-card schedule express"  + (shipment === "express" ? " active": " ") } `} id="express" onClick={ () => (handleActive, setShipment("express"))  }> 
                                      <h6>Envío Express</h6>
                                      <p>de 2 a 3 días hábiles</p>
                                      <Field name="shipment" type="radio" value={"express"} />
                                      
                                  </div>
                              </div>
                              <div className="col-md-6 col-lg-4 alert fade show">
                                  <div className={`${"profile-card schedule store"  + (shipment === "store" ? " active": "") } `}  onClick={() => (handleActive, setShipment("store"))} id="store">
                                      <h6>Recoger en tienda </h6>
                                      <p>de 8.00 AM - 10.00 PM</p>
                                      <Field name="shipment" type="radio"  value={"store"} />
                                  </div>
                              </div>
                              <div className="col-md-6 col-lg-4 alert fade show">
                                  <div className={`${"profile-card schedule normal"  + (shipment === "normal" ? " active": "") } `} onClick={() => (handleActive, setShipment("normal"))} id="normal">
                                      <h6>Envío Normal</h6>
                                      <p>de 5 a 7 días hábiles</p>
                                      <Field name="shipment" type="radio"  value={"normal"} />
                                  </div>
                              </div>
                          </div>
                      </div>
                    
                  </div>
                  <div className="account-card">
                    

                      <div className="account-title">
                      <h4>Domicilio de entrega</h4>
                      </div>
                  <div className="form-title">
                    <h3> </h3>
                </div>
                <label className="form-label">Nombres: </label>
                <Field name="names" type='text' placeholder='Nombre completo' className="form-control"/>
                <ErrorMessage name="names" component="span"  className="alert alert-danger"/>
                <br/>
               
                <label className="form-label">Telefono:</label>
                <Field name="phone" type='text' pattern="[0-9]{10}" maxLength="10" placeholder='Telefono' className="form-control"/>
                <ErrorMessage name="phone" component="span"  className="alert alert-danger"/>
                <br/>
                <label className="form-label">Correo:</label>
                <Field name="email" type='text' placeholder='Correo' className="form-control"/>
                <ErrorMessage name="email" component="span"  className="alert alert-danger"/>
                <br/>
                <label className="form-label">Domicilio:</label>
                 <Field name="address" component="textarea" rows="3"  placeholder='Domicilio'className="form-control" />   
                 <ErrorMessage name="address" component="span"  className="alert alert-danger"/>
                 <br/>
                    <label className="form-label">Estado:</label>
                    <Field as="select" className="form-select" name='country'>
<option disabled > Elige uno ...</option>
<option value='Aguascalientes'>Aguascalientes</option>
<option value='Baja California Norte'>Baja California Norte</option>
<option value='Baja California Sur'>Baja California Sur</option>
<option value='Campeche'>Campeche</option>
<option value='Chiapas'>Chiapas</option>
<option value='Chihuahua'>Chihuahua</option>
<option value='Ciudad de México'>Ciudad de México</option>
<option value='Coahuila'>Coahuila</option>
<option value='Colima'>Colima</option>
<option value='Durango'>Durango</option>
<option value='Estado de México'>Estado de México</option>
<option value='Guanajuato'>Guanajuato</option>
<option value='Guerrero'>Guerrero</option>
<option value='Hidalgo'>Hidalgo</option>
<option value='Jalisco'>Jalisco</option>
<option value='Michoacán'>Michoacán</option>
<option value='Morelos'>Morelos</option>
<option value='Nayarit'>Nayarit</option>
<option value='Nuevo León'>Nuevo León</option>
<option value='Oaxaca'>Oaxaca</option>
<option value='Puebla'>Puebla</option>
<option value='Querétaro'>Querétaro</option>
<option value='Quintana Roo'>Quintana Roo</option>
<option value='San Luis Potosí'>San Luis Potosí</option>
<option value='Sinaloa'>Sinaloa</option>
<option value='Sonora'>Sonora</option>
<option value='Tabasco'>Tabasco</option>
<option value='Tamaulipas'>Tamaulipas</option>
<option value='Tlaxcala'>Tlaxcala</option>
<option value='Veracruz'>Veracruz</option>
<option value='Yucatán'>Yucatán</option>
<option value='Zacatecas'>Zacatecas</option>



                      
                    </Field>
                    <label className="form-label">Ciudad</label>
                <Field name="city" type='text' placeholder='Ciudad' className="form-control"/>
                <ErrorMessage name="city" component="span"  className="alert alert-danger"/>
                <br/>
                    <label className="form-label">Codigo postal</label>
                <Field name="zipcode" type='text' pattern="[0-9]{5}" maxLength="5" placeholder='Codigo postal' className="form-control"/>
                <ErrorMessage name="zipcode" component="span" className="alert alert-danger" />
                  </div>
                  <div className="checkout-check">
                  
                             <Field name="terms" type="checkbox"  />
                             
                                <label htmlFor="checkout-check">Si realizas esta compra aceptas nuestros <Link to="/privacy">terminos y condiciones</Link>.</label>
                                <ErrorMessage name="terms" component="span"  className="alert alert-danger"/>
                            </div>  

                            {Order?.terms  ?  null : <button className="form-btn" type="submit">Guardar</button>}
          </div>  
         
                  </Form>
                  </Formik>
   
   
:
null
}

<br/>
<br/>
<br/>



    {Order?.terms  ?  <>
                            <div className="checkout-proced">
                            <button className="checkout-proced form-btn"  data-bs-toggle="modal"  data-bs-target="#modal-state-user" onClick={ ()=> {handleShow(); setStepPurchase()}} > <Link to="/checkout" className="btn btn-inline">proceder al pago</Link> </button>
                            </div> 
                            <div className="modal fade"   id="modal-state-user">
                            <div className="modal-dialog modal-dialog-centered"> 
                                <div className="modal-content"  key="modal-state-user" >
                                    <button className="modal-close" data-bs-dismiss="modal" onClick={handleClose}><FontAwesomeIcon icon={['fas', 'fa-xmark']}/><i className="icofont-close"></i></button>
                                    <div className="modal-form">
                                        
                                        {step === 1 ?  <CartPreview/> : <LoadingSpinner/>}
                                    
                                    </div>
                                    </div>
                                </div>
                            </div>
                            </> : null }
                            
    
 
    
    
    </div>
    </div>

   </section>
</div>
    ) }


    export default CheckOut