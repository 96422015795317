import React, { useEffect } from 'react';
import { useDispatch, useSelector } from "react-redux";
import { Link } from 'react-router-dom';
import { removeMovieFavorite} from "../../actions/index";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import '../../css/wishlist.css';
import {formatCurrency} from "../../utils/formatCurrency";
import {addToCart} from "../../actions/index";
import UserService from '../../services/user.service';

export function ConnectedList () {

    const { user: currentUser } = useSelector((state) => state.auth);
  const movies = useSelector((state) => state.publicActions.moviesFavourites);
    const dispatch = useDispatch();
    function addCart(movie) {
        dispatch(addToCart(movie));
        }

    const removeMovie = (movie) => {
        if (currentUser)
        UserService.deleteWishListUser({data: movie.id}).then(() => {
        dispatch(removeMovieFavorite(movie));}).catch(err => {
            console.log(err);
        } ); 
        else {
            dispatch(removeMovieFavorite(movie));
        }
    } ;
    
    
    return (
      <div key={movies}>
      <section className="inner-section single-banner" >
      <div className="container">
          <h2>Mi lista de productos deseados</h2>
          <ol className="breadcrumb">
              <li className="breadcrumb-item"><Link to="/">Home</Link></li>
              <li className="breadcrumb-item"><Link to="/checkout">Carrito</Link></li>
              <li className="breadcrumb-item active" aria-current="page">wishlist</li>
          </ol>
      </div>
</section>

<section className="inner-section wishlist-part">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="table-scroll">
                            <table className="table-list">
                                <thead>
                                    <tr>
                                        <th scope="col">Nombre</th>
                                        <th scope="col">Producto</th>
                                        
                                        <th scope="col">Precio</th>
                                        <th scope="col">Marca</th>
                                        <th scope="col">status</th>
                                        <th scope="col">Comprar</th>
                                        <th scope="col">Accion</th>
                                    </tr>
                                </thead>
                                
                                <tbody>
                             
        {
            movies.map(movie => (
             

                <tr key={movie.SKU + movie.id}>  

                                

                               
                                        <td className="table-name"><h6>&nbsp;{movie.products}&nbsp; </h6></td>
                                        <td className="table-image"><img src={process.env.PUBLIC_URL + "/" +movie.image} alt={movie.title} /></td>
                                        
                                        <td className="table-price"><h6>&nbsp;<small>c/u &nbsp;</small>{formatCurrency(movie.price)}</h6></td>
                                        <td className="table-brand"><br/><p>{movie.brand}</p><br/></td>
                                        <td className="table-status"><h6 className="stock-out">Stock:{movie.stock}</h6></td>
                                        <td className="table-shop">
                                            <button className="product-add" title="Add to Cart" onClick={() => addCart(movie)}><FontAwesomeIcon  icon={['fas', 'fa-shopping-basket']}/></button>
                                            <div className="product-action">
                            
                                            </div>
                                        </td>
                                        <td className="table-action">
                                           <Link to={`/producto/${movie.SKU}`}className="view"  title="Detalles">  <i className="fas fa-eye"> <FontAwesomeIcon  icon={['fas', 'fa-eye']}/></i> </Link>
                                            <a className="trash"  title="Quitar de Favoritos" onClick={() => removeMovie(movie)}><i className="icofont-trash"><FontAwesomeIcon  icon={['fas', 'fa-trash']}/></i></a>
                                        </td>
                                      
           

                                    </tr>
                                       
             
            ))

          }
          </tbody>    
           </table>
                        </div>
                    </div>
                </div>
                
            </div>
        </section>
          <div className="row">
                    <div className="col-lg-12">
                        <div className="text-center mt-5 margen-personalizado">
                            <button className="btn btn-outline">
                                Cargar mas
                            </button>
                        </div>
                    </div>
                </div>
     </div>
    );
  }






export default ConnectedList;



