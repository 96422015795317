import React, { useEffect, useState} from  'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {addMovieFavorite, addToCart} from "../../actions/index";
import {formatCurrency} from "../../utils/formatCurrency";
import { useDispatch, useSelector } from "react-redux";
import "../../css/blog-details.css";
import logo from  "../../img/isotipo.svg";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import {  Link, useNavigate } from "react-router-dom";
import OfferPreview from "./OfferPreview";
import LoadingSpinner from "../../utils/LoadingSpinner";
import {setCheckoutStep} from "../../actions/index";


export default function Offer() {

    const dispatch = useDispatch();
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(!show);
    const handleShow = () => setShow(!show);
    const [step , setStep] = useState(1);
    


const formSchema = Yup.object().shape({
    name: Yup.string().required("Campo requerido"),
    email: Yup.string().email("Email inválido").required("Campo requerido"),
    text: Yup.string().required("Campo requerido"),
    terms: Yup.boolean().oneOf([true], "Debes aceptar los términos y condiciones"),
    });

    
const initialValues = {
    name: "",
   text: "",
    email: "",
    shipment: "",
    terms: false,
};

const onSubmit = (values) => {
    
    dispatch(setCheckoutStep([values]))
};

function nextStep() {
    setStep(step + 1);
}

    return (
        <div>
          
            <section className="inner-section blog-details-part">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-lg-12 col-xl-10">
                        <article className="blog-details">
                        <h1>Oferta maceronu</h1>
                            <a className="blog-details-thumb" href="">
                                <img src={process.env.PUBLIC_URL + "/images/slider/1.jpeg"} alt="blog"/>
                            </a>
                            <div className="blog-details-content">
                                <ul className="blog-details-meta">
                                    <li>
                                        <i className="icofont-ui-calendar"> <FontAwesomeIcon icon={['fas', 'fa-calendar']}/></i>
                                        <span>Diciembre 02, 2022</span>
                                    </li>
                                    <li>
                                        <i className="icofont-user-alt-3"><FontAwesomeIcon icon={['fas', 'fa-tag']}/></i>
                                        <span>Maceronu</span>
                                    </li>
                                    <li>
                                        <i className="icofont-speech-comments"> <FontAwesomeIcon icon={['fas', 'fa-mobile']}/></i>
                                        <span> Solo 1000 reparaciones disponibles</span>
                                    </li>
                                    <li>
                                        <i className="icofont-share-boxed"><FontAwesomeIcon icon={['fas', 'fa-handshake-simple']}/></i>
                                        <span> 4 servicios ya comprado por nuestros clientes</span>
                                    </li>
                                </ul>
                                <h2 className="blog-details-title">Adipisicing elit. Earum beatae, eius voluptates lorem ipsum dolor sit amet consectetur.</h2>
                                <p className="blog-details-desc">Lorem, ipsum dolor sit amet consectetur adipisicing elit. Molestias repellendus laudantium at optio ex, magnam illo ducimus placeat voluptates officiis molestiae ratione assumenda dignissimos est id hic veritatis. Aspernatur aliquam praesentium officiis pariatur velit nesciunt debitis odio, dolores harum, quidem nobis enim. Incidunt quia doloremque ipsam optio repudiandae non ipsa.</p>
                                <p className="blog-details-desc">Lorem ipsum dolor sit, amet consectetur adipisicing elit. Voluptas est nisi ut eius quod? Libero, labore. Omnis maiores at, incidunt odit commodi, possimus voluptas porro sunt beatae laboriosam provident. Id, sit laborum accusantium quia ad aut eaque obcaecati eos, <a href="#">adipisci</a>  molestias quasi nostrum harum nihil, rerum totam doloribus perspiciatis quos reiciendis voluptatibus. Eos numquam eaque sed nemo illo optio quos facere, at alias! Doloremque nemo, porro neque magnam voluptatem harum at quidem. Porro asperiores facere veritatis consequatur. Minus amet error nam laudantium omnis modi molestias, ex eveniet sed non. Veniam quas aspernatur quisquam aut quis ea iusto, officia eos sunt vel, voluptatibus incidunt ut eius nesciunt perspiciatis modi reiciendis fugit alias autem, dolorum mollitia esse earum? Doloribus illum culpa eligendi!</p>
                                <blockquote className="blog-details-quote">
                                    <p>Adipisicing elit Qui ipsam natus aspernatur quaerat impedit eveniet ipsum dolor</p>
                                    <footer>- Maceronu</footer>
                                </blockquote>
                                <p className="blog-details-desc">Lorem ipsum dolor, sit amet consectetur adipisicing elit. Rerum eligendi asperiores id, doloribus quasi assumenda perspiciatis dicta vero quidem repellendus qui dolore et recusandae deserunt at perferendis dignissimos provident, similique quos dolor dolorem ducimus modi nisi quis? Hic error, officia, porro officiis explicabo consequatur magnam nostrum itaque minima expedita ex assumenda dolorum consequuntur molestias, blanditiis dignissimos vel quia. Aliquam neque, omnis culpa quaerat dolor quibusdam laborum consequuntur tenetur sit excepturi adipisci, ducimus molestiae exercitationem perspiciatis, nobis eum magnam explicabo.</p>
                                <div className="row blog-details-grid">
                                    <div className="col-md-6 col-lg-6">
                                    <img src={process.env.PUBLIC_URL + "/images/slider/2.jpeg"} alt="blog"/>
                                    </div>
                                    <div className="col-md-6 col-lg-6 ">
                                        <p className='image-shadow'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Odit veniam quam natus! Dignissimos, sed. Sint enim perferendis illum rem natus commodi dolorum, nobis, temporibus minima dolores numquam ab nemo aspernatur placeat vitae assumenda? Provident cumque aliquid cupiditate nam libero vero praesentium tempora. Nostrum vero ipsa soluta minus facilis doloremque quis.</p>
                                    </div>
                                </div>
                                <div className="blog-details-subtitle">
                                    <h3>Lorem ipsum dolor sit amet consectetur</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.</p>
                                </div>
                                <div className="blog-details-subtitle">
                                    <h3>Aboris nisi ut aliquip commodo consequat</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia.</p>
                                </div>
                                <a className="blog-details-thumb mb-4" href="">
                                <img src={process.env.PUBLIC_URL + "/images/slider/3.jpeg"} alt="blog"/>
                                </a>
                                <ul className="blog-details-list">
                                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi error ex placeat repudiandae et ut modi non libero mollitia velit. Dolor consequuntur quo error explicabo? Officiis vitae sed officia autem harum sequi pariatur corporis fugit suscipit? Laboriosam animi voluptatum quibusdam voluptate expedita corrupti natus rerum, quaerat velit, voluptates saepe quasi.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi error ex placeat repudiandae et ut modi non libero mollitia velit. Dolor consequuntur quo error explicabo? Officiis vitae sed officia autem harum sequi pariatur corporis fugit <a href="#">suscipit?</a> Laboriosam animi voluptatum quibusdam voluptate expedita corrupti natus rerum, quaerat velit, voluptates saepe quasi.</li>
                                    <li>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Sequi error ex placeat repudiandae et ut modi non libero mollitia velit. Dolor consequuntur quo error explicabo? Officiis vitae sed officia autem harum sequi pariatur corporis fugit suscipit? Laboriosam animi voluptatum quibusdam voluptate expedita corrupti natus rerum, quaerat velit, voluptates saepe quasi.</li>
                                </ul>
                                <div className="blog-details-footer">
                                    <ul className="blog-details-share">
                                        <li><span>compartir:</span></li>
                                        <li><a target="_blank" href={`https://www.facebook.com/sharer/sharer.php?u=www.maceronu.com/offer`}  className="icofont-facebook" title="Facebook"><FontAwesomeIcon icon={['fab', 'fa-facebook']}/></a></li>
                                            <li><a target="_blank" href={`https://twitter.com/intent/tweet?text=www.maceronu.com/offer`} className="icofont-twitter" title="Twitter"><FontAwesomeIcon icon={['fab', 'fa-twitter']}/></a></li>
                                            <li><a target="_blank" href={`https://www.linkedin.com/shareArticle?mini=true&url=www.maceronu.com/offer&title=La%20mejor%20tienda&summary=Compra%20Ahora%20!&source=www.maceronu.com/offer`} className="icofont-linkedin" title="Linkedin"><FontAwesomeIcon icon={['fab', 'fa-linkedin']}/></a></li>
                                            <li><a target="_blank" href={`https://pinterest.com/pin/create/button/?url=www.maceronu.com/offer&media=https%3A//drive.google.com/file/d/1pK9lbRURwV-kJ3dmGakkw5SUW5E-YmgO/view?usp=sharing&description=Compra%20Ahora%20!`} className="icofont-instagram" title="Instagram"><FontAwesomeIcon icon={['fab', 'fa-instagram']}/></a></li>
                                    </ul>
                                    <ul className="blog-details-tag">
                                        <li><span>Etiquetas:</span></li>
                                        <li><a href="#">Celulares</a></li>
                                        <li><a href="#">Accesorios</a></li>
                                        <li><a href="#">Refacciones</a></li>
                                    </ul>
                                </div>
                            </div>
                        </article>
                        <Formik initialValues={initialValues} validationSchema={formSchema} onSubmit={(values) => {onSubmit(values); nextStep()} }>
                        

                        <Form className="blog-details-comment">
                            <h3 className="blog-details-comment-title">Haz tu pedido</h3>
                            <div className="row">
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Field type="text" name="name"  className="form-control" placeholder="Nombre completo"/>
                                        <ErrorMessage name="name" component="span"  className="alert alert-danger"/>
                                    </div>
                                </div>
                                <div className="col-lg-6">
                                    <div className="form-group">
                                        <Field type="email" name="email"  className="form-control" placeholder="Tu correo"/>
                                        <ErrorMessage name="email" component="span"  className="alert alert-danger"/>
                                    </div>
                                </div>
                                <div className="col-lg-12">
                                    <div className="form-group">
                                        <Field component="textarea" rows="3" name='text' className="form-control" placeholder="Equipo a reparar y detalles"/>
                                        <ErrorMessage name="text" component="span"  className="alert alert-danger"/>
                                    </div>
                                    <div className="checkout-check">
                  
                  <Field name="terms" type="checkbox"  />
                  
                     <label htmlFor="checkout-check">Si realizas esta compra aceptas nuestros <Link to="/privacy">terminos y condiciones</Link>.</label>
                     <ErrorMessage name="terms" component="span"  className="alert alert-danger"/>
                 </div>  
                                </div>
                                <div className="details-add-group">
                          
                                    
                                { step === 2 ?  
                                        <button className="product-add" title="Add to Cart"  data-bs-toggle="modal"  data-bs-target="#modal-state-user" onClick={ ()=> {handleShow(); nextStep();}}>
                                            <i className="fas fa-shopping-basket"><FontAwesomeIcon icon={['fas', 'fa-shopping-basket']}/></i>
                                            <span>Comprar Ahora</span>
                                        </button>
                                        : <button type="submit" className="product-save btn "><i></i>Guardar</button> }
                                      
                                    </div>
                                
                            </div>
                        </Form>
                        </Formik>


                        <div className="blog-details-profile">
                            <a href=""><img src={logo} alt="avatar"/></a>
                            <h3>Maceronu</h3>
                            <h4>soporte@maceronu.com</h4>
                            <ul>
                            <li><a className="icofont-facebook" href="https://www.facebook.com/WorldCellT"><FontAwesomeIcon  icon={['fab', 'fa-facebook-f']} target="_blank" rel="noreferrer" /></a></li>
                                <li><a className="icofont-twitter" href="https://twitter.com/WorldCell10"><FontAwesomeIcon  icon={['fab', 'fa-twitter']} target="_blank"/></a></li>
                                <li><a className="icofont-google" href="https://www.google.com/search?q=maceronu&oq=maceronu&aqs=edge..69i57j0i10l4j69i60j69i61l3.735j0j4&sourceid=chrome&ie=UTF-8&newwindow=1&tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=ALiCzsbYGGD9LBXKCp6arR7SM7cPI7hCNQ:1661376742989&rflfq=1&num=10&rldimm=10441443659896202324&lqi=CghtYWNlcm9udVoKIghtYWNlcm9udZIBBXN0b3Jl&ved=2ahUKEwivierUtuD5AhXzKkQIHeVUAYsQvS56BAgREAE&sa=X&rlst=f#rlfi=hd:;si:10441443659896202324,l,CghtYWNlcm9udVoKIghtYWNlcm9udZIBBXN0b3Jl;mv:[[21.157379,-101.680596],[21.1223802,-101.68202319999999]];tbs:lrf:!1m4!1u2!2m2!2m1!1e1!2m1!1e2!3sIAE,lf:1,lf_ui:2" 
                                rel="noreferrer" target="_blank">
                                    <FontAwesomeIcon  icon={['fab', 'fa-google']} /></a></li>
                                <li><a className="icofont-instagram" href="https://www.instagram.com/maceronu_/" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fab', 'fa-instagram']} /></a></li>
                                <li><a className="icofont-ML" href="https://worldcellmx.mercadoshops.com.mx/" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fas', 'fa-basket-shopping']} /></a></li>
                                <li><a className="icofont-tiktok" href="https://www.tiktok.com/@maceronu" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fab', 'fa-tiktok']} /></a></li>
                            </ul>
                          
                        </div>
                        </div>


           
        </div>
        
    </div>
    <div className="modal fade"   id="modal-state-user">
    <div className="modal-dialog modal-dialog-centered"> 
        <div className="modal-content"  key="modal-state-user" >
            <button className="modal-close" data-bs-dismiss="modal" onClick={() => {handleClose();}}><FontAwesomeIcon icon={['fas', 'fa-xmark']}/><i className="icofont-close"></i></button>
            <div className="modal-form">
                
                {step >= 3 ?  <OfferPreview/> : <LoadingSpinner/>}
            
            </div>
            </div>
        </div>
    </div>
    </section>
    </div>
    

    );
}


