
import { ADD_TO_CART,
          REMOVE_FROM_CART,
          CLEAR_CART,
          PLUS,
          MINUS,
          ADD_MOVIE_FAVORITE,
          REMOVE_MOVIE_FAVORITE,
          GET_SEARCH,
          GET_RECO,
          GET_MOVIE_DETAIL,
          GET_MOVIE_FAVORITE,
          GET_MAS_VENDIDOS,
          GET_NUEVOS_P,
          GET_RECOMENDADOS,
        PURCHARSE,
        PURCHARSE_SUCCESS,
        SET_SHIPPING_ADDRESS,
        SET_CHECKOUT_STEP,
        GET_SECTION,
        GET_CATEGORY
      } from "../actions/type";

 
const initialState = {
  moviesRe: [],
    moviesFavourites: [],
    moviesLoaded: [],
    movieDetail : {},
    moviesFavs: [],
    MasVendidosobj: [],
    NuevosPobj: [],
    Recomendadosobj: [],
    items: [],
    purcharse: [],
    section : [],
    category: [],
    Reco: [],

  };

export default function (state = initialState, action){

const { type, payload } = action;

switch (type) {
  
  case ADD_TO_CART:
    const quantity = {quantity: 1};
    const id = payload.cartItem.SKU;
    const isOld = state.items.map((item) => item.SKU).includes(id);
    let cartItems = null;
   

    if (isOld) {
      const items = state.items.map((item) => {
        
        if (item.SKU === id) {
          return {
            ...item,
            quantity: item.quantity + 1
          };
        }
        return item;
      });
      cartItems = [...items];
    } 
    
    
    else {
    
      cartItems = [...state.items, payload.cartItem,];
      cartItems[cartItems.length - 1] = {...cartItems[cartItems.length - 1], ...quantity};      
    }

    return {
      ...state,
      items: cartItems
      
    };
  case REMOVE_FROM_CART:
    return {
      ...state,
      items: state.items.filter(
        (item) => item.SKU !== payload.SKU
      )
    };
  case CLEAR_CART:
    return {
      ...state,
      ...initialState
    };

    case PLUS:
      
      const items = state.items.map((item) => {
        if (item.SKU === payload.cartItem.SKU) {
          return {
            ...item,
            quantity: item.quantity + 1
          };
        }
        return item;
      });
      return {
        ...state,
        items
      };
    case MINUS:
      const items2 = state.items.map((item) => {
        if (item.SKU === payload.cartItem.SKU) {
          return {
            ...item,
            quantity: item.quantity - 1
          };
        }
        return item;
      });
      return {
        ...state,
        items: items2
      };   

      case ADD_MOVIE_FAVORITE:
  
        if (state.moviesFavourites.find(movie => movie.SKU === payload.SKU)) {
          return state;
        }
        else
          return {
              ...state,
            moviesFavourites: state.moviesFavourites.concat(payload)
          };
      
      case GET_SEARCH: 
      
          return {
            ...state,
            moviesLoaded: payload
          };
          case GET_RECO: 
      
          return {
            ...state,
            Reco: payload
          };
      
      case GET_MOVIE_DETAIL: 
          return {
            ...state,
            movieDetail: payload 
  
          };
          
      
     case REMOVE_MOVIE_FAVORITE:
          return {
            ...state,
            moviesFavourites: state.moviesFavourites.filter(movie => movie.SKU !== payload.SKU)
          };
        
       case GET_MOVIE_FAVORITE:
          return {
            ...state,
            moviesFavs: payload
          };
      
      
      case GET_MAS_VENDIDOS:
        return {
          ...state,
          MasVendidosobj: payload
        };
    
case GET_NUEVOS_P:
      return {
        ...state,
        NuevosPobj: payload
      };
  
  case  GET_RECOMENDADOS:
    return {
      ...state,
      Recomendadosobj: payload
    };
  
case SET_CHECKOUT_STEP :
  return {
    ...state,
    purcharse: payload
  };

case PURCHARSE:
  return {
    ...state,
    purcharse: payload
  };
case PURCHARSE_SUCCESS:
  return {
    ...state,
    purcharse: payload
  };
  
  case SET_SHIPPING_ADDRESS:
    return {
      ...state,
      purcharse: payload
    };
  case GET_SECTION: 
    return {  
      ...state,
      section: payload
    };
  case GET_CATEGORY:
    return {
      ...state,
      category: payload
    };
    
      default:
        
      return state;
    }
   

  }