import React, { useContext, useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch, useSelector } from "react-redux";
import '../../css/user-auth.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useNavigate } from "react-router-dom";
import { AuthContext } from "../../utils/AuthContext";
import { Forgot } from "../../actions/auth";
/* import FacebookLogin from '@greatsumini/react-facebook-login';*/

export function ResetForgot() {
 
  const [loading, setLoading] = useState(false);
    const { isLoggedIn } = useSelector(state => state.auth);
    const { message } = useSelector(state => state.message);
    const dispatch = useDispatch();
    const navigate = useNavigate();

   
 
    const initialValues = {
        email: "",
     
      };
      
     
    const loginState = (data) => {
        setLoading(true);
       
      dispatch(Forgot(data.email))
        .then(res => {
            setLoading(false);
        
        }).catch(err => {
            setLoading(false);
          
        }
        );
    }
    

        

    return (
<div>

    <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
            loginState(values)}}
        
        validationSchema={Yup.object().shape({
            email: Yup.string().email("Debe ser un correro valido").required("Campo requerido"),
            
        })}>

<section className="user-form-part">
            <div className="container">
                <div className="row justify-content-center">
                    <div className="col-12 col-sm-10 col-md-12 col-lg-12 col-xl-10">
                        <div className="user-form-logo">
                            <a href="/"><img src="images/logotipo2.svg" alt="logo"/></a>
                        </div>
                        <div className="user-form-card">
                            <div className="user-form-title">
                                <h2>¿Preocupado?</h2>
                                <p>Descuida!, sólo sigue las instrucciones que te enviaremos al correo que has registrado</p>
                            </div>
                           
                         
                                 <Form className="user-form">
                               
                                    <div className="form-group">
                                    <Field name="email" type="text" className="form-control" placeholder="Ingresa tu correo"/>
                                       <ErrorMessage name="email" component="div" />
                                    </div>
                                   
                                    <div className="form-check mb-3">
                                    {message ?  
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                <span>{message} </span>
              </div> 
            </div>
            : null}

                                    </div>
                                    <div className="form-button">
                                        <button type="submit">Restablecer contraseña</button>
                                        {loading && (
                <span className="spinner-border spinner-border-sm"></span>
              )}
                                       
                                    </div>
                                </Form>
                            </div>
                        </div>
                        <div className="user-form-remind">
                            <p>¿Aún no tienes una cuenta?<a href="/Reg">Regístrate aquí</a></p>
                        </div>
                        </div>
                        </div>
                  
        
        </section>


    
       
    </Formik>
</div>
    );
}

 export default ResetForgot;
