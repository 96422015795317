
import { useEffect, useState, useContext} from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import UserService from "../../services/user.service";
import AuthService from "../../services/auth.service";
import { ItemContext } from "./Movie";



export default function Question () {
    
    const navigate = useNavigate();
   const [currentUser, setCurrentUser] = useState(null);
    const { item, socket } = useContext(ItemContext);
    const [itemQuestion, setItemQuestion] = useState(0);
    const [itemQuestionCount, setItemQuestionCount] = useState(0);
    const [page, setPage] = useState(3);
    const [pageAnswer, setPageAnswer] = useState(2);
    const [HasMore, setHasMore] = useState(true);
    const [HasMoreAnswer, setHasMoreAnswer] = useState(true);
    
    useEffect (() => {
        let mounted = true;
        const user = AuthService.getCurrentUser();
       
        if (user) {
            setCurrentUser(user);
        }
        return () => mounted = false;
    }, []);

    useEffect (() => { 
        let mounted = true;
        
        if (item) {
            
        UserService.getQuestion(item.id, page, pageAnswer).then(
            (response) => { 
                setItemQuestion( response.data);
                setItemQuestionCount(response.data.count);
                setHasMore( response.data.questions.length < response.data.count);     
            }); 

        }
     
       
        return () => mounted = false;
    }, [item, itemQuestionCount, socket, pageAnswer, page]);

 

   

  const viewMore = () => {
    setPage(page + 3);
    
    };
const viewMoreAnswer = () => {
    setPageAnswer(pageAnswer + 2);
};


   const questionHandler = (values) => { 
     

        if (item) {
        const product_id = item.id;
        const user_id = currentUser?.user.id
        const question = values.question

        UserService.addQuestion({product_id, user_id, question}).then(
            (response) => {
            setItemQuestion((prev) => { return { ...prev, questions: [response.data, ...prev.questions] } });
            setItemQuestionCount (itemQuestionCount + 1)
            },
            (error) => {
                const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
             
               
            }
        ).then (
            () => { 

                socket?.emit('globalNotificationAdmin', {

                    id: currentUser.user.id,
                    room: "Global_admin",
                    user_sender_id: currentUser.user.id,
                    role: currentUser.user.role,
                    message: 'Existe una nueva pregunta para el producto ' + item.products + ' efectuado por ' + currentUser.user.username + ' ' + currentUser.user.lastname, 
                    type: "Global_Notification_Admin",
                }, (error) => {
                    if(error) {
                        alert(error);
                    }}
                           );
            });
           
                 }};

const answerHandler = (values, id) => { 
   
    if (itemQuestion) {
    const question_id = id;
    const user_id = currentUser.user.id
    const answer = values.answer
    UserService.addAnswer({question_id, user_id, answer}).then(
        (response) => {
            setItemQuestion((prev) => { return { ...prev, questions: prev.questions.map((question) => { 
                if (question.id === id) {
                    return { ...question, answers: [response.data, ...question.answers] }
                    } else {
                        return question
                        };
                    }) } });
        setItemQuestionCount (itemQuestionCount + 1)
        },

        (error) => {
            const resMessage = (error.response && error.response.data && error.response.data.message) || error.message || error.toString();
        }
    );
}
}



    return (
        <div id="question">
        {itemQuestionCount > 0 && ( < >
            <div className="product-details-frame"> 
                <h3 className="frame-title">Preguntas ({itemQuestionCount})</h3>
                <ul className="review-list">
                    {itemQuestion.questions.map((itemQuestion, index ) => (
                        <li key={itemQuestion.id+index} className="review-item" >
                    
                        <div className="review-media">
                            <a className="review-avatar" href="#">
                                <img src={process.env.PUBLIC_URL + "/images/user.png"} alt="review"/>
                            </a>
                            <h5 className="review-meta">
                                <a href="#">{ currentUser ? itemQuestion?.user_id === currentUser?.user?.id ? "Tú" : itemQuestion.Users?.user : itemQuestion.Users?.user }</a>
                                <span> {new Date(itemQuestion.createdAt).toLocaleString()} </span>
                            </h5>
                        </div>
                       
                        <p className="review-desc">{itemQuestion.question}</p>

                          
                          
                          {currentUser ? 1 === currentUser?.user?.id ?  
                        <Formik initialValues={{ answer: ''}}
                        validationSchema={Yup.object({
                            answer: Yup.string().required('Requerido'),
                        })}
                        onSubmit= { (values) => answerHandler(values, itemQuestion.id) }>

                        <Form className="review-reply">
                            <Field name="answer" type="text" className="form-control" placeholder="Escribe tu respuesta" />
                             
                            <button type="submit"><i className="icofont-reply" ></i>Responder</button>
                        </Form>
                    </Formik>
                    : null : null}


                   
                        <ul className="review-reply-list">
                        {itemQuestion.answers ? itemQuestion.answers.length > 0 && (  <h6> Respuestas: </h6> ) : null}  
                                
                            {itemQuestion.answers? itemQuestion.answers.map((Answer, index) => (  
                                <li key={Answer.id+index} className="review-reply-item">
                                
                                <div className="review-media">
                                    <a className="review-avatar" href="#"> 
                                        <img src={process.env.PUBLIC_URL + "/images/user.png"} alt="review"/>
                                    </a>
                                    <h5 className="review-meta">
                                        <a href="#">{ currentUser ? Answer?.user_id === currentUser?.user.id ? "Tú" : Answer?.Users?.user : Answer?.Users?.user}</a>
                                        <span>
                                           
                                            {new Date(Answer.createdAt).toLocaleString()}</span>
                                    </h5>
                                </div>
                                <p className="review-desc">{Answer.answer}</p>
                                
                            </li> )) : null}
                            {HasMoreAnswer === true ?  <button onClick={viewMoreAnswer}>Ver mas..</button> : null}
                        </ul>
                        
                    </li>
                    ))}
                    {HasMore === true ? <button onClick={viewMore}>Ver mas..</button> : null}
                   
                </ul>
            </div>
            <div className="product-details-frame">
                <h3 className="frame-title">Hacer una pregunta</h3>
                <Formik initialValues={{ question: '' }}
                    onSubmit= { (values,  {resetForm}) => {
                        questionHandler(values);
                        resetForm({values: ''});
                    }}>
           
                        <Form className="review-form">
                    
                    <div className="row">
                        <div className="col-lg-12">
                         
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                            <Field name="question" as="textarea" className="form-control" placeholder="Escribe tu pregunta"/>
                            </div>
                        </div>
                       
                        <div className="col-lg-12">
                            <button className="btn btn-inline" type="submit">
                                <i className="icofont-water-drop"></i>
                                <span>Preguntar</span>
                            </button>
                        </div>
                    </div>
                </Form>
                </Formik> 
            </div>
           </> )} 
           {itemQuestionCount === 0 && ( 
            <>
             
              <div className="product-details-frame">
              <h3 className="frame-title">Preguntas ({itemQuestionCount})</h3>
                <h3 className="frame-title">Se el primero en preguntar!  </h3>
                <Formik initialValues={{ question: '' }}
                    onSubmit= { (values, {resetForm}) => {questionHandler(values); 
                        resetForm({ values: '' });
                    }
                    }
                    validationSchema={Yup.object({
                        question: Yup.string().required('Requerido'),
                    })}>
           
                    
                
           
                        <Form className="review-form">
                    
                    <div className="row">
                        <div className="col-lg-12">
                         
                        </div>
                        <div className="col-lg-12">
                            <div className="form-group">
                                <Field name="question" as="textarea" className="form-control" placeholder="Escribe tu pregunta"/>
                                
                            </div>
                        </div>
                       
                        <div className="col-lg-12">
                            <button className="btn btn-inline">
                                <i className="icofont-water-drop"></i>
                                {currentUser  ?  <span>Preguntar</span> : <span onClick={()=> (navigate("/login"))}>Inicia sesion para preguntar</span>  }
                               
                            </button>
                        </div>
                    </div>
                </Form>
                </Formik> 
            </div>
            </>
              )}


    </div>
    )}