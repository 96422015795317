export default function Order() {
   
    return (

        <section class="user-form-part">
            <div class="container">
                <div class="row justify-content-center">
                    <div class="col-12 col-sm-10 col-md-12 col-lg-12 col-xl-10">
                        <div class="user-form-logo">
                            <a href="index.html"><img src="images/logo.png" alt="logo"/></a>
                        </div>
                        <div class="user-form-card">
                            <div class="user-form-title">
                                <h2>Solicitar factura</h2>
                                <p>Por favor llena estos datos para poder generar tu factura, recuerda que esto puede demorar de 5 a 7 dias habiles</p>
                            </div>
                            <div class="user-form-group">
                               
                
                                <form class="user-form">
                                    <div class="form-group">
                                        <input type="text" class="form-control" placeholder="Nombre a facturar"/>
                                    </div>
                                    <div class="form-group">
                                        <input type="email" class="form-control" placeholder="RFC"/>
                                    </div>
                                    <div class="form-group">
                                        <h6>Direccion fiscal</h6> 
                                        <input type="password" class="form-control" placeholder="Calle"/>
                                        <input type="password" class="form-control" placeholder="Numero"/>
                                        <input type="password" class="form-control" placeholder="Colonia"/>
                                        <input type="password" class="form-control" placeholder="Estado"/>
                                        <input type="password" class="form-control" placeholder="Municio"/>
                                        <input type="password" class="form-control" placeholder="Codigo postal"/>
                                        <input type="password" class="form-control" placeholder="Telefono"/>
                                        <input type="password" class="form-control" placeholder="Correo electronico"/>
                                    </div>

                                    <lablel>Nombre de producto </lablel>
                                  <label>Cantidad
                                  </label>  
                                   <label> Precio unitario</label>
                                   <label>  Importe </label>


                                        
                                
                                    <div class="form-check mb-3">
                                        <input class="form-check-input" type="checkbox" value="" id="check"/>
                                        <label class="form-check-label" for="check">Al solicitar tu factura aceptas nuestros <a href="#">Terminos y Condiciones</a></label>
                                    </div>
                                    <div class="form-button">
                                        <button type="submit">Enviar datos</button>
                                    </div>
                                </form>
                            </div>
                        </div>
                        <div class="user-form-remind">
                            <p>Tienes cuenta?<a href="login.html">Ingresa aqui</a></p>
                        </div>
                        <div class="user-form-footer">
                            <p>Maceronu | &COPY; Todos los derechos recervados por <a href="#">Maceronu</a></p>
                        </div>
                    </div>
                </div>
            </div>
        </section>

    )};

