import React, {useLayoutEffect, useState, useEffect}  from 'react';
import { NavLink, Link } from 'react-router-dom';
import AuthService from "../../services/auth.service";
import '../../css/index.css';
import Move from "../../components/Move.js";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import "../../css/orderlist.css";







export  function NavBar() {

    const [currentUser, setCurrentUser] = useState(null);
    const [category, setCategory] = useState([]);
    const [subcategory, setSubcategory] = useState([]);
    const API = process.env.REACT_APP_URL;


useLayoutEffect (() => {
   
    fetch(API + '/categories/')
    .then(res => res.json())
    .then(data => {
        setCategory(data);
    }).catch();
    fetch(API + '/categories/sub/')
    .then(res => res.json())
    .then(data => {
        setSubcategory(data);
    }
    ).catch();
} , []);

useEffect (() => {
    const user = AuthService.getCurrentUser();
    
    if (user) {
        setCurrentUser(user);
    }
    else {
        setCurrentUser(null);
    }
}, []);


    return (
        <div> 
            <nav className="navbar-part">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="navbar-content">
                            <ul className="navbar-list">
                                
                                
                                <li className="navbar-item dropdown-megamenu">
                                    <NavLink className="navbar-link dropdown-arrow" to="/categories">Categorías <Move x={5} rotation={90} timing={1000}><FontAwesomeIcon icon={['fas', 'fa-angles-right']}/> </Move></NavLink>
                                    <div className="megamenu">
                                        <div className="container megamenu-scroll">
                                            <div className="row row-cols-5">
                                               
                                                        
                                                        {category.map((categories) => (
                                                            <div className="col" key={categories.name}>
                                                            <div className="megamenu-wrap">
                                                        <h5 className="megamenu-title">{categories.name}</h5>
                                                        <ul className="megamenu-list">
                                                            {
                                                                subcategory.map( (subcategories) => {
                                                                    if (subcategories.category_id === categories.id) {
                                                                        return (
                                                                            <li key={subcategories.name}>
                                                                                <NavLink to={`/categories/sub/product/${subcategories.name}`} >
                                                                                <span> 
                                                                                {subcategories.name === "PC de Escritorio" ? <FontAwesomeIcon icon={['fas',"fa-desktop"]}></FontAwesomeIcon> : 
                                                                                    subcategories.name === "PC portátil" ? <FontAwesomeIcon icon={['fas',"fa-laptop"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Accesorios" ? <FontAwesomeIcon icon={['fas',"fa-headphones"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Componentes" ? <FontAwesomeIcon icon={['fas',"fa-microchip"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Herramientas para reparación" ? <FontAwesomeIcon icon={['fas',"fa-toolbox"]}></FontAwesomeIcon> : 
                                                                                    subcategories.name === "Otros" ? <FontAwesomeIcon icon={['fas',"fa-gifts"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Celulares" ? <FontAwesomeIcon icon={['fas',"fa-mobile-screen-button"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Refacciones" ? <FontAwesomeIcon icon={['fas',"fa-mobile"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Telefonía fija" ? <FontAwesomeIcon icon={['fas',"fa-mobile"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Telefonía inalámbrica" ? <FontAwesomeIcon icon={['fas',"fa-signal"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Desarmadores" ? <FontAwesomeIcon icon={['fas',"fa-screwdriver-wrench"]}></FontAwesomeIcon> :
                                                                                    subcategories.name === "Maquinas de soldar " ? <FontAwesomeIcon icon={['fas',"fa-toolbox"]}></FontAwesomeIcon> :
                                                                         
                                                                                    null} 
                                                                                    &nbsp; {subcategories.name}</span>
                                                                                </NavLink>
                                                                            </li>
                                                                        )
                                                                    }
                                                                } ) 
                                                        }
                                                            
                                                        </ul>
                                                        </div>
                                                </div>
                                                        ) ) } 

        
                                
                                            </div>
                                        </div>
                                    </div>
                                </li>
                                <li className="navbar-item dropdown">
                                    <NavLink className="navbar-link dropdown-arrow" to="/">Páginas <Move x={5} rotation={90} timing={1000}><FontAwesomeIcon icon={['fas', 'fa-angles-right']}/> </Move></NavLink>
                                    <ul className="dropdown-position-list">
                                        <li><NavLink to="/faqs"><FontAwesomeIcon icon={['fas',"fa-question"]}></FontAwesomeIcon> Preguntas frecuentes</NavLink></li>
           
                                       <li><NavLink to="/about"><FontAwesomeIcon icon={['fas',"fa-hand"]}></FontAwesomeIcon> ¿Quiénes Somos?</NavLink></li>
                                        <li><NavLink to="/contact"> <FontAwesomeIcon icon={['fas',"fa-message"]}></FontAwesomeIcon> Contáctanos</NavLink></li>
                                        <li><NavLink to="/privacy"><FontAwesomeIcon icon={['fas',"fa-landmark"]}></FontAwesomeIcon> Políticas</NavLink></li>
                                     
                                    </ul>
                                </li>
                                {currentUser ? (
                                    <li className="navbar-item dropdown">
                                    <NavLink className="navbar-link dropdown-arrow" to="/">Mi cuenta <Move x={5} rotation={90} timing={1000}><FontAwesomeIcon icon={['fas', 'fa-angles-right']}/> </Move></NavLink>
                                    <ul className="dropdown-position-list">
                                    <li><NavLink to={`/Perfil/${currentUser?.user?.id}`}><FontAwesomeIcon icon={['fas',"user"]}></FontAwesomeIcon> Mi perfil</NavLink></li>
                                    <li><NavLink to="/user/history"> <FontAwesomeIcon icon={['fas',"basket-shopping"]}></FontAwesomeIcon> Mis compras</NavLink></li>
                                   <li><NavLink to='/preguntas'><FontAwesomeIcon icon={['fas',"fa-question"]}></FontAwesomeIcon> Mis preguntas</NavLink></li>
                                    <li><NavLink to="/favs"><FontAwesomeIcon icon={['fas',"fa-heart"]}></FontAwesomeIcon> Mis favoritos</NavLink></li>
                   
                                    {/* <li><NavLink to="/MisCalificaciones"><FontAwesomeIcon icon={['fas',"fa-star"]}></FontAwesomeIcon> Mis calificaciones</NavLink></li>
                                    <li><NavLink to="/MisComentarios"><FontAwesomeIcon icon={['fas',"fa-message"]}></FontAwesomeIcon> Mis comentarios</NavLink></li> */}
                                    <li><NavLink to="/logout"><FontAwesomeIcon icon={['fas',"fa-right-from-bracket"]}></FontAwesomeIcon> Cerrar sesión</NavLink></li>


                              
                            </ul>
                        </li>
                                ) : ( 
                                <li className="navbar-item dropdown">
                                    <NavLink className="navbar-link dropdown-arrow" to="/">Ingresar <Move x={5} rotation={90} timing={1000}><FontAwesomeIcon icon={['fas', 'fa-angles-right']}/> </Move></NavLink>
                                    <ul className="dropdown-position-list">
                                        <li><NavLink to='/login'>Ingresar</NavLink></li>
                                        <li><NavLink to="/Reg">Registrarse</NavLink></li>
                                        <li><NavLink to="/forgot">Recuperar Contraseña</NavLink></li>
                                    </ul>
                                </li>
                                )  }
                            </ul>
                          
                        </div>
                    </div>
                </div>
            </div>
        </nav>
    </div>
       
        
    )
       
}

export default NavBar;