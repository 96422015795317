import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from 'react-responsive-carousel';
import '../../css/faq.css';


export default function Help() {
    return (  

<div key='Aboutkey'> 
<section className="inner-section faq-part">
<div className="container">
                <div className="row">
                    <div className="col-lg-3">
                        <nav className="nav nav-pills flex-column" id="scrollspy">
                            <a className="nav-link" href="#item-1">¿Cómo devuelvo un producto?</a>
                            <a className="nav-link" href="#item-2">¿El equipo llegó dañado, qué puedo hacer?</a>
                            <a className="nav-link" href="#item-3">El producto que recibí no me gustó, ¿qué puedo hacer?</a>
                            <a className="nav-link" href="#item-4">¿Puedo devolver un producto por garantía?</a>
                            <a className="nav-link" href="#item-5">No puedo encontrar una respuesta a mi pregunta, ¿cómo me pongo en contacto?</a>
                        </nav>
                    </div>
                    <div className="col-lg-9">
                        <div data-bs-spy="scroll" data-bs-target="#scrollspy" data-bs-offset="0" tabIndex="0">
                            <div className="scrollspy-content" id="item-1">
                                <h3>¿Cómo devuelvo un producto?</h3>
                                <p>
                                Si desea devolver un producto, debe comunicarse con nuestro servicio al cliente para obtener más información sobre el proceso de devolución. Nuestro equipo de atención al cliente lo guiará en los pasos a seguir para devolver el producto.
                                </p>
                            </div>
                            <div className="scrollspy-content" id="item-2">
                                <h3>¿El equipo llegó dañado, qué puedo hacer?</h3>
                                <p>
                                Si el equipo que recibió está dañado, comuníquese con nuestro servicio al cliente de inmediato para informar el problema. Le proporcionaremos información sobre cómo devolver el producto y le ofreceremos una solución.
                                </p>
                            </div>
                            <div className="scrollspy-content" id="item-3">
                                <h3>El producto que recibí no me gustó, ¿qué puedo hacer?</h3>
                                <p>
                                Si el producto que recibió no cumplió con sus expectativas o no le gustó, comuníquese con nuestro servicio al cliente para informar el problema. Siempre hacemos todo lo posible para asegurarnos de que nuestros clientes estén satisfechos con su compra.
                                </p>
                            </div>
                            <div className="scrollspy-content" id="item-4">
                                <h3>¿Puedo devolver un producto por garantía?</h3>
                                <p>Sí, puede devolver un producto si está cubierto por una garantía. Comuníquese con nuestro servicio al cliente para obtener más información sobre el proceso de devolución de productos bajo garantía.</p>
                            </div>
                            <div className="scrollspy-content" id="item-5">
                                <h3>No puedo encontrar una respuesta a mi pregunta, ¿cómo me pongo en contacto?</h3>
                                <p>Si tienes alguna pregunta o preocupación sobre tu pedido, puedes ponerte en contacto con nuestro equipo de atención al cliente por correo electrónico o por teléfono. También puedes utilizar nuestro chat en línea para obtener ayuda en tiempo real. Estamos disponibles para ayudarte de lunes a viernes de 9 a.m. a 5 p.m. hora local.</p>
                                <ul className="footer-contact">
                                <li>
                                    <i className="icofont-ui-email"><FontAwesomeIcon  icon={['fas', 'fa-envelope']}/></i>
                                    <p>
                                    <a href="mailto:soporte@maceronu.com?Subject=Hola%20Maceronu%20quiero%20informes"><span> soporte@maceronu.com</span></a>
                                        
                                    </p>
                                </li>
                                <li>
                                    <i className="icofont-ui-touch-phone"><FontAwesomeIcon  icon={['fab', 'fa-whatsapp']}/></i>
                                    <p>
                                    <a href='https://api.whatsapp.com/send?phone=+524773959779&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"><span>+52 477-395-9779</span> </a> <br/>
                                    
                                        <a href='https://api.whatsapp.com/send?phone=+524778751774&text=Hola%20Maceronu%20quiero%20informes%20!' rel="noreferrer" target="_blank"> <span>+52 477-875-1774</span> </a>
                                    </p>
                                </li>
                               </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        </div>
)
}