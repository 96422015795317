
import api from "./api";
import authHeader from "./auth-header";




const getAddresses = () => { 
  return api.get("/Perfil/" + authHeader().id ); }

const addAddresses = (addr) => {
  return api.post("/Perfil/" + authHeader().id, addr );
}

const deleteAddress = (del) => { 
  return api.delete("/Perfil/", {  data: del });
}
const updateAddress = (addr) => { 
  return api.put("/Perfil/", addr, );
}

const updateUser = (data) => { 

  return api.put("/Perfil/" + authHeader().id, data, );
}

const ChangePassword = (data, id) => {
  return api.put("/Perfil/"+ id, data, );
}

const addWishListUser = (data) => {
  return api.post("/wish/" + authHeader().id, data, );
}
const deleteWishListUser = (data) => {
  
  return api.delete("/wish/" + authHeader().id, { data:data });
  
}
const getWishListUser = () => {
  return api.get("/wish/" + authHeader().id, );
  
}

const addCartListUser = (data) => {
  return api.post("/cart/" + authHeader().id, data, );
}
const deleteCartListUser = (data) => {
  
  return api.delete("/cart/" + authHeader().id, { data:data });
}
const getCartListUser = () => {
  return api.get("/cart/" + authHeader().id, );
}

const deleteAllCartListUser = (data) => {
  return api.delete("/cart/item/" + authHeader().id, { data:data });
}

const clearCart = () => {
  return api.delete("/cart/" + authHeader().id, );
}

const OrderUser = (data) => {
  return api.post("/order/" + authHeader().id, data, { headers: authHeader()});
}
const Order = (data) => {
  return api.post("/order/" + 1,  data );
}

const OrderStatus = (data) => {
  return api.get("/order/" + data);
}

const addRate = (data) => {  
  return api.post("/items/rate/" + authHeader().id, data, );
}

const updateRate  = (data) => { 
  return api.put("/items/rate/" + authHeader().id, data, );
}

const deleteRate  = (data) => {
  return api.delete("/items/rate/" + authHeader().id, { data:data });
}

const addReview = (data) => {  
  return api.post("/items/review/" + authHeader().id, data, );
}

const updateReview  = (data) => {
  return api.put("/items/review/" + authHeader().id, data, );
}

const deleteReview  = (data) => {
  return api.delete("/items/review/" + authHeader().id, { data:data });
}

const addQuestion = (data) => { 
  return api.post("/items/question/" + authHeader().id, data, );
}

const updateQuestion  = (data) => {
  return api.put("/items/question/" + authHeader().id, data, );
}

const deleteQuestion  = (data) => {
  return api.delete("/items/question/" + authHeader().id, { data:data });
}

const addAnswer = (data) => {
  return api.post("/items/answer/" + authHeader().id, data, );
}

const updateAnswer  = (data) => {
  return api.put("/items/answer/" + authHeader().id, data, );
}

const deleteAnswer  = (data) => {
  return api.delete("/items/answer/" + authHeader().id, { data:data });
}

const addComment = (data) => { 
  return api.post("/items/comment/" + authHeader().id, data, );
}

const updateComment  = (data) => {
  return api.put("/items/comment/" + authHeader().id, data, );
}

const deleteComment  = (data) => {
  return api.delete("/items/comment/" + authHeader().id, { data:data });
}

const getRate = (id) => { 
  
  return api.get("/items/rate/" + id); 
}

const getReview = (id) => { 
  return api.get("/items/review/" + id);
}

const getQuestion = (id,a,b) => {
  return api.get("/items/question/"+id+"/?limit="+a+"&limitAnswer="+b);
}

const getAnswer = (id) => {
  return api.get("/items/answer/" + id);
}

const getComment = (id) => {
  return api.get("/items/comment/" + id);
}

const getPurchasedBefore = (item) => {
  return api.get("/items/purchased/" + item + "/" + authHeader().id , );
}

const getRequestedQuestions = () => {
  return api.get("/preguntas/" + authHeader().id , );


}


const checkCoupon = (data) => {

  return api.get("/coupon/validate/"+data.coupon,  { 
   params: {
    ...data
    },
})  
}

const createService = (data) => {
  return api.post("/service", data, { headers: authHeader()});
}





export default {
  getAddresses,
  addAddresses,
  updateAddress,
  deleteAddress,
  updateUser,
  addWishListUser,
  deleteWishListUser,
  getWishListUser,
  addCartListUser,
  deleteCartListUser,
  getCartListUser,
  deleteAllCartListUser,
  Order,
  OrderStatus,
  clearCart,
  addRate,
  updateRate,
  deleteRate,
  addReview,
  updateReview,
  deleteReview,
  addQuestion,
  updateQuestion,
  deleteQuestion,
  addAnswer,
  updateAnswer,
  deleteAnswer,
  addComment,
  updateComment,
  deleteComment,
  ChangePassword,
  getRate,
  getReview,
  getQuestion,
  getAnswer,
  getComment,
  OrderUser,
  getPurchasedBefore,
  getRequestedQuestions,
  checkCoupon,
  createService,

};