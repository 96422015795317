import React, { useEffect,useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import UserService from "../../services/user.service";
import { useParams } from "react-router-dom";
import LoadingSpinner from "../../utils/LoadingSpinner";



export  function Order() {
 
    const [order, setOrder] = useState(null);
    const { id } = useParams();
  

    useEffect  (() => {
        UserService.OrderStatus(id).then((res) => {
            setOrder(res.data);
        });
    }, []);


    return ( 
        <div>
            
            {order ? (
                <div>

        {order && order[0].type !== undefined ? (
        
            <section className="inner-section invoice-part">
            <div className="container">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="alert-info">
                            <p>Gracias por tu compra! hemos recibido tu orden.</p>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="account-card">
                            <div className="account-title">
                                <h4>Orden recibida</h4>
                            </div>
                            <div className="account-content">
                                <div className="invoice-recieved">
                                    <h6>Número de orden: <span>{order[1].id}</span></h6>
                                    <h6>Fecha de compra: <span>{new Date(order[1].createdAt).toLocaleString() }</span></h6>
                                    <h6>Total:  <span>$ {order[1].total}</span></h6>
                                    { order[0].type ==="card" ?  (
                                        <div>
                                    <h6>Método de pago: Tarjeta <span>{order[0].card.brand}</span></h6>
                                     <h6>Tarjeta:<span> **** - **** - **** - {order[0].card.last4}</span></h6> </div>) : ( <h6>Método de pago: <span>{order[0]?.type}</span></h6> )}
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="account-card">
                            <div className="account-title">
                                <h4>Detalles de la orden</h4>
                            </div>
                            <div className="account-content">
                                <ul className="invoice-details">
                                    
                                    <li>
                                        <h6>Fecha del pedido:</h6>
                                        <p>{new Date(order[1].createdAt).toLocaleString() }</p>
                                    </li>
                                    <li>
                                        <h6>Metodo de entrega:</h6>
                                        <p>{order[1].shippingMethod}</p>
                                    </li>
                                    <li>
                                        <h6>Lugar de envio: </h6>

                                        <p>{order[1].address}</p>
                                        <p>C. {order[1].zipcode}</p>
                                        <p>Localidad: {order[1].city}, {order[1].country}</p>
                                        <h6>Contacto: </h6>
                                        <p>Tel: {order[1].phone}</p>
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-6">
                        <div className="account-card">
                            <div className="account-title">
                                <h4>Detalles del pago </h4>
                            </div>
                            <div className="account-content">
                                <ul className="invoice-details">
                                    {/* <li>
                                        <h6>Sub Total</h6>
                                        <p>$ {order[1].subtotal} </p>
                                    </li>
                                    <li>
                                        <h6>descuento</h6>
                                        <p>$0.00</p>
                                    </li>*/}
                                    <li> 
                                        <h6>Método de pago</h6>
                                        { order[0].type ==="card" ?  (
                                        <div>
                                    <p> Tipo: <span>{order[0]?.card?.brand}</span></p>
                                     <p>Tarjeta:<span> **** - **** - **** - {order[0]?.card?.last4}</span></p> </div>) : 
                                      order[0]?.oxxo?.number !== undefined ? ( <div><p>Efectivo: <span>{order[0]?.type}, <strong>aprobado</strong></span></p> <p>Numero de aprobacion: {order[0]?.oxxo?.number}</p></div>) 
                                        : ( <p>Efectivo: <span>{order[0]?.type}, <strong>pendiente</strong> <h4>Esperamos tu pago en Oxxo, recuerda que el tiempo de aprobacion para Oxxo puede demorar hasta 72 horas habiles dependiendo de la sucursal de Oxxo </h4></span></p> )}
                                      
                                        
                                    </li>
                                    <li>
                                        <h6>Total<small>(Incl. IVA)</small></h6>
                                        <p>$ {order[1].total}</p>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    <div className="col-lg-12">
                        <div className="table-scroll">
                            <table className="table-list">
                                <thead>
                                    <tr>
                                        <th scope="col">Producto</th>
                                        <th scope="col">Imagen</th>
                                        <th scope="col">Calidad</th>
                                        <th scope="col">Precio</th>
                                        <th scope="col">Marca</th>
                                        <th scope="col">Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                        {order[1].orderItems.map((item) => ( 

<tr key={item.product.SKU}>
                                        <td className="table-serial"><h6>{item.product.products}</h6></td>
                                        <td className="table-image"><img src={process.env.PUBLIC_URL + "/" + item.product.image}  alt="product"/></td>
                                        <td className="table-name"><h6>{item.product.quality}</h6></td>
                                        <td className="table-price">{item.product.inOffer === true ?  <h6>$ {item.product.offerPrice}<small>C/U</small></h6> : <h6>$ {item.product.price}<small>C/U</small></h6>} </td>
                                        <td className="table-brand"><h6>{item.product.brand}</h6></td>
                                        <td className="table-quantity"><h6>{item.quantity}</h6></td>
                                        </tr>
                                        ))}

                                   
                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-lg-12 text-center mt-5">
                        <Link className="btn btn-inline" to={"/invoice/"+id}>
                            <i className="icofont-download"></i>
                            <span> Solictar Factura </span>
                        </Link>
                        <div className="back-home">
                            <Link to="/">Comprar más productos!</Link>
                        </div>
                    </div>
                </div>
            </div>
        </section>
        ) : order  && order[0].oxxo !== undefined ? (
        
            <section className="inner-section invoice-part">
        <div className="container">
            <div className="row">
            <div className="col-lg-12">
                        <div className="alert-info">
                            <p>En espera de aprobacion.</p>
                        </div>
                    </div>
                <div className="col-lg-12 text-center mt-5">
                    <h4>Esperamos tu pago en Oxxo, recuerda que el tiempo de aprobacion para Oxxo puede demorar hasta 72 horas habiles dependiendo de la sucursal de Oxxo </h4>
                    <p>Cuentas con un tiempo limite para pagar en {order[0].oxxo.expires_after_days} dias</p>
                    </div>
                      <br/>
                    <div className="col-lg-12">
                        <div className="account-card">
                        <div className="account-title">
                        <h4> Detalles de la orden: </h4>
                        </div>
                      
                    
                            <div className="account-content">
                           
                                <ul className="invoice-details">
                                    
                                    <li>
                                        <h6>Fecha del pedido:</h6>
                                        <span>{new Date(order[1].createdAt).toLocaleString() }</span>
                                    </li>
                                    <li>
                                        <h6>Metodo de entrega:</h6>
                                        <span>{order[1].shippingMethod}</span>
                                    </li>
                                    <li>
                                        <h6>Lugar de envio: </h6>

                                        <p>{order[1].address}</p>
                                        <p>C. {order[1].zipcode}</p>
                                        <p>Localidad: {order[1].city}, {order[1].country}</p>
                                        <h6>Contacto: </h6>
                                        <span>Tel: {order[1].phone}</span>
                                        
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                    


                            <br/>
                    <div className="col-lg-12">
                        <div className="table-scroll">
                            <table className="table-list">
                                <thead>
                                    <tr>
                                        <th scope="col">Producto</th>
                                        <th scope="col">Imagen</th>
                                        <th scope="col">Calidad</th>
                                        <th scope="col">Precio</th>
                                        <th scope="col">Marca</th>
                                        <th scope="col">Cantidad</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    
                                        {order[1].map((item) => ( 

<tr key={item.product.SKU}>
                                        <td className="table-serial"><h6>{item.product.products}</h6></td>
                                        <td className="table-image"><img src={process.env.PUBLIC_URL + "/" + item.product.image}  alt="product"/></td>
                                        <td className="table-name"><h6>{item.product.quality}</h6></td>
                                        <td className="table-price">{item.product.inOffer === true ?  <h6>$ {item.product.offerPrice}<small>C/U</small></h6> : <h6>$ {item.product.price}<small>C/U</small></h6>} </td>
                                        <td className="table-brand"><h6>{item.product.brand}</h6></td>
                                        <td className="table-quantity"><h6>{item.quantity}</h6></td>
                                        </tr>
                                        ))}

                                   
                                   
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
                    </div>
                    <div className="row">
                    <div className="col-lg-12 text-center mt-5">
                <div className="back-home">
                            <Link to="/">Comprar más productos!</Link>
                        </div>

            </div>
            </div>


</section>
        ) : null } 
        
</div> 
        
        
        ) : ( <div> <h3>estamos preparando todo espera porfavor... </h3> <br/> <LoadingSpinner/></div> )}
        </div>
    )
}
export default Order;