import { combineReducers } from "redux";
import auth from "./auth";
import message from "./message";
import publicActions from "./publicActions";



 export const rootReducer = combineReducers({
  publicActions,  
  auth,
  message,
  });

  export default rootReducer;

