import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Carousel } from 'react-responsive-carousel';
import '../../css/privacy.css';


export default function Privacy() {
    return (  

<div key='privacykey'> 
<section className="inner-section privacy-part">
            <div className="container">
                <div className="row">
                <div className="col-lg-10 mx-auto">
                        <div className="faq-parent">
                            <div className="faq-child">
                                <div className="faq-que" >
                                    <button>DECLARACIÓN DE PRIVACIDAD</button>
                                </div>
                            </div>
                        </div>

                        <div className="faq-child">
                                <div className="faq-que">
                                    <button>SECCIÓN 1 - ¿QUÉ HACEMOS CON TU INFORMACIÓN?</button>
                                </div>
                                <div className="faq-ans">
                                    <p>Cuando utilizas nuestros servicios y aplicaciones, recolectamos la información personal que nos proporcionas, como tu nombre, dirección y correo electrónico, con el fin de brindarte una experiencia personalizada y segura. Además, cuando interactúas con nuestros servicios, automáticamente recopilamos información sobre tu ubicación, dispositivo y navegador, así como tu dirección de protocolo de internet de tu computadora (IP), para personalizar tu experiencia y mejorar nuestros servicios.

Email marketing (si aplica): Con tu permiso, podremos enviarte correos electrónicos acerca de nuestras actualizaciones, promociones y nuevos servicios.</p>
                                </div>
                        </div>

                        <div className="faq-child">
                                <div className="faq-que">
                                    <button>SECCIÓN 2 - CONSENTIMIENTO</button>
                                </div>
                                <div className="faq-ans">
                                    <h6>¿Cómo obtienen mi consentimiento?</h6>
                                    <p>Cuando nos provees tu información personal, como parte del proceso de registro o uso de nuestros servicios, implicamos que aceptas la recolección y uso de tu información personal por esa razón específica solamente. Si te pedimos tu información personal por una razón secundaria, como marketing, te pediremos directamente tu expreso consentimiento.</p>
                                    <h6>¿Cómo puedo anular mi consentimiento?</h6>
                                    <p>Si luego de haber aceptado cambias de opinión, puedes anular tu consentimiento para que te contactemos, 
                                    por la recolección, uso o divulgación de tu información, en cualquier momento, contactándonos a 
                                    soporte@maceronu.com o escribiéndonos a nuestras redes sociales. 
                                    </p>
                                    <ul className="footer-social">
                                <li><a className="icofont-facebook" href="https://www.facebook.com/WorldCellT"><FontAwesomeIcon  icon={['fab', 'fa-facebook-f']} target="_blank" rel="noreferrer" /></a></li>
                                <li><a className="icofont-twitter"  href="https://twitter.com/WorldCell10"><FontAwesomeIcon  icon={['fab', 'fa-twitter']} target="_blank"/></a></li>
                                <li><a className="icofont-google"  href="https://www.google.com/search?q=maceronu&oq=maceronu&aqs=edge..69i57j0i10l4j69i60j69i61l3.735j0j4&sourceid=chrome&ie=UTF-8&newwindow=1&tbs=lf:1,lf_ui:2&tbm=lcl&sxsrf=ALiCzsbYGGD9LBXKCp6arR7SM7cPI7hCNQ:1661376742989&rflfq=1&num=10&rldimm=10441443659896202324&lqi=CghtYWNlcm9udVoKIghtYWNlcm9udZIBBXN0b3Jl&ved=2ahUKEwivierUtuD5AhXzKkQIHeVUAYsQvS56BAgREAE&sa=X&rlst=f#rlfi=hd:;si:10441443659896202324,l,CghtYWNlcm9udVoKIghtYWNlcm9udZIBBXN0b3Jl;mv:[[21.157379,-101.680596],[21.1223802,-101.68202319999999]];tbs:lrf:!1m4!1u2!2m2!2m1!1e1!2m1!1e2!3sIAE,lf:1,lf_ui:2" rel="noreferrer" target="_blank"><FontAwesomeIcon  icon={['fab', 'fa-google']} /></a></li>
                                <li><a className="icofont-instagram" href="https://www.instagram.com/maceronu_/" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fab', 'fa-instagram']} /></a></li>
                                <li><a className="icofont-ML" href="https://worldcellmx.mercadoshops.com.mx/" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fas', 'fa-basket-shopping']} /></a></li>
                                <li><a className="icofont-tiktok" href="https://www.tiktok.com/@maceronu" target="_blank" rel="noreferrer"> <FontAwesomeIcon  icon={['fab', 'fa-tiktok']} /></a></li>
                            </ul>
                                </div>
                        </div>

                        <div className="faq-child">
                                <div className="faq-que">
                                    <button>SECCIÓN 3 - DIVULGACIÓN</button>
                                </div>
                                <div className="faq-ans">
                                    <p>No vendemos, alquilamos ni divulgamos tu información personal a terceros, a menos que sea necesario para prestarte nuestros servicios o cumplir con las leyes y regulaciones aplicables.</p>
                                </div>
                        </div>

                        <div className="faq-child">
                                <div className="faq-que">
                                    <button>SECCIÓN 4 - SEGURIDAD</button>
                                </div>
                                <div className="faq-ans">
                                    <p>Para proteger tu información personal, tomamos precauciones razonables y seguimos las mejores prácticas de la industria para asegurarnos de que no haya pérdida de manera inapropiada, mal uso, acceso, divulgación, alteración o destrucción de la misma. Implementamos medidas de seguridad técnicas, administrativas y físicas para proteger tu información personal.</p>
                                </div>
                        </div>

                        <div className="faq-child">
                                <div className="faq-que">
                                    <button>SECCIÓN 5 - EDAD DE CONSENTIMIENTO</button>
                                </div>
                                <div className="faq-ans">
                                    <p>No permitimos el uso de nuestros servicios a menores de 13 años y requerimos el consentimiento de los padres o tutores para el uso de nuestros servicios por menores de 18 años.</p>
                                </div>
                        </div>

                        <div className="faq-child">
                                <div className="faq-que">
                                    <button>SECCIÓN 6 - CAMBIOS A ESTA POLÍTICA DE PRIVACIDAD</button>
                                </div>
                                <div className="faq-ans">
                                    <p>Para proteger tu información personal, tomamos precauciones razonables y seguimos las mejores prácticas de la industria para asegurarnos de que no haya pérdida de manera inapropiada, mal uso, acceso, divulgación, alteración o destrucción de la misma. Implementamos medidas de seguridad técnicas, administrativas y físicas para proteger tu información personal.</p>
                                </div>
                        </div>

                </div>            
                </div>
            </div>
        </section>

        </div>


    )}