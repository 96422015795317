import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import '../../css/user-auth.css';
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { useNavigate, useParams, NavLink } from "react-router-dom";
import logo from  "../../img/isotipo.svg";
import { useSelector } from "react-redux";


function Reset() {

    const { message } = useSelector(state => state.message);
    const { token } = useParams();
    const API = process.env.REACT_APP_URL;
    const initialValues = {
     
      password: "",
    
 
    };
    const navigate = useNavigate();   
    const validationSchema = Yup.object().shape({
        
        password: Yup.string().min(8, "minimo 8 caracteres").max(20, "maximo 15 caracteres").required("campo requerido"),
       
        passwordvalidation: Yup.string().oneOf([Yup.ref('password'), null], 'Tu password debe coincidir').required("campo requerido"),
      });

      const onSubmit = (data) => {
        axios.post(API + "/reset/" + token ).then(() => {
          (data.password === data.passwordvalidation) ? alert("Contraseñas actulziada! ") : alert("Las contraseñas no coinciden");
        }). then (() => {
            navigate("/")}); 
      };


    return (
<div>


<Formik
        initialValues={initialValues}
        onSubmit={onSubmit}
        validationSchema={validationSchema} >
    <section className="user-form-part">
    <div className="container">
        <div className="row justify-content-center">
            <div className="col-12 col-sm-10 col-md-12 col-lg-12 col-xl-10">
                <div className="user-form-logo">
                    <NavLink to="/"><img src={logo} alt="Maceronu"/><h2>&nbsp; &nbsp; Maceronu</h2> </NavLink>
                </div>
                <div className="user-form-card">
                            <div className="user-form-title">
                                <h2>Restablecer tu contraseña</h2>
                                <p>Recuerda que debes de escribir en dos ocasiones la nueva contraseña</p>
                            </div>
                            
                   
                    <div className="user-form-group">
                      
                        
                        <Form  className="user-form">

                     
                              
                         
                            <div className="form-group">
                            <ErrorMessage name="password" component="span" />
                            <Field
            autocomplete="off"
            className="form-control"
            name="password" 
            placeholder="Escribe tu nueva contraseña"
          />
                            
                            </div>
                            <div className="form-group">
                            <ErrorMessage name="passwordvalidation" component="span" />
                            <Field
            autocomplete="off"
            className="form-control"
            name="passwordvalidation" 
            placeholder="Repite tu nueva contraseña"
          />
                            </div>
                            
                            <div className="form-button">
                                <button type="submit">Cambiar contraseña</button>
                            </div>
                        </Form>
                       
                    </div>
                </div>
                {message ?  
            <div className="form-group">
              <div className="alert alert-danger" role="alert">
                <span>{message} </span>
              </div> 
            </div>
            : null}
                <div className="user-form-remind">
                            <p>¿Aún no tienes una cuenta?<a href="/Reg">Regístrate aquí</a></p>
                        </div>
                <div className="user-form-footer">
                    <p>Maceronu Todos los derechos por <a href="/Privacy"> Maceruno </a></p>
                </div>
            </div>
        </div>
    </div>
</section>
</Formik>
</div>
);
}
export default Reset;