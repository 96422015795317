import { useState, useEffect } from 'react';
import { Link } from "react-router-dom";
import {formatCurrency} from "../../utils/formatCurrency";


export default function Carousel({ items }) {

  const [currentIndex, setCurrentIndex] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setCurrentIndex((index) => {
        let newIndex = index + 1;
        if (newIndex >= items.length) {
          newIndex = 0;
        }
        return newIndex;
      });
    }, 60000);
    return () => clearInterval(intervalId);
  }, [items.length]);

  const next = () => {
    setCurrentIndex((index) => {
      let newIndex = index + 1;
      if (newIndex >= items.length) {
        newIndex = 0;
      }
      return newIndex;
    });
  };

  const prev = () => {
    setCurrentIndex((index) => {
      let newIndex = index - 1;
      if (newIndex < 0) {
        newIndex = items.length - 1;
      }
      return newIndex;
    });
  };

  const windowStart = currentIndex;
  const windowEnd = windowStart + 4;
  const itemsToShow = items.slice(windowStart, windowEnd);

  return (
    <div className="row">
             
   
   

    <div className="row row-cols-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6">
       
    <button className='col-md-auto' onClick={prev} >
                            &#x3c;&#x3c;&#x3c;
                         </button>           
      {itemsToShow.map((movie) => (
       <div  key={movie.SKU + 'product'}>
       <div className="col" key={movie.SKU  + 'col'}>
           <div className="product-card">
               <div className="product-media">
                   <div className="product-label">
                   {movie.sectionA === null ? null :   <label className="label-text sale">{movie.sectionA}</label>}
                        {movie.sectionB === null ? null :   <label className="label-text off">{movie.sectionB}</label>}
                           {movie.sectionC === null ? null :   <label className="label-text new">{movie.sectionC}</label>}
                           {movie.sectionD === null ? null :   <label className="label-text feat">{movie.sectionD}</label>}
                   </div>
                  
                   <Link  key={movie.SKU} to={`/producto/${movie.SKU}`} className="product-image"> 
                   <img src={process.env.PUBLIC_URL +  "/" +  movie.image} alt={movie.title} />
                   </Link>
                  
               </div>
               <div className="product-content">
                   <div className="product-rating">
                      
                   <Link  to={`/categories/sub/product/${movie.subcategory}/${movie.brand}`} key={movie.SKU + 'Masvendidosproduct-rating'}>( {movie.brand} )</Link>
                   </div>
                   <h6 className="product-name">
                       <Link to={`/producto/${movie.SKU}`} key={movie.SKU + 'Masvendidosproduct-name'}>{movie.products}</Link>
                   </h6>
                  
                   {movie.inOffer === true ?
             <h6 className="product-price">
                 <del>{formatCurrency(movie.price)}</del>
                 <span>{formatCurrency(movie.offerPrice)}<small>/pieza</small></span>
                
             </h6> : 
             <h6 className="product-price">
                 <span>{formatCurrency(movie.price)}<small>/pieza</small></span>
             </h6>
              }
                   
                  
               </div>
              
       </div>
    
       </div>
       </div>

      ))}
      
     
      <button onClick={next} className='col-md-auto'>
                            &#x3e;&#x3e;&#x3e;
                         </button>
                         </div>
                         
   
    
                         </div>
                     
  
    
                         
     
  );
};

